/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React from "react";

import FB from "../../../../../src/app/assets/images/cds-blog/fb.png";
import TW from "../../../../../src/app/assets/images/cds-blog/tw.png";
import LI from "../../../../../src/app/assets/images/cds-blog/li.png";

const SocialShare = () => {
  const currentUrl = window.location.href;

  return (
    <>
      <div className="blog-share">
        <small className="text-center">Share</small>
        <div className="share mt-3">
          <a
            href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
              currentUrl
            )}`}
            target="_blank"
            rel="noopener noreferrer"
            className="ico fb"
          >
            <img
              alt=""
              class="img-fluid rounded-3 me-2"
              style={{ width: "35px" }}
              src={FB}
            />
          </a>

          <a
            href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
              currentUrl
            )}`}
            target="_blank"
            rel="noopener noreferrer"
            className="ico gp"
          >
            <img
              alt=""
              class="img-fluid rounded-3 me-2"
              style={{ width: "35px" }}
              src={LI}
            />
          </a>

          <a
            href={`https://twitter.com/share?url=${encodeURIComponent(
              currentUrl
            )}&text=Social%20Share%20by%20%40supahfunk`}
            target="_blank"
            rel="noopener noreferrer"
            className="ico tw"
          >
            <img
              alt=""
              class="img-fluid rounded-3"
              style={{ width: "33px" }}
              src={TW}
            />
          </a>
        </div>
      </div>
    </>
  );
};

export default SocialShare;
