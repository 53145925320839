import React, { useEffect } from "react";
import HeaderOne from "../../../common/header/HeaderOne";
import FooterOne from "../../../common/footer/FooterOne";
import { Link } from "react-router-dom";

//slides thumbnial

import slide1 from "../../../app/assets/images/slides/cap-introduction.jpg";
import slide2 from "../../../app/assets/images/slides/idea-to-product.jpg";
import slide3 from "../../../app/assets/images/slides/legal-managed-services.jpg";
import slide4 from "../../../app/assets/images/slides/data-annotation-service.jpg";
import slide5 from "../../../app/assets/images/slides/mobile-software-development.jpg";
import slide6 from "../../../app/assets/images/slides/marketing-and-consulting-service.jpg";
import slide7 from "../../../app/assets/images/slides/client-success-service.jpg";
import slide8 from "../../../app/assets/images/slides/nearshore-call-center-outsourcing.jpg";
import slide9 from "../../../app/assets/images/slides/comprehensive-website-graphical-design.jpg";
import slide10 from "../../../app/assets/images/slides/staffing-solution.png";
import slide11 from "../../../app/assets/images/slides/b2b-lead-generation.jpg";
import slide12 from "../../../app/assets/images/slides/knowledge-process-outsourcing.jpg";
import slide13 from "../../../app/assets/images/slides/attesta-ai.jpg";
import slide14 from "../../../app/assets/images/slides/service-offering.jpg";
import slide15 from "../../../app/assets/images/slides/managed-services.jpg";

const ClientSucess = () => {
  useEffect(() => {
    document.title = "Slides | CAP Digisoft ";
  }, []);
  return (
    <div className="slidespage">
      <HeaderOne />
      <section class="case-study-bg d-flex align-items-center justify-content-center inner pt-5 section-padding margin-minus">
        <div class="container ">
          <div class="row  justify-content-start g-4 g-xl-5">
            <div class="col-lg-12">
              <h6 class="toptitle text-center text-white">CAP Presentations</h6>
              <h1 class="px-lg-4 text-center text-white">
                Explore our Dynamic Slide Showcase
              </h1>
            </div>
          </div>
          <div class="row  g-4 g-xl-5 mt-1">
            <div class="col-lg-3 col-md-6 col-sm-12 d-flex">
               <Link to="https://docs.google.com/presentation/d/1RSfbUL9FO3HqKhgt63iTdzZLyGgCZi1zgA5UM0kRTXI/edit#slide=id.g29dff46de72_2_53" target="_blank">
             
                <div class="card  h-100 video-card video-card1">
                  <img src={slide1} class="card-img-top " alt="..."/>
                  <div class="card-body p-3 d-flex flex-column justify-content-between h-100">
                    <h5 class="card-title">CAP Introduction</h5>
                    <div class="d-flex justify-content-between">
                      <p class="small text-dark">Last Updated:</p>
                      <p class="small text-dark">Nov 18, 2023</p>
                    </div>
                  </div>
                </div>
                </Link>
            </div>
             <div class="col-lg-3  col-md-6 col-sm-12 d-flex">
                <Link to="https://docs.google.com/presentation/d/1s3wga7NMyRdHZhOG12PZhCkVzh_P2BxjX4oZNLXKvI8/edit#slide=id.p1" target="_blank">                
            
                <div class="card  h-100 video-card video-card1">
                <img src={slide2} class="card-img-top " alt="..."/>
                  <div class="card-body p-3 d-flex flex-column justify-content-between h-100">
                    <h5 class="card-title">Idea to Product</h5>
                    <div class="d-flex justify-content-between">
                      <p class="small text-dark">Last Updated:</p>
                      <p class="small text-dark">Nov 24, 2023</p>
                    </div>
                  </div>
                </div>
             </Link>
            </div>
             <div class="col-lg-3  col-md-6 col-sm-12 d-flex">
                <Link to="https://docs.google.com/presentation/d/1k25ZHOheFAojzFUORas-qXqT-zUvFeFjFM1hRz0zBuI/edit#slide=id.p1" target="_blank">
             
                <div class="card  h-100 video-card video-card1">
                <img src={slide3} class="card-img-top " alt="..."/>
                  <div class="card-body p-3 d-flex flex-column justify-content-between h-100">
                    <h5 class="card-title">Legal Managed Services</h5>
                    <div class="d-flex justify-content-between">
                      <p class="small text-dark">Last Updated:</p>
                      <p class="small text-dark">Nov 18, 2023</p>
                    </div>
                  </div>
                </div>
             </Link>
            </div>
             <div class="col-lg-3  col-md-6 col-sm-12 d-flex">
                <Link to="https://docs.google.com/presentation/d/1wYtSkWxa0b2naBm_3SbUwVOlM8ZRlgNPVs0rkovmWMg/edit#slide=id.g261dde1737e_6_187" target="_blank">
                              <div class="card  h-100 video-card video-card1">
                <img src={slide4} class="card-img-top " alt="..."/>
                  <div class="card-body p-3 d-flex flex-column justify-content-between h-100">
                    <h5 class="card-title">Data Annotation Service</h5>
                    <div class="d-flex justify-content-between">
                      <p class="small text-dark">Last Updated:</p>
                      <p class="small text-dark">Nov 21, 2023</p>
                    </div>
                  </div>
                </div>
             </Link>
            </div>
             <div class="col-lg-3  col-md-6 col-sm-12 d-flex">
                <Link to="https://docs.google.com/presentation/d/1ClhSBn0DSgaGccbR54tU54V-mS0byBHCKj8_zzxa78Q/edit#slide=id.p1" target="_blank">
              <div class="card  h-100 video-card video-card1">
                <img src={slide5} class="card-img-top " alt="..."/>
                  <div class="card-body p-3 d-flex flex-column justify-content-between h-100">
                    <h5 class="card-title">
                      Mobile App & Software Development
                    </h5>
                    <div class="d-flex justify-content-between">
                      <p class="small text-dark">Last Updated:</p>
                      <p class="small text-dark">Nov 24, 2023</p>
                    </div>
                  </div>
                </div>
             </Link>
            </div>
             <div class="col-lg-3  col-md-6 col-sm-12 d-flex">
                <Link to="https://docs.google.com/presentation/d/1Ue99DL7ol-KPtRRq9TAWlI-6X52pxCaEVwLUZwGsLQo/edit#slide=id.g29e53a4827d_1_99" target="_blank">
              <div class="card h-100  video-card video-card1">
                <img src={slide6} class="card-img-top " alt="..."/>
                  <div class="card-body p-3 d-flex flex-column justify-content-between h-100">
                    <h5 class="card-title">
                      Marketing and Consulting Services
                    </h5>
                    <div class="d-flex justify-content-between">
                      <p class="small text-dark">Last Updated:</p>
                      <p class="small text-dark">Nov 24, 2023</p>
                    </div>
                  </div>
                </div>
             </Link>
            </div>
             <div class="col-lg-3  col-md-6 col-sm-12 d-flex">
                <Link to="https://docs.google.com/presentation/d/1BSBdNzXsJeBOMcMzWVPBxqg_MjUIjZulEerxKTZwg1c/edit#slide=id.g29e476460ce_1_32" target="_blank">
                             <div class="card  h-100 video-card video-card1">
                <img src={slide7} class="card-img-top " alt="..."/>
                  <div class="card-body p-3 d-flex flex-column justify-content-between h-100">
                    <h5 class="card-title">Client Success Service</h5>
                    <div class="d-flex justify-content-between">
                      <p class="small text-dark">Last Updated:</p>
                      <p class="small text-dark">Nov 24, 2023</p>
                    </div>
                  </div>
                </div>
             </Link>
            </div>
             <div class="col-lg-3  col-md-6 col-sm-12 d-flex">
                <Link to="https://docs.google.com/presentation/d/1XWKZ6BU9nxAZB7N6dkNG3uMQLCaCWi1JDvnp_YaXz_M/edit#slide=id.g29ecba13500_1_82" target="_blank">
              
                <div class="card  h-100 video-card video-card1">
                <img src={slide8} class="card-img-top " alt="..."/>
                  <div class="card-body p-3 d-flex flex-column justify-content-between h-100">
                    <h5 class="card-title">
                      Nearshore Call Center Outsourcing
                    </h5>
                    <div class="d-flex justify-content-between">
                      <p class="small text-dark">Last Updated:</p>
                      <p class="small text-dark">Dec 06, 2023</p>
                    </div>
                  </div>
                </div>
             </Link>
            </div>
             <div class="col-lg-3  col-md-6 col-sm-12 d-flex">
                <Link to="https://docs.google.com/presentation/d/1m_-u3qBQH0CUe_dPeMeiwvvrn8TnhHa5DVsWMOG8WI4/edit#slide=id.g29f443c76e2_1_130" target="_blank">
                             <div class="card  h-100 video-card video-card1">
                <img src={slide9} class="card-img-top " alt="..."/>
                  <div class="card-body p-3 d-flex flex-column justify-content-between h-100">
                    <h5 class="card-title">
                      Comprehensive Website / Graphic Design
                    </h5>
                    <div class="d-flex justify-content-between">
                      <p class="small text-dark">Last Updated:</p>
                      <p class="small text-dark">Dec 06, 2023</p>
                    </div>
                  </div>
                </div>
             </Link>
            </div>
             <div class="col-lg-3  col-md-6 col-sm-12 d-flex">
                <Link to="https://docs.google.com/presentation/d/1a0djYg5ExP-S6kNoDTo8CSbYRTJBAwSaEBkyinRW7OI/edit#slide=id.g29dfac6c637_15_88" target="_blank">
                            <div class="card  h-100 video-card video-card1">
                <img src={slide10} class="card-img-top " alt="..."/>
                  <div class="card-body p-3 d-flex flex-column justify-content-between h-100">
                    <h5 class="card-title">Staffing Solution</h5>
                    <div class="d-flex justify-content-between">
                      <p class="small text-dark">Last Updated:</p>
                      <p class="small text-dark">Dec 06, 2023</p>
                    </div>
                  </div>
                </div>
             </Link>
            </div>
             <div class="col-lg-3  col-md-6 col-sm-12 d-flex">
                <Link to="https://docs.google.com/presentation/d/137PkG5CcVQWBAh1mrqZYWb7UrrRo6hAbnxkUQJS0h_s/edit#slide=id.g29f3fec1151_2_121" target="_blank">
                              <div class="card  h-100 video-card video-card1">
                <img src={slide11} class="card-img-top " alt="..."/>
                  <div class="card-body p-3 d-flex flex-column justify-content-between h-100">
                    <h5 class="card-title">B2B Lead Generation</h5>
                    <div class="d-flex justify-content-between">
                      <p class="small text-dark">Last Updated:</p>
                      <p class="small text-dark">Dec 06, 2023</p>
                    </div>
                  </div>
                </div>
             </Link>
            </div>
             <div class="col-lg-3  col-md-6 col-sm-12 d-flex">
                <Link to="https://docs.google.com/presentation/d/1okcAr8EE_sBe9Qw447ptZfAqL5VmxRSTyqSjeUIjouE/edit#slide=id.g2624cc5e692_0_0" target="_blank">
             
                <div class="card  h-100 video-card video-card1">
                <img src={slide12} class="card-img-top " alt="..."/>
                  <div class="card-body p-3 d-flex flex-column justify-content-between h-100">
                    <h5 class="card-title">Knowledge Process Outsourcing</h5>
                    <div class="d-flex justify-content-between">
                      <p class="small text-dark">Last Updated:</p>
                      <p class="small text-dark">Dec 06, 2023</p>
                    </div>
                  </div>
                </div>
             </Link>
            </div>
             <div class="col-lg-3  col-md-6 col-sm-12 d-flex">
                <Link to="https://docs.google.com/presentation/d/103DJEuIMFZVyArpbKnorX6fFbB5OW7so8K41g00uNs4/edit#slide=id.g2b245d3dae4_0_0" target="_blank">
                            <div class="card  h-100 video-card video-card1">
                <img src={slide13} class="card-img-top " alt="..."/>
                  <div class="card-body p-3 d-flex flex-column justify-content-between h-100">
                    <h5 class="card-title">Attesta AI</h5>
                    <div class="d-flex justify-content-between">
                      <p class="small text-dark">Last Updated:</p>
                      <p class="small text-dark">Jan 23, 2024</p>
                    </div>
                  </div>
                </div>
             </Link>
            </div>
             <div class="col-lg-3  col-md-6 col-sm-12 d-flex">
                <Link to="https://docs.google.com/presentation/d/1tQyeZ2Cs9Qju_9VK7N7UEar3Yhv3MnexRQpZLtYZZWc/edit?usp=sharing" target="_blank">
                             <div class="card  h-100 video-card video-card1">
                <img src={slide14} class="card-img-top " alt="..."/>
                  <div class="card-body p-3 d-flex flex-column justify-content-between h-100">
                    <h5 class="card-title">Introduction & Service Brief</h5>
                    <div class="d-flex justify-content-between">
                      <p class="small text-dark">Last Updated:</p>
                      <p class="small text-dark">Mar 25, 2024</p>
                    </div>
                  </div>
                </div>
             </Link>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 d-flex">
                <Link to="https://docs.google.com/presentation/d/15QWdpf46QBtW32jHrTislU_4gdu_fba_Nw4VWuWrtds/edit#slide=id.g2c68442e023_0_1730">
                             <div class="card  h-100 video-card video-card1">
                <img src={slide15} class="card-img-top " alt="..."/>
                  <div class="card-body p-3 d-flex flex-column justify-content-between h-100">
                    <h5 class="card-title">Managed Services</h5>
                    <div class="d-flex justify-content-between">
                      <p class="small text-dark">Last Updated:</p>
                      <p class="small text-dark">Mar 30, 2024</p>
                    </div>
                  </div>
                </div>
             </Link>
            </div>
          </div>
        </div>
      </section>
      <FooterOne />
    </div>
  );
};

export default ClientSucess;
