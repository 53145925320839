import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { FaLightbulb } from "react-icons/fa6";
import {
  BsRocketTakeoffFill,
  BsPlugin,
  BsFileEarmarkCodeFill,
} from "react-icons/bs";

const Nav = () => {
  const [openMenu, setOpenMenu] = useState(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const location = useLocation();

  const handleMenuToggle = (index) => {
    if (openMenu === index) {
      setOpenMenu(null); // Close menu if already open
    } else {
      setOpenMenu(index); // Open menu
    }
  };

  const handleMouseEnter = (index) => {
    setOpenMenu(index);
  };

  const handleMouseLeave = () => {
    setOpenMenu(null);
  };

  const handleMobileMenuToggle = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1198.98) {
        setIsMobileMenuOpen(false);
        setOpenMenu(null);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const menuItems = [
    {
      label: "Solutions",
      class: "current",
      megamenu: [
        {
          section: "Solutions",
          items: [
            {
              label: "App Idea to Product",
              link: "https://ideatoproduct.capdigisoft.com/",
              target: "_blank",
              icon: <FaLightbulb />,
              subcontent:
                "Bring your software or app idea to life and make it a reality",
              isNew: true,
            },
            {
              label: "Call Center Service",
              target: "_blank",
              icon: <BsPlugin />,
              link: "https://callcenter.capdigisoft.com/",
              subcontent:
                "Efficient, empowering Plug and Play Call Center for expert tech support.",
            },
            {
              label: "App Idea Booster",
              link: "https://ideabooster.capdigisoft.com/",
              target: "_blank",
              icon: <BsRocketTakeoffFill />,
              subcontent:
                "Unleash your app's potential with strategic design and positioning.",
            },
            {
              label: "Mobile App & Software Development",
              link: "https://softwareappdevelopment.capdigisoft.com/",
              target: "_blank",
              icon: <BsFileEarmarkCodeFill />,
              subcontent:
                "Excel in custom software for diverse business needs with tailored solutions",
            },
          ],
        },
        {
          section: "Core Services",
          items: [
            { label: "Generative AI", link: "/generative-ai" },
            { label: "IT Support", link: "/it_support" },
            { label: "Staffing Solutions", link: "/staffing-support-service" },
            {
              label: "Knowledge Process Outsourcing",
              link: "/knowledge-process-outsourcing",
            },
            {
              label: "Comprehensive Website Design",
              link: "/comprehensive-website-design",
            },
            {
              label: "B2B Lead Generation",
              link: "/b2b-lead-generation-expert",
            },
            { label: "Client Success Service", link: "/client-success" },
          ],
        },
      ],
    },
    {
      label: "Products",
      link: "/products",
    },
    {
      label: "About Us",
      submenu: [
        { label: "Our Company", link: "/ourcompany" },
        { label: "Management Team", link: "/management-team" },
        { label: "Press Release", link: "/pressrelease" },
        { label: "Careers", link: "/careers" },
      ],
    },
    {
      label: "Insights",
      megamenu: [
        {
          section: "Recent Blogs",
          items: [
            {
              label: "Marketing Strategies for the Age of Everything Online",
              link: "/marketing-strategies-for-the-age-of-everything-online",
              subcontent:
                "Strategic Digital Marketing Strategies for the Age of Everything Online The digital age has ...",
            },
            {
              label:
                "The Impact of Knowledge Processing on Business Intelligence",
              link: "/the-impact-of-knowledge-processing-on-business-intelligence",
              subcontent:
                "Transforming Data into Business Intelligence The Impact of Knowledge Processing on Business Intelligence...",
            },
            {
              label:
                "The Importance of UI/UX Design in Today’s Digital Landscape",
              link: "/the-importance-of-ui-ux-design-in-todays-digital-landscape",
              subcontent:
                "Mastering UI/UX Design The Importance of UI/UX Design in Today’s Digital Landscape Imagine walking into...",
            },
            {
              label: "Building a Fortress: Enhancing Web Security in 2024",
              link: "/building-a-fortress-enhancing-web-security-in-2024",
              subcontent:
                "Web Security in Today’s Digital Landscape Building a Fortress: Enhancing Web Security in 2024...",
            },
          ],
        },
        {
          section: "Insights",
          items: [
            { label: "Blog", link: "/blog" },
            { label: "Case Studies", link: "/casestudy" },
            { label: "Free Downloads", link: "/freedownload" },
          ],
        },
      ],
    },
    {
      label: "Contact Us",
      link: "/contactus",
    },
  ];

  const isActive = (link) => {
    if (!link) return false;
    return location.pathname === link || location.pathname.startsWith(link);
  };

  return (
    <>
      <ul className={`primary-menu ${isMobileMenuOpen ? "open" : ""}`}>
        {menuItems.map((item, index) => (
          <li
            key={index}
            className={`${
              item.megamenu || item.submenu ? "has-submenu" : ""
            } ${isActive(item.link) ? "active" : ""}`}
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={handleMouseLeave}
          >
            <Link
              to={item.link}
              className="has-subnav-link"
              onClick={(e) => {
                if (item.megamenu || item.submenu) {
                  e.preventDefault();
                  handleMenuToggle(index);
                }
              }}
            >
              {item.label}
              {item.megamenu || item.submenu ? (
                openMenu === index ? (
                  <FaChevronUp
                    className="chevron-icon"
                    style={{ fontSize: "16px" }}
                  />
                ) : (
                  <FaChevronDown
                    className="chevron-icon"
                    style={{ fontSize: "16px" }}
                  />
                )
              ) : null}
            </Link>
            {(item.megamenu || item.submenu) && openMenu === index && (
              <div
                className={`${item.megamenu ? "megamenu" : "submenu"} ${
                  item.label === "Insights" ? "insights-megamenu" : ""
                }`}
              >
                {item.megamenu && (
                  <>
                    <div className="megamenu-wrapper">
                      <div className="megamenu-section-container1">
                        <h4>{item.megamenu[0].section}</h4>
                        <ul className="mega-sec-1">
                          {item.megamenu[0].items.map((subitem, subindex) => (
                            <li
                              key={subindex}
                              className={`sub-menu-item ${
                                isActive(subitem.link) ? "active" : ""
                              }`}
                            >
                              <Link
                                to={subitem.link}
                                target={subitem.target}
                                className="sub-link"
                              >
                                <div className="subnav-menu-icon">
                                  <span className="menu-icon">
                                    {subitem.icon}
                                  </span>
                                </div>
                                <div className="sub-menu-content">
                                  <span className="label">{subitem.label}</span>
                                  {subitem.isNew && (
                                    <span className="new-badge">New</span>
                                  )}
                                  {subitem.subcontent && (
                                    <p className="mini-text">
                                      {subitem.subcontent}
                                    </p>
                                  )}
                                </div>
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className="megamenu-section-container2">
                        <h4>{item.megamenu[1].section}</h4>
                        <div className="sub-categorymenu">
                          <div className="submenu-category1">
                            <ul className="mega-sec-2">
                              {item.megamenu[1].items.map(
                                (subitem, subindex) => (
                                  <li
                                    key={subindex}
                                    className={`sub-menu-item2 ${
                                      isActive(subitem.link) ? "active" : ""
                                    }`}
                                  >
                                    <Link
                                      to={subitem.link}
                                      target={subitem.target}
                                      className="sub-link2"
                                    >
                                      {subitem.icon && (
                                        <img
                                          src={subitem.icon}
                                          alt={subitem.label}
                                          className="icon"
                                        />
                                      )}
                                      <span>{subitem.label}</span>
                                      {subitem.isNew && (
                                        <span className="new-badge">New</span>
                                      )}
                                      {subitem.subcontent && (
                                        <p>{subitem.subcontent}</p>
                                      )}
                                    </Link>
                                  </li>
                                )
                              )}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {item.submenu && (
                  <div className="submenu-section">
                    <ul>
                      {item.submenu.map((subitem, subindex) => (
                        <li
                          key={subindex}
                          className={`${
                            isActive(subitem.link) ? "active" : ""
                          }`}
                        >
                          <Link
                            to={subitem.link}
                            onClick={() => handleMenuToggle(index)}
                          >
                            {subitem.label}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            )}
          </li>
        ))}
      </ul>
    </>
  );
};

export default Nav;
