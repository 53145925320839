import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import cpbg from '../../../app/assets/images/banner/cp-bg.png'



class CourseCat01 extends Component {
   

    render() {
        // Course category

        return (      
            <section className="course-category-3 section-padding global-sec pb-0">
                <div className="container custom-cn-width cus-pd-24">
                    <div className="row mb-5">
                        <div className="col-xl-12">
                            <div className="section-heading">
                                <h1 className="fw-light">A Local Company with a Global Reach</h1>
                               
                            </div>
                        </div>
                    </div>

                    <div class="row g-lg-5 g-4 align-items-end">
                        <div class="col-xxl-8 col-xl-7">
                            <section class="row g-4 pt-0">
                                <div class="col-lg-6 col-md-6">
                                    <h4 class="mb-lg-3 mb-2 font-size-25 text-dark">A Local Presence</h4>
                                    <p className='font-size-17 text-dark'>Since 2006 CAP Digisoft has been a local face in the North Texas area helping companies just like yours with comprehensive BPO and IT solutions.</p>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <h4 class="mb-lg-3 mb-2 font-size-25 text-dark">A Global Reach</h4>
                                    <p className='font-size-17 text-dark'>With a global team that includes more than 350 software engineers and data experts, we can provide on-demand resources at a competitive price without compromising.</p>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <h4 class="mb-lg-3 mb-2 font-size-25 text-dark">Certified to High Standards</h4>
                                    <p className='font-size-17 text-dark'>Our design &amp; development process is constantly improving. With ISO 27001 and 9001:2015 Certification, you can be assured that we are committed to high quality business standards, meeting our customers expectations, and delivering a superior product.</p>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <h4 class="mb-lg-3 mb-2 font-size-25 text-dark">Minority Business Enterprise</h4>
                                    <p className='font-size-17 text-dark'>CAP Digisoft is a Minority Owned Business and we are dedicated to making sure every one of our more than 300 employees, globally and locally, is receiving the best training and support possible so that we can provide you with the highest quality products and services.</p>
                                </div>
                            </section>
                        </div>
                        <div class="col-xxl-4 col-xl-5 mt-0 pt-lg-4 text-center position-relative">
                            <div class="core-img-wrapper">
                                <div>
                                    <img src={cpbg} alt="Logo" class="img-fluid" height={520} />
                                </div>

                                <div class="name-wrapper">
                                    <div class="core">
                                        <p class="h4 mb-2 fw-400 text-dark text-name" >Arun Kumar</p>
                                        <p class="h6 fw-light text-dark text-desc">Founder, President and Chief Executive Officer</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>  

                    
                </div>
         </section>

        );
    }
}
export default CourseCat01;