import React, { useState, useEffect } from "react";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useParams, useNavigate } from "react-router-dom";

// Import your components and assets

import HeaderDark from "../../common/header/HeaderDark";
import FooterOne from "../../common/footer/FooterOne";
import book1 from "../../../src/app/assets/images/free-download//1.png";
import book2 from "../../../src/app/assets/images/free-download/2.jpg";
import book3 from "../../../src/app/assets/images/free-download/3.png";
import book4 from "../../../src/app/assets/images/free-download/4.png";
import book5 from "../../../src/app/assets/images/free-download/5.jpg";
import book6 from "../../../src/app/assets/images/free-download/6.jpg";
import book7 from "../../../src/app/assets/images/free-download/7.jpg";
import book8 from "../../../src/app/assets/images/free-download/8.jpg";
import book9 from "../../../src/app/assets/images/free-download/9.jpg";
import book10 from "../../../src/app/assets/images/free-download/10.jpg";
import book11 from "../../../src/app/assets/images/free-download/11.jpg";
import book12 from "../../../src/app/assets/images/free-download/12.jpg";
import book13 from "../../../src/app/assets/images/free-download/13.jpg";
import book14 from "../../../src/app/assets/images/free-download/14.jpg";
import book15 from "../../../src/app/assets/images/free-download/15.jpg";
import book16 from "../../../src/app/assets/images/free-download/16.jpg";

const Products = () => {
  const { pagename, id } = useParams();
  
  const downloadBook = [
    {
      id: 1,
      title: " The Outbound Prospecting Playbook",
      bookcover: book16,
      downloadUrl:
        "https://capdigisoft.com/backend/freedownloadbooks/resource/the-outbound-prospecting-playbook.pdf",
      pagename: "the-outbound-prospecting-playbook",
    },
    {
      id: 2,
      title: "Writing a business plan for your app or software idea",
      bookcover: book1,
      downloadUrl:
        "https://capdigisoft.com/backend/freedownloadbooks/resource/writing-a-business-plan-for-your-app-or-software-idea.pdf",
        pagename: "writing-a-business-plan-for-your-app-or-software-idea",
    },
    {
      id: 3,
      title: "Idea to product workbook",
      bookcover: book2,
      downloadUrl:
        "https://capdigisoft.com/backend/freedownloadbooks/resource/idea-to-product-workbook.pdf",
        pagename: "idea-to-product-workbook",
    },
    {
      id: 4,

      title: "Mobile app & software development",
      bookcover: book3,
      downloadUrl:
        "https://capdigisoft.com/backend/freedownloadbooks/resource/mobile-app-and-software-development.pdf",
        pagename: "mobile-app-and-software-development",
    },

    {
      id: 5,
      title: "Strategic framework for email marketing",
      bookcover: book4,
      downloadUrl:
        "https://capdigisoft.com/backend/freedownloadbooks/resource/strategic-framework-for-email-marketing.pdf",
        pagename: "strategic-framework-for-email-marketing",
    },

    {
      id: 6,
      title: "The secret to activating your marketing plan",
      bookcover: book5,
      downloadUrl:
        "https://capdigisoft.com/backend/freedownloadbooks/resource/the-secret-to-activating-your-marketing-plan.pdf",
        pagename: "the-secret-to-activating-your-marketing-plan",
    },

    {
      id: 7,
      title: "Social media best practices",
      bookcover: book6,
      downloadUrl:
        "https://capdigisoft.com/backend/freedownloadbooks/resource/social-media-best-practices.pdf",
        pagename: "social-media-best-practices",
    },

    {
      id: 8,
      title: "Best practices for UI and UX designers",
      bookcover: book7,
      downloadUrl:
        "https://capdigisoft.com/backend/freedownloadbooks/resource/best-practices-for-ui-and-ux-designers.pdf",
        pagename: "best-practices-for-ui-and-ux-designers",
    },

    {
      id: 9,
      title: "The myth of build it and they will come",
      bookcover: book9,
      downloadUrl:
        "https://capdigisoft.com/backend/freedownloadbooks/resource/the-myth-of-build-it-and-they-will-come.pdf",
        pagename: "the-myth-of-build-it-and-they-will-come",
    },
    {
      id: 10,
      title: "The next generation of lead generation",
      bookcover: book8,
      downloadUrl:
        "https://capdigisoft.com/backend/freedownloadbooks/resource/the-next-generation-of-lead-generation.pdf",
        pagename: "the-next-generation-of-lead-generation",
      bookdesc: "",

    },

    {
      id: 11,
      title: "Social media image sizes cheat sheet",
      bookcover: book10,
      downloadUrl:
        "https://capdigisoft.com/backend/freedownloadbooks/resource/social-media-image-sizes-cheat-sheet.pdf",
        pagename: "social-media-image-sizes-cheat-sheet",
    },

    {
      id: 12,
      title: "Website relaunch SEO checklist",
      bookcover: book11,
      downloadUrl:
        "https://capdigisoft.com/backend/freedownloadbooks/resource/website-relaunch-seo-checklist.pdf",
        pagename: "website-relaunch-seo-checklist",
    },

    {
      id: 13,
      title: "Technical SEO site audit",
      bookcover: book12,
      downloadUrl:
        "https://capdigisoft.com/backend/freedownloadbooks/resource/technical-seo-site-audit.pdf",
        pagename: "technical-seo-site-audit",
    },
    {
      id: 14,
      title: "Best content management system WordPress",
      bookcover: book13,
      downloadUrl:
        "https://capdigisoft.com/backend/freedownloadbooks/resource/best-content-management-system-wordpress.pdf",
        pagename: "best-content-management-system-wordpress",
    },
    {
      id: 15,
      title: "Brand checklist",
      bookcover: book14,
      downloadUrl:
        "https://capdigisoft.com/backend/freedownloadbooks/resource/brand-checklist.pdf",
        pagename: "brand-checklist",
    },
    {
      id: 16,
      title: "ADA compliance",
      bookcover: book15,
      downloadUrl:
        "https://capdigisoft.com/backend/freedownloadbooks/resource/ada-compliance.pdf",
        pagename: "ada-compliance",
    },
  ];

  let bookfilterArray = downloadBook.filter(
    (bookFilter) => parseInt(bookFilter.id) === parseInt(id)
  );
  console.log(bookfilterArray);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const formValidationSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, "Minimum 3 characters")
      .matches(/^[a-zA-Z]+(?: [a-zA-Z]+)*$/, "Should be in A-Z, a-z letters")
      .required("Please enter your name"),
      email: Yup.string().email("Please enter a valid email address")
      .test('no-com.co', 'Please enter a valid email address', value => !/\.com\.co$/.test(value))
      .test('no-single-letter-after-com', 'Please enter a valid email address', value => !/\.com\.[a-zA-Z]$/.test(value))
      .test('no-spaces', 'Please enter a valid email address', value => !/\s/.test(value))
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        'Please enter a valid email address'
      )
      .test('only-one-dot-com', 'Please enter a valid email address', value => (value.match(/\.com/g) || []).length <= 1)
      .test('no-repeated-domain', 'Please enter a valid email address', value => {
        const domainExtensions = ['.com', '.net', '.in', '.co'];
        const matches = value.match(/\.\w+/g);
        if (matches) {
          const uniqueExtensions = new Set(matches.map(match => match.toLowerCase()));
          return uniqueExtensions.size === matches.length;
        }
        return true;
      })
      .test('laxEmail_new', 'Please enter a valid email address.', function(value) {
        if (value) {
          if (value.indexOf("@") > 0 && value.indexOf("@") === value.lastIndexOf("@")) {
            const [localPart, domain] = value.split("@");
            const firstChar = localPart[0];
            const lastChar = localPart.charAt(localPart.length - 1);
            const afterAt = domain[0];
    
            if (firstChar === '.' || lastChar === '.' || (/^[A-Za-z]$/.test(afterAt) === false)) {
              return false;
            }
    
            const domainParts = domain.split(".");
            if (domainParts.length < 2) {
              return false;
            }
    
            const topLevelDomain = domainParts.pop();
            if (topLevelDomain.length < 2 || topLevelDomain.length > 4) {
              return false;
            }
    
            return domainParts.every(part => part.length > 1);
          } else {
            return false;
          }
        }
        return true;
      })
      .required("Please enter your email address"),
    phone: Yup.string()
      .required("Please enter phone number")
      .matches(/^\d+$/, "Please enter a valid phone number")
      .min(10, "Phone number must be at least 10 digits")
      .max(15, "Phone number must not exceed 15 digits"),
    company: Yup.string()
      .min(3, "Company name must be at least 3 characters")
      .required("Please enter your Company name"),
  });

  //country code

  const [countryCode, setCountryCode] = useState("");

  useEffect(() => {
    // Fetch the user's country based on their IP
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        // ipapi returns the country code in ISO Alpha-2 format, which react-phone-input-2 expects
        const countryCode = response.data.country_code.toLowerCase();
        setCountryCode(countryCode);
      })
      .catch((error) => {
        console.error("Error fetching IP information:", error);
        // Optionally set a default or handle the error
      });
  }, []);

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      company: "",
      downloadUrl:
        bookfilterArray.length > 0 ? bookfilterArray[0].downloadUrl : "",
      bookname: bookfilterArray.length > 0 ? bookfilterArray[0].title : "",
      pageLink: window.location.href,
    },
    validationSchema: formValidationSchema,
    onSubmit: async (values) => {
      setIsSubmitting(true);
      if (window.grecaptcha) {
        window.grecaptcha.ready(async () => {
          const token = await window.grecaptcha.execute(
            "6LdRfrYpAAAAAM6i4yl1LJWvbrvxcnIhzqn1XWmB",
            { action: "submit" }
          );
        
      try {
        const response = await axios.post(
          "/backend/submit_download.php",
          {
            mode: "adddownload_data",
            ...values,
            "g-recaptcha-response": token,
          }
        );
        const responseData = response.data; 
        console.log("response", response);
        if (responseData === 1) {          
          //alert(responseData);
          formik.resetForm();
          const downloadUrl =
            bookfilterArray.length > 0 ? bookfilterArray[0].downloadUrl : "";
          // Programmatically create an anchor tag to "click" for download
          // const link = document.createElement('a');
          // link.href = downloadUrl;
          // link.setAttribute('download', ''); // Or set a specific filename here
          // document.body.appendChild(link);
          // link.click();
          // document.body.removeChild(link);
          window.open(downloadUrl, "_blank");
          //navigate(/thankyoudownload);
          const bookId =
            bookfilterArray.length > 0 ? bookfilterArray[0].id : "";
          //navigate(`/thankyoudownload?bookId=${bookId}`);
          // navigate(/thankyoudownload);
          navigate("/thankyoudownload", { state: { downloadUrl } });
        } else if((responseData === 0) ) {
          console.error("Error submitting form data");
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setIsSubmitting(false);
      }
    });
  }
    },
  });

  const handleKeyPress = (event) => {
    if (event.key === ' ') {
      event.preventDefault();
    }
  };
  return (
    <div className="download-detail-page">
      <HeaderDark />
      <section class="p-0 position-relative">
        <div class="container custom-cn-width super-container">
          <div class="row justify-content-center g-0 text-center text-xl-start">
            {bookfilterArray.length > 0 && (
              <>
                <div className="col-xl-6 bg-green1">
                  <div className="position-relative z-index-1 pe-lg-5 text-start">
                    <div className="banner-sec">
                      <h2 className="mb-1 pb-lg-3">
                        {bookfilterArray[0].title}
                      </h2>
                    </div>
                    <div className="banner-content">
                      <div className="mt-2 mb-3">
                        <img
                          src={bookfilterArray[0].bookcover}
                          alt="Book cover"
                          className="img-fluid bg-shadow b-r-6"
                        />
                      </div>

                      <h5 class="mb-0">{bookfilterArray[0].bookdesc}</h5>
                    </div>
                  </div>
                </div>
              </>
            )}

            <div class="col-xl-6 down-sec bg-light">
              <div class="position-relative">
                <h4 class="mb-4 mt-xl-5">Please fill the form to download</h4>
                <div class="border-bottom my-4 d-none d-sm-block"></div>
                <form onSubmit={formik.handleSubmit}>
                  {/* Name field */}
                  <div className="mb-4">
                    <label htmlFor="name" className="form-label">
                      Full Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      name="name"
                      placeholder="Full Name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.name}
                    />
                    {formik.touched.name && formik.errors.name ? (
                      <div className="error-msg text-danger">{formik.errors.name}</div>
                    ) : null}
                  </div>

                  {/* Email field */}
                  <div className="mb-4">
                    <label htmlFor="email" className="form-label">
                      Email <span className="text-danger">*</span>
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      name="email"
                      placeholder="Email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      onKeyDown={handleKeyPress}
                      value={formik.values.email}
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <div className="error-msg text-danger">{formik.errors.email}</div>
                    ) : null}
                  </div>

                  {/* Phone field */}
                  <div className="mb-4">
                    <label htmlFor="phone" className="form-label">
                      Phone Number <span className="text-danger">*</span>
                    </label>
                    <PhoneInput
                      // country={countryCode}
                      value={formik.values.phone}
                      country={countryCode}
                      onChange={(phone) => formik.setFieldValue("phone", phone)}
                      containerStyle={{ marginTop: "0.5rem" }}
                      inputStyle={{
                        width: "100%",
                        height: "38px",
                      }}
                    />
                    {formik.touched.phone && formik.errors.phone ? (
                      <div className="error-msg text-danger">{formik.errors.phone}</div>
                    ) : null}
                  </div>

                  {/* Company field */}
                  <div className="mb-4">
                    <label htmlFor="company" className="form-label">
                      Company <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="company"
                      name="company"
                      placeholder="Company"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.company}
                    />
                    {formik.touched.company && formik.errors.company ? (
                      <div className="error-msg text-danger">{formik.errors.company}</div>
                    ) : null}
                  </div>

                  <button
                    type="submit"
                    className="btn cust-btn-primary"
                    disabled={isSubmitting}
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterOne />
    </div>
  );
};

export default Products;