import React, { useEffect } from 'react';
import { Link } from "react-router-dom";

import HeaderLight from '../../../common/header/HeaderLight';
import FooterOne from '../../../common/footer/FooterOne';

import banner from '../../../../src/app/assets/images/casestudy/hireharmony/banner.png'
import mini from '../../../../src/app/assets/images/casestudy/tech/mini-equ.png'
import cutout1 from '../../../../src/app/assets/images/casestudy/hireharmony/cutout1.png'
import cutout2 from '../../../../src/app/assets/images/casestudy/hireharmony/cutout2.png'
import cutout3 from '../../../../src/app/assets/images/casestudy/hireharmony/cutout3.png'

import tech1 from '../../../../src/app/assets/images/casestudy/tech/php.png'
import tech2 from '../../../../src/app/assets/images/casestudy/tech/json.png'
import tech3 from '../../../../src/app/assets/images/casestudy/tech/my-sql.png'
import tech4 from '../../../../src/app/assets/images/casestudy/tech/linux.png'
import tech5 from '../../../../src/app/assets/images/casestudy/tech/apache.png'

import case1 from '../../../../src/app/assets/images/casestudy/othercase/rcm1.png'
import case2 from '../../../../src/app/assets/images/casestudy/othercase/meeting-minder.png'
import case3 from '../../../../src/app/assets/images/casestudy/othercase/cluedin.png'

const HiireHormony = () => {
    
	useEffect(() => {
        document.title = 'Hire Harmony | CAP Digisoft ';
      }, []);

return (
<div className="casestudy-practica-page">
    <HeaderLight />
    <div class="container-fluid hero-banner pt-0 px-0 margin-minus">
        <div class="masthead tracky pt-lg-5">
            <h2 class="text-white text-center pt-5">Hire Harmony</h2>
            <p class="text-white text-center pb-4 pt-3">Hire Harmony is a web application based on hiring process of an
                organization.
            </p>
            <div class="img text-center">
                <img alt="" class="img-fluid rounded-3 pb-5" src={banner} />
            </div>
        </div>
    </div>
    <div class="about py-5">
        <div class="container custom-cn-width">
            <div class="row">
                <div class="col-lg-3 col-md-4 col-sm-12 ">
                    <div class="heading">
                        <div class="content1 d-flex align-items-center">
                            <img src={mini} class="img-fluid" alt="" />
                            <p class="fs-3 ">What is Hire Harmony?</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-9 col-md-8 col-sm-12">
                    <p>
                        HireHarmony is a web application designed to streamline and optimize the hiring processes of
                        organizations. Leveraging intuitive features and advanced technologies, the platform aims to
                        simplify recruitment, enhance candidate experiences, and ultimately improve the overall harmony
                        within the hiring ecosystem. </p>
                </div>
            </div>
        </div>

    </div>
    <div class="picture">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-lg-12">
                    <div class="picture-img">
                        <img class="w-100 tracky-mockup img-fluid" src={cutout1} alt="" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="position-relative py-5">
        <div class="container custom-cn-width">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="heading">
                        <div class="content1 d-flex align-items-center">
                            <img src={mini} class="img-fluid" alt="" />
                            <p class="fs-3 ">Scenario</p>
                        </div>
                    </div>
                    <p class="p-4">
                        In the traditional hiring landscape, organizations often face challenges in managing the
                        recruitment lifecycle efficiently. Manual processes, a lack of collaboration among hiring teams,
                        and a disconnect in candidate experiences can lead to prolonged hiring cycles, loss of top
                        talent, and increased operational costs.</p>
                    <div class="heading">
                        <div class="content1 d-flex align-items-center">
                            <img src={mini} class="img-fluid" alt="" />
                            <p class="fs-3 ">CDS Solution Approach</p>
                        </div>
                    </div>
                    <p class="p-4">
                        We have successfully crafted an innovative and comprehensive solution named "Hire Harmony" to
                        revolutionize the hiring process for organizations. Boasting an intuitive and user-friendly. The
                        platform empowers Hiring Managers with efficient tools to create, manage, and distribute job
                        listings across diverse platforms.</p>
                </div>
                <div class="col-lg-6">
                    <div class="tracky-middle  d-flex justify-content-center">
                        <img class="rounded-3 py-0 img-fluid" src={cutout2} alt="" />
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div class="picture1">
        <div class="container custom-cn-width">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="picture1-img text-center">
                        <img class="rounded-3 py-0 img-fluid" src={cutout3} alt="" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="impact py-5">
        <div class="container custom-cn-width">
            <div class="row">
                <div class="col-lg-3 col-sm-12 ">
                    <div class="heading">
                        <div class="content1 d-flex align-items-center">
                            <img src={mini} class="img-fluid" alt="" />
                            <p class="fs-3 ">Impact</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-9 col-sm-12  ">
                    <ul class="mb-0 pt-2 ps-4 ps-lg-0 list-styled">
                        <li>Job Creation and Distribution</li>
                        <li>Candidate Profile Management</li>
                        <li>Interview Scheduling</li>
                        <li>AI-Powered Candidate Matching</li>
                        <li>Candidate Application and Management</li>
                        <li>AI-Driven Interview Process</li>
                        <li>Skill Assessments and Results</li>
                        <li>Candidate Dashboard</li>

                    </ul>
                </div>
                <div class="col-lg-3 col-sm-12 pb-3 pt-5">
                    <div class="heading">
                        <div class="content1 d-flex align-items-start ">
                            <img src={mini} class="img-fluid" alt="" />
                            <p class="fs-3 ">Technology Stack</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-9 col-sm-12 pt-5">
                    <div class="impact-img4 d-flex align-items-center">
                        <div class="img2 p-2 pb-0 case"><img src={tech1} class="img-fluid" alt="" />
                        </div>
                        <div class="img2 p-2 pb-0 case"><img src={tech2} class="img-fluid" alt="" />
                        </div>
                        <div class="img2 p-2 pb-0  case"><img src={tech3} class="img-fluid" alt="" />
                        </div>
                        <div class="img2 p-2 pb-0 case"><img src={tech4} class="img-fluid" alt="" />
                        </div>
                        <div class="img2 p-2 pb-0 case"><img src={tech5} class="img-fluid" alt="" />
                        </div>
                    </div>

                </div>
                <div class="col-lg-3 col-sm-12  pt-5">
                    <div class="heading">
                        <div class="content1 d-flex align-items-center">
                            <img src={mini} class="img-fluid" alt="" />
                            <p class="fs-3 ">Conclusion</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-9 col-sm-12 pt-lg-5 pt-2">
                    <p class="pt-2">This mobile application addresses the diverse needs of organizations by offering an
                        intuitive, collaborative, and efficient solution for scheduling, managing, and optimizing
                        meetings for all users.</p>
                </div>

            </div>
        </div>
    </div>
    <div class="more-cases pb-5">
        <div class="container custom-cn-width">
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="heading">
                        <div class="content1 d-flex align-items-center">
                            <img src={mini} class="img-fluid" alt="" />
                            <p class="fs-3 ">Other Case Studies</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row text-center py-4 g-4">
                <div class="col-lg-4 col-sm-12">
                    <Link to="/rcm">
                        <div class="case rounded-3  h-100 case1 p-4 bg-light">
                            <div class="case-title">
                                <h4 class="">RCM</h4>
                            </div>
                            <p class=" pt-2"> Gen AI-Powered Solution Revolutionizing Revenue Cycle Management
                            </p>
                            <div class="case-img"><img class="img-fluid rounded py-3" src={case1} alt="" />
                            </div>

                        </div>
                    </Link>
                </div>
                <div class="col-lg-4 col-sm-12 ">
                    <Link to="/meeting-minder">
                        <div class="case rounded-3  h-100 case1 p-4 bg-light">
                            <div class="case-title">
                                <h4 class="">Meeting Minder</h4>
                            </div>
                            <p class=" pt-2">Mobile application is used for scheduling and managing the
                                meetings
                            </p>
                            <div class="case-img"><img class="img-fluid rounded py-3" src={case2} alt="" />
                            </div>
                        </div>
                    </Link>
                </div>
                <div class="col-lg-4 col-sm-12">
                    <Link to="/clued-in-closet">
                        <div class="case rounded-3 h-100 case1 p-4 bg-light">
                            <div class="case-title">
                                <h4 class="">Clued.in Closet</h4>
                            </div>
                            <p class=" pt-2">Efficiently organize your closet within the app.</p>
                            <div class="case-img"><img class="img-fluid rounded py-3" src={case3} alt="" />
                            </div>

                        </div>
                    </Link>
                </div>
            </div>
        </div>
    </div>

    <FooterOne />
</div>
)
}

export default HiireHormony;