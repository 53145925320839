import React, { useEffect } from 'react';
import { Link } from "react-router-dom";

import HeaderLight from '../../../common/header/HeaderLight';
import FooterOne from '../../../common/footer/FooterOne';

import banner from '../../../../src/app/assets/images/casestudy/aiya/aiya1.png'
import mini from '../../../../src/app/assets/images/casestudy/tech/mini-equ.png'
import cutout1 from '../../../../src/app/assets/images/casestudy/aiya/aiya2.png'
import cutout2 from '../../../../src/app/assets/images/casestudy/aiya/aiya3.png'
import cutout3 from '../../../../src/app/assets/images/casestudy/aiya/aiya4.png'


import tech1 from '../../../../src/app/assets/images/casestudy/tech/php.png'
import tech2 from '../../../../src/app/assets/images/casestudy/tech/json.png'
import tech3 from '../../../../src/app/assets/images/casestudy/tech/my-sql.png'
import tech4 from '../../../../src/app/assets/images/casestudy/tech/linux.png'

import case1 from '../../../../src/app/assets/images/casestudy/othercase/smart-swatcher1.png'
import case2 from '../../../../src/app/assets/images/casestudy/othercase/steppa1.png'
import case3 from '../../../../src/app/assets/images/casestudy/othercase/rcm1.png'

const Aiya = () => {
	useEffect(() => {
	  document.title = 'AIYA | CAP Digisoft ';
	}, []);
return (
<div className="casestudy-practica-page">
    <HeaderLight />
    <div class="container-fluid hero-banner pt-0 px-0 margin-minus">
        <div class="masthead tracky pt-lg-5">
            <h2 class="text-white text-center pt-5">AIYA</h2>
            <p class="text-white text-center pb-4 pt-3">
            Generative AI powered knowledge assistant designed to
					elevate the performance and decision making capabilities
            </p>
            <div class="img text-center">
                <img alt="" class="img-fluid rounded-3 pb-5" src={banner} />
            </div>
        </div>
    </div>
    <div class="about py-5">
        <div class="container custom-cn-width">
            <div class="row">
                <div class="col-lg-3 col-md-4 col-sm-12 ">
                    <div class="heading">
                        <div class="content1 d-flex align-items-center">
                            <img src={mini} class="img-fluid" alt="" />
                            <p class="fs-3 ">What is AIYA?</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-9 col-md-8 col-sm-12">
                    <p>
                        AIYA is a cutting-edge generative AI-powered knowledge assistant that leverages advanced algorithms
                        to synthesize, organize, and deliver relevant information. Tailored to elevate decision-making
                        capabilities, AIYA transforms data into actionable insights, providing users with a dynamic
                        knowledge companion.
                     </p>
                </div>
            </div>
        </div>

    </div>
    <div class="picture">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-lg-12">
                    <div class="picture-img">
                        <img class="w-100 tracky-mockup img-fluid" src={cutout1} alt="" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="position-relative py-5">
        <div class="container custom-cn-width">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="heading">
                        <div class="content1 d-flex align-items-center">
                            <img src={mini} class="img-fluid" alt="" />
                            <p class="fs-3 ">Scenario</p>
                        </div>
                    </div>
                    <p class="p-4">
                        Individuals and organizations grapple with the overwhelming volume of information available, leading
                        to challenges in extracting meaningful insights, making informed decisions, and staying updated on
                        evolving trends. This information overload hampers productivity and leaves decision-makers
                        susceptible to the risk of missing critical details.</p>
                    <div class="heading">
                        <div class="content1 d-flex align-items-center">
                            <img src={mini} class="img-fluid" alt="" />
                            <p class="fs-3 ">CDS Solution Approach</p>
                        </div>
                    </div>
                    <p class="p-4">
                        AIYA employs generative AI to synthesize vast amounts of information, distilling complex data sets
                        into concise, understandable insights. Utilizes user preferences and historical interactions to
                        tailor information delivery, ensuring that users receive relevant and timely insights aligned with
                        their specific needs. AIYA evolves with user interactions, learning from feedback and adapting to
                        changing contexts, ensuring that recommendations remain accurate and pertinent over time.
                    </p>
                </div>
                <div class="col-lg-6">
                    <div class="tracky-middle  d-flex justify-content-center">
                        <img class="rounded-3 py-0 img-fluid" src={cutout2} alt="" />
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div class="picture1">
        <div class="container custom-cn-width">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="picture1-img text-center">
                        <img class="rounded-3 py-0 img-fluid" src={cutout3} alt="" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="impact py-5">
        <div class="container custom-cn-width">
            <div class="row">
                <div class="col-lg-3 col-sm-12 ">
                    <div class="heading">
                        <div class="content1 d-flex align-items-center">
                            <img src={mini} class="img-fluid" alt="" />
                            <p class="fs-3 ">Impact</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-9 col-sm-12  ">
                    <ul class="impact pt-2 list-styled">
                        <li>Enhanced Decision-Making Speed</li>
                        <li>Increased Productivity</li>
                        <li>Strategic Insights</li>
                        <li>Reduced Information Overload</li>
                        <li>Proactive Trend Identification</li>
                        <li>Knowledge Sharing and Collaboration</li>
                        <li>Customizable Insights Dashboard</li>
                    </ul>
                </div>
                <div class="col-lg-3 col-sm-12 pb-3 pt-5">
                    <div class="heading">
                        <div class="content1 d-flex align-items-start ">
                            <img src={mini} class="img-fluid" alt="" />
                            <p class="fs-3 ">Technology Stack</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-9 col-sm-12 pt-5">
                    <div class="impact-img4 d-flex align-items-center">
                        <div class="img2 p-2 pb-0 case"><img src={tech1} class="img-fluid" alt="" />
                        </div>
                        <div class="img2 p-2 pb-0 case"><img src={tech2} class="img-fluid" alt="" />
                        </div>
                        <div class="img2 p-2 pb-0 case"><img src={tech3} class="img-fluid" alt="" />
                        </div>
                        <div class="img2 p-2 pb-0 case"><img src={tech4} class="img-fluid" alt="" />
                        </div>

                    </div>

                </div>
                <div class="col-lg-3 col-sm-12  pt-5">
                    <div class="heading">
                        <div class="content1 d-flex align-items-center">
                            <img src={mini} class="img-fluid" alt="" />
                            <p class="fs-3 ">Conclusion</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-9 col-sm-12 pt-lg-5 pt-2">
                    <p class="pt-2">AIYA emerges as a transformative force in the landscape of decision support systems,
					offering a sophisticated solution to the challenges posed by information overload. By harnessing the
					power of generative AI, AIYA not only streamlines information management but elevates
					decision-making capabilities, ultimately positioning itself as a crucial ally for individuals and
					organizations navigating the complexities of the information age.</p>
                </div>

            </div>
        </div>
    </div>
    <div class="more-cases pb-5">
        <div class="container custom-cn-width">
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="heading">
                        <div class="content1 d-flex align-items-center">
                            <img src={mini} class="img-fluid" alt="" />
                            <p class="fs-3 ">Other Case Studies</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row text-center py-4 g-4">
                <div class="col-lg-4 col-sm-12">
                    <Link to="/smart-swatchers">
                        <div class="case rounded-3  h-100 case1 p-4 bg-light">
                            <div class="case-title">
                                <h4 class="">Smart Swatcher</h4>
                            </div>
                            <p class=" pt-2"> Empowering Hair Color Enthusiasts with a Mobile App 
                            </p>
                            <div class="case-img"><img class="img-fluid rounded py-3" src={case1} alt="" />
                            </div>

                        </div>
                    </Link>
                </div>
                <div class="col-lg-4 col-sm-12 ">
                    <Link to="/steppa">
                        <div class="case rounded-3  h-100 case1 p-4 bg-light">
                            <div class="case-title">
                                <h4 class="">Steppa</h4>
                            </div>
                            
                                <p class=" pt-2">Empowering Confident Real-Time Decisions through AI</p>
                           
                            <div class="case-img"><img class="img-fluid rounded py-3" src={case2} alt="" />
                            </div>
                        </div>
                    </Link>
                </div>
                <div class="col-lg-4 col-sm-12">
                    <Link to="/rcm">
                        <div class="case rounded-3 h-100 case1 p-4 bg-light">
                            <div class="case-title">
                                <h4 class="">RCM</h4>
                            </div>
                            <p class=" pt-2"> Gen AI-Powered Solution Revolutionizing Revenue Cycle Management
                            </p>
                            <div class="case-img"><img class="img-fluid rounded py-3" src={case3} alt="" />
                            </div>

                        </div>
                    </Link>
                </div>
            </div>
        </div>
    </div>

    <FooterOne />
</div>
)
}

export default Aiya;