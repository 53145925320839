import React, { useEffect } from 'react';
import { Link } from "react-router-dom";

import HeaderLight from '../../../common/header/HeaderLight';
import FooterOne from '../../../common/footer/FooterOne';

import banner from '../../../../src/app/assets/images/casestudy/carfidante/carfidante1.png'
import mini from '../../../../src/app/assets/images/casestudy/tech/mini-equ.png'
import cutout1 from '../../../../src/app/assets/images/casestudy/carfidante/carfidante2.png'
import cutout2 from '../../../../src/app/assets/images/casestudy/carfidante/carfidante3.png'
import cutout3 from '../../../../src/app/assets/images/casestudy/carfidante/carfidante4.png'


import tech1 from '../../../../src/app/assets/images/casestudy/tech/figma.png'

import case1 from '../../../../src/app/assets/images/casestudy/othercase/steppa1.png'
import case2 from '../../../../src/app/assets/images/casestudy/othercase/verific1.png'
import case3 from '../../../../src/app/assets/images/casestudy/othercase/aiya1.png'

const Carfidante = () => {

	useEffect(() => {
	  document.title = 'Carfidante | CAP Digisoft ';
	}, []);

return (
<div className="casestudy-practica-page">
    <HeaderLight />
    <div class="container-fluid hero-banner pt-0 px-0 margin-minus">
        <div class="masthead tracky pt-lg-5">
            <h2 class="text-white text-center pt-5">Carfidante</h2>
		    <p class="text-white text-center pt-3">A cutting-edge mobile application designed to redefine the
			    experience of buying pre-owned cars</p>
            <div class="img text-center">
                <img alt="" class="img-fluid rounded-3 pb-5" src={banner} />
            </div>
        </div>
    </div>
    <div class="about py-5">
        <div class="container custom-cn-width">
            <div class="row">
                <div class="col-lg-3 col-md-4 col-sm-12 ">
                    <div class="heading">
                        <div class="content1 d-flex align-items-center">
                            <img src={mini} class="img-fluid" alt="" />
                            <p class="fs-3 ">What is Carfidante?</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-9 col-md-8 col-sm-12">
				    <p>
					Carfidante is a state-of-the-art mobile application that redefines the pre-owned car purchasing
					experience. It combines advanced technology, user-friendly design, and a comprehensive approach to
					empower users with the information and tools needed to make confident and informed decisions in the
					used car market. </p>
                </div>
            </div>
        </div>

    </div>
    <div class="picture">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-lg-12">
                    <div class="picture-img">
                        <img class="w-100 tracky-mockup img-fluid" src={cutout1} alt="" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="position-relative py-5">
        <div class="container custom-cn-width">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="heading">
                        <div class="content1 d-flex align-items-center">
                            <img src={mini} class="img-fluid" alt="" />
                            <p class="fs-3 ">Scenario</p>
                        </div>
                    </div>
                    <p class="p-4">
					The traditional pre-owned car buying process is riddled with uncertainties, lack of transparency,
					and time-consuming procedures. Buyers often face challenges in assessing the true condition of a
					vehicle, negotiating fair prices, and navigating complex paperwork. Sellers, on the other hand,
					struggle to reach the right audience and build trust. This creates a market inefficiency that
					impacts both sides of the transaction.</p>
                    <div class="heading">
                        <div class="content1 d-flex align-items-center">
                            <img src={mini} class="img-fluid" alt="" />
                            <p class="fs-3 ">CDS Solution Approach</p>
                        </div>
                    </div>
                    <p class="p-4">
					Carfidante provides detailed insights into the condition, maintenance history, and performance
					metrics of each listed vehicle, ensuring buyers make informed decisions. The app utilizes market
					data and machine learning to establish fair and transparent pricing, facilitating seamless
					negotiations between buyers and sellers.
					Carfidante simplifies the transaction process, offering in-app tools for paperwork, payments, and
					logistics, reducing the time and effort required to complete a purchase.
					Establishes a community-driven rating system for both buyers and sellers, fostering trust and
					accountability within the Carfidante platform.</p>
                </div>
                <div class="col-lg-6">
                    <div class="tracky-middle  d-flex justify-content-center">
                        <img class="rounded-3 py-0 img-fluid" src={cutout2} alt="" />
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div class="picture1">
        <div class="container custom-cn-width">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="picture1-img text-center">
                        <img class="rounded-3 py-0 img-fluid" src={cutout3} alt="" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="impact py-5">
        <div class="container custom-cn-width">
            <div class="row">
                <div class="col-lg-3 col-sm-12 ">
                    <div class="heading">
                        <div class="content1 d-flex align-items-center">
                            <img src={mini} class="img-fluid" alt="" />
                            <p class="fs-3 ">Impact</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-9 col-sm-12  ">
                    <ul class="impact pt-2 list-styled">
                        <li>Empowered Buyers</li>
                        <li>Efficient Transactions</li>
                        <li>Increased Seller Reach</li>
                        <li>Market Transparency</li>
                        <li>Data-Driven Decision Making</li>
                        <li>Market Standardization</li>
                    </ul>
                </div>
                <div class="col-lg-3 col-sm-12 pb-3 pt-5">
                    <div class="heading">
                        <div class="content1 d-flex align-items-start ">
                            <img src={mini} class="img-fluid" alt="" />
                            <p class="fs-3 ">Technology Stack</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-9 col-sm-12 pt-5">
                    <div class="impact-img4  d-flex align-items-center">
                        <div class="img2 p-2 pb-0 case"><img src={tech1} class="img-fluid" alt="" />
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-12  pt-5">
                    <div class="heading">
                        <div class="content1 d-flex align-items-center">
                            <img src={mini} class="img-fluid" alt="" />
                            <p class="fs-3 ">Conclusion</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-9 col-sm-12 pt-lg-5 pt-2">
				<p class="pt-2">Carfidante's innovative approach not only addresses the inherent challenges of buying
					and
					selling pre-owned cars but elevates the entire experience for users. By leveraging technology to
					enhance
					transparency, streamline transactions, and build a trustworthy community, Carfidante emerges as a
					transformative force in the pre-owned car market, promising a future where every transaction is
					seamless, informed, and satisfying for both buyers and sellers.</p>
                </div>

            </div>
        </div>
    </div>
    <div class="more-cases pb-5">
        <div class="container custom-cn-width">
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="heading">
                        <div class="content1 d-flex align-items-center">
                            <img src={mini} class="img-fluid" alt="" />
                            <p class="fs-3 ">Other Case Studies</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row text-center py-4 g-4">
                <div class="col-lg-4 col-sm-12">
                    <Link to="/steppa">
                        <div class="case rounded-3  h-100 case1 p-4 bg-light">
						<div class="case-title">
							<h4>Steppa</h4>
						</div>
						<p class="pt-2">Empowering Confident Real-Time Decisions through AI
						</p>
                            <div class="case-img"><img class="img-fluid rounded py-3" src={case1} alt="" />
                            </div>

                        </div>
                    </Link>
                </div>
                <div class="col-lg-4 col-sm-12 ">
                    <Link to="/verific">
                        <div class="case rounded-3  h-100 case1 p-4 bg-light">
						<div class="case-title">
							<h4>Verific</h4>
						</div>
						<p class="pt-2">Transform customer feedback into actionable insights
						</p>
                            <div class="case-img"><img class="img-fluid rounded py-3" src={case2} alt="" />
                            </div>
                        </div>
                    </Link>
                </div>
                <div class="col-lg-4 col-sm-12">
                    <Link to="/aiya">
                        <div class="case rounded-3 h-100 case1 p-4 bg-light">
						<div class="case-title">
							<h4>Aiya</h4>
						</div>
						<p class="pt-2"> Generative AI powered knowledge assistant </p>
                            <div class="case-img"><img class="img-fluid rounded py-3" src={case3} alt="" />
                            </div>

                        </div>
                    </Link>
                </div>
            </div>
        </div>
    </div>

    <FooterOne />
</div>
)
}

export default Carfidante;