import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import HeaderLight from "../../../common/header/HeaderLight";
import FooterOne from "../../../common/footer/FooterOne";

import banner from "../../../../src/app/assets/images/casestudy/predixa/predixa1.png";
import mini from "../../../../src/app/assets/images/casestudy/tech/mini-equ.png";
import cutout1 from "../../../../src/app/assets/images/casestudy/predixa/predixa2.png";
import cutout2 from "../../../../src/app/assets/images/casestudy/predixa/predixa3.png";
import cutout3 from "../../../../src/app/assets/images/casestudy/predixa/predixa4.png";

import tech1 from "../../../../src/app/assets/images/casestudy/tech/xampp.png";
import tech2 from "../../../../src/app/assets/images/casestudy/tech/html.png";
import tech3 from "../../../../src/app/assets/images/casestudy/tech/css.png";
import tech4 from "../../../../src/app/assets/images/casestudy/tech/php.png";
import tech5 from "../../../../src/app/assets/images/casestudy/tech/my-sql.png";
import tech6 from "../../../../src/app/assets/images/casestudy/tech/json.png";
import tech7 from "../../../../src/app/assets/images/casestudy/tech/bootstrap.png";

import case1 from "../../../../src/app/assets/images/casestudy/othercase/carfidante1.png";
import case2 from "../../../../src/app/assets/images/casestudy/othercase/finax1.png";
import case3 from "../../../../src/app/assets/images/casestudy/othercase/fura.png";

const Predixa = () => {
  useEffect(() => {
    document.title = "Predixa | CAP Digisoft ";
  }, []);

  return (
    <div className="casestudy-practica-page">
      <HeaderLight />
      <div class="container-fluid hero-banner pt-0 px-0 margin-minus">
        <div class="masthead tracky pt-lg-5">
          <h2 class="text-white text-center pt-5">Predixa</h2>
          <p class="text-white text-center pb-4 pt-3">
            GenAI-powered forecasting solution platform
          </p>
          <div class="img text-center">
            <img alt="" class="img-fluid rounded-3 pb-5" src={banner} />
          </div>
        </div>
      </div>
      <div class="about py-5">
        <div class="container custom-cn-width">
          <div class="row">
            <div class="col-lg-3 col-md-4 col-sm-12 ">
              <div class="heading">
                <div class="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">What is Predixa?</p>
                </div>
              </div>
            </div>
            <div class="col-lg-9 col-md-8 col-sm-12">
              <p>
                Predixa is a cutting-edge GenAI-powered forecasting solution
                platform designed to revolutionize the way businesses approach
                demand prediction, supply chain management, and financial
                planning. Leveraging advanced artificial intelligence and
                machine learning algorithms, Predixa empowers organizations to
                make data-driven decisions, optimize operations, and stay ahead
                in today's dynamic and competitive business landscape.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="picture">
        <div class="container-fluid">
          <div class="row justify-content-center">
            <div class="col-lg-12">
              <div class="picture-img">
                <img
                  class="w-100 tracky-mockup img-fluid"
                  src={cutout1}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="position-relative py-5">
        <div class="container custom-cn-width">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="heading">
                <div class="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Scenario</p>
                </div>
              </div>
              <p class="p-4">
                In a rapidly evolving business environment, traditional
                forecasting methods often fall short in providing accurate and
                timely predictions. Unforeseen events, market fluctuations, and
                changing consumer behavior pose significant challenges for
                businesses seeking to maintain efficient operations and maximize
                profitability. The need for a sophisticated forecasting solution
                that can adapt to these complexities and provide real-time
                insights has never been more critical.
              </p>
              <div class="heading">
                <div class="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">CDS Solution Approach</p>
                </div>
              </div>
              <p class="p-4">
                Predixa employs advanced AI-driven predictive analytics to
                analyze historical data and generate accurate forecasts. By
                integrating real-time data, the platform ensures
                up-to-the-minute insights for agile decision-making. Predixa
                facilitates scenario modeling, allowing users to simulate
                various conditions and develop resilient strategies. The
                automation of decision support processes streamlines operations,
                leading to improved accuracy, enhanced supply chain resilience,
                and a competitive advantage for businesses.
              </p>
            </div>
            <div class="col-lg-6">
              <div class="tracky-middle  d-flex justify-content-center">
                <img class="rounded-3 py-0 img-fluid" src={cutout2} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="picture1">
        <div class="container custom-cn-width">
          <div class="row">
            <div class="col-lg-12 col-md-12">
              <div class="picture1-img text-center">
                <img class="rounded-3 py-0 img-fluid" src={cutout3} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="impact py-5">
        <div class="container custom-cn-width">
          <div class="row">
            <div class="col-lg-3 col-sm-12 ">
              <div class="heading">
                <div class="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Impact</p>
                </div>
              </div>
            </div>
            <div class="col-lg-9 col-sm-12  ">
              <ul class="impact pt-2 list-styled">
                <li>AI-Driven Predictive Analytics</li>
                <li>Real-time Data Integration</li>
                <li>Automated Decision Support</li>
                <li>Improved Forecasting Accuracy</li>
                <li>Enhanced Supply Chain Resilience</li>
                <li>Competitive Advantage</li>
              </ul>
            </div>
            <div class="col-lg-3 col-sm-12 pb-3 pt-5">
              <div class="heading">
                <div class="content1 d-flex align-items-start ">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Technology Stack</p>
                </div>
              </div>
            </div>
            <div class="col-lg-9 col-sm-12 pt-5">
              <div class="impact-img4  d-flex align-items-center">
                <div class="img2 p-2 pb-0 case">
                  <img src={tech1} class="img-fluid" alt="" />
                </div>
                <div class="img2 p-2 pb-0 case">
                  <img src={tech2} class="img-fluid" alt="" />
                </div>
                <div class="img2 p-2 pb-0  case">
                  <img src={tech3} class="img-fluid" alt="" />
                </div>
                <div class="img2 p-2 pb-0 case">
                  <img src={tech4} class="img-fluid" alt="" />
                </div>
                <div class="img2 p-2 pb-0 case">
                  <img src={tech5} class="img-fluid" alt="" />
                </div>
              </div>
              <div class="impact-img4  d-flex align-items-center">
                <div class="img2 p-2 pb-0 case">
                  <img src={tech7} class="img-fluid" alt="" />
                </div>
                <div class="img2 p-2 pb-0 case">
                  <img src={tech6} class="img-fluid" alt="" />
                </div>
              </div>
              <div class="impact-img4  d-flex align-items-center"></div>
            </div>

            <div class="col-lg-3 col-sm-12  pt-5">
              <div class="heading">
                <div class="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Conclusion</p>
                </div>
              </div>
            </div>
            <div class="col-lg-9 col-sm-12 pt-lg-5 pt-2">
              <p class="pt-2">
                Predixa represents a paradigm shift in forecasting solutions,
                combining the power of GenAI with real-time data integration to
                deliver unparalleled accuracy and adaptability. As businesses
                face increasingly complex challenges, Predixa empowers them to
                not only navigate uncertainties but also thrive in dynamic
                markets. With its transformative impact on forecasting, supply
                chain management, and decision-making processes, Predixa stands
                as a beacon of innovation in the ever-evolving landscape of
                AI-powered business solutions.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="more-cases pb-5">
        <div class="container custom-cn-width">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12">
              <div class="heading">
                <div class="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Other Case Studies</p>
                </div>
              </div>
            </div>
          </div>
          <div class="row text-center py-4 g-4">
            <div class="col-lg-4 col-sm-12">
              <Link to="/carfidante">
                <div class="case rounded-3  h-100 case1 p-4 bg-light">
                  <div class="case-title">
                    <h4 class="">Carfidante</h4>
                  </div>
                  <p class="pt-2">
                    A application to redefine the experience of buying pre-owned
                    cars
                  </p>
                  <div class="case-img">
                    <img class="img-fluid rounded py-3" src={case1} alt="" />
                  </div>
                </div>
              </Link>
            </div>
            <div class="col-lg-4 col-sm-12 ">
              <Link to="/finax">
                <div class="case rounded-3  h-100 case1 p-4 bg-light">
                  <div class="case-title">
                    <h4 class="">Finax</h4>
                  </div>
                  <p class="pt-2">
                    An intelligent, generative AI -driven platform provides
                    dynamic, actionable insights to help you make informed
                    financial decisions with confidence
                  </p>
                  <div class="case-img">
                    <img class="img-fluid rounded py-3" src={case2} alt="" />
                  </div>
                </div>
              </Link>
            </div>
            <div class="col-lg-4 col-sm-12">
              <Link to="/fura">
                <div class="case rounded-3 h-100 case1 p-4 bg-light">
                  <div class="case-title">
                    <h4 class="">Fura</h4>
                  </div>
                  <p class="pt-2">
                    AI powered speciality pharmacy prescription fulfillment
                    booster
                  </p>
                  <div class="case-img">
                    <img class="img-fluid rounded py-3" src={case3} alt="" />
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <FooterOne />
    </div>
  );
};

export default Predixa;
