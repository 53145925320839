import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import ReactPlayer from 'react-player'
import HeaderLight from '../../../common/header/HeaderLight';
import FooterOne from '../../../common/footer/FooterOne';

import banner from '../../../../src/app/assets/images/casestudy/genie/banner.mp4'
import mini from '../../../../src/app/assets/images/casestudy/tech/mini-equ.png'
import cutout1 from '../../../../src/app/assets/images/casestudy/genie/cutout1.png'
import cutout2 from '../../../../src/app/assets/images/casestudy/genie/cutout2.png'
import cutout3 from '../../../../src/app/assets/images/casestudy/genie/cutout3.png'

import tech1 from '../../../../src/app/assets/images/casestudy/tech/api.png'
import tech2 from '../../../../src/app/assets/images/casestudy/tech/react.png'
import tech3 from '../../../../src/app/assets/images/casestudy/tech/php.png'
import tech4 from '../../../../src/app/assets/images/casestudy/tech/my-sql.png'

import case1 from '../../../../src/app/assets/images/casestudy/othercase/tracky.png'
import case2 from '../../../../src/app/assets/images/casestudy/othercase/attesta.png'
import case3 from '../../../../src/app/assets/images/casestudy/othercase/bussopi.png'

const Genie = () => {
    
	useEffect(() => {
        document.title = 'Genie | CAP Digisoft ';
      }, []);
return (
<div className="casestudy-genie-page">
    <HeaderLight />
    <div class="container-fluid pt-0 px-0 margin-minus">
        <div class="masthead sunbelt">
            <h2 class="text-white text-center pt-5">Genie</h2>
            <p class="text-white text-center pb-4 pt-3">The Genie app serves as a transformative launcher
            </p>
            <div className='banner-video_wrapper'>
                <ReactPlayer className="my-video" controls={true} url={banner} />
            </div>
        </div>

    </div>
    <div class="about py-5">
        <div class="container custom-cn-width">
            <div class="row">
                <div class="col-lg-4 col-md-4 col-sm-12 ">
                    <div class="heading">
                        <div class="content1 d-flex align-items-center">
                            <img src={mini} class="img-fluid" alt="" />
                            <p class="fs-3 ">What is Genie?</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-8 col-md-8 col-sm-12">
                    <p>
                        The Genie application is an innovative educational tool designed for Chrysalis Register School,
                        aimed at effectively managing and monitoring teacher information within the school committee.
                        This powerful platform enables seamless tracking of teacher activities during class sessions,
                        promoting enhanced efficiency and accountability. With Genie, the school administration can
                        effortlessly maintain comprehensive profiles of each teacher, including their qualifications,
                        experience, and specialized areas of expertise. This enables the committee to make informed
                        decisions when assigning teachers to specific classes or subjects. </p>
                </div>
            </div>
        </div>

    </div>
    <div class="picture">
        <div class="container custom-cn-width">
            <div class="row justify-content-center">
                <div class="col-lg-12">
                    <div class="picture-img">
                        <img class="w-100 tracky-mockup img-fluid" src={cutout1} alt="" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="position-relative py-5">
        <div class="container custom-cn-width">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="heading">
                        <div class="content1 d-flex align-items-center">
                            <img src={mini} class="img-fluid" alt="" />
                            <p class="fs-3 ">Scenarios</p>
                        </div>
                    </div>
                    <p class="p-4">
                        The application's data-driven approach empowers the committee to identify areas of improvement,
                        recognize outstanding educators, and implement evidence-based strategies to enhance the overall
                        quality of education provided by the institution. Genie offers a range of features to track and
                        evaluate teacher performance. It provides real-time insights into class attendance, lesson
                        plans, and student engagement, allowing administrators to assess the effectiveness of teaching
                        strategies and provide targeted support where needed. The application also facilitates
                        communication between teachers, allowing them to collaborate, share resources, and exchange
                        feedback to enhance their professional development.</p>

                </div>
                <div class="col-lg-6">
                    <div class="tracky-middle  d-flex justify-content-center">
                        <img class="rounded-3 py-0 img-fluid" src={cutout2} alt="" />
                    </div>
                </div>
                <div class="heading">
                    <div class="content1 d-flex align-items-center">
                        <img src={mini} class="img-fluid" alt="" />
                        <p class="fs-3 ">CDS Solution Approach</p>
                    </div>
                </div>
                <p class="p-4">
                    Genie offers a range of features to track and evaluate teacher performance. It provides real-time
                    insights into class attendance, lesson plans, and student engagement, allowing administrators to
                    assess the effectiveness of teaching strategies and provide targeted support where needed. The
                    application also facilitates communication between teachers, allowing them to collaborate, share
                    resources, and exchange feedback to enhance their professional development.</p>
            </div>
        </div>

    </div>
    <div class="picture1">
        <div class="container custom-cn-width">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="picture1-img text-center">
                        <img class="rounded-3 py-0 img-fluid" src={cutout3} alt="" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="impact py-5">
        <div class="container custom-cn-width">
            <div class="row">
                <div class="col-lg-3 col-sm-12 ">
                    <div class="heading">
                        <div class="content1 d-flex align-items-center">
                            <img src={mini} class="img-fluid" alt="" />
                            <p class="fs-3 ">Impact</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-9 col-sm-12  ">
                    <p className='mb-3'> Transforming Education with Genie</p>
                    <ul class="mb-0 pt-2 ps-4 ps-lg-0 list-styled ps-4">
                        <li>Augmented reality (AR) takes the learning experience to a whole new level.</li>
                        <li>The camera functionality within the Genie app revolutionizes the way teachers interact with
                            their students and facilitates engaging learning experiences.</li>
                        <li>Genie's QR scanner feature simplifies access to digital resources, creating a seamless
                            bridge between physical and digital materials.</li>
                        <li>Built-in timer feature ensures efficient time management, enabling teachers to keep track of
                            activities and maintain a structured learning environment.</li>
                        <li>leading to improved engagement and participation in the classroom.</li>
                        <li>Empowering Teachers and Engaging Students through Innovative Features</li>

                    </ul>
                </div>
                <div class="col-lg-3 col-sm-12 pb-3 pt-5">
                    <div class="heading">
                        <div class="content1 d-flex align-items-start ">
                            <img src={mini} class="img-fluid" alt="" />
                            <p class="fs-3 ">Technology Stack</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-9 col-sm-12 pt-5">
                    <div class="impact-img4 d-flex align-items-center">
                        <div class="img2 p-2 pb-0 case"><img src={tech1} class="img-fluid" alt="" />
                        </div>
                        <div class="img2 p-2 pb-0 case"><img src={tech2} class="img-fluid" alt="" />
                        </div>
                        <div class="img2 p-2 pb-0  case"><img src={tech3} class="img-fluid" alt="" />
                        </div>
                        <div class="img2 p-2 pb-0 case"><img src={tech4} class="img-fluid" alt="" />
                        </div>

                    </div>

                </div>
                <div class="col-lg-3 col-sm-12  pt-5">
                    <div class="heading">
                        <div class="content1 d-flex align-items-center">
                            <img src={mini} class="img-fluid" alt="" />
                            <p class="fs-3 ">Conclusion</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-9 col-sm-12 pt-lg-5 pt-2">
                    <p class="pt-2">Genie serves as a vital tool for Chrysalis Register School, revolutionizing the way
                        teacher information is managed and enabling effective monitoring of classroom activities. With
                        its user-friendly interface and powerful features, Genie empowers both teachers and
                        administrators to strive for excellence in education.</p>
                </div>

            </div>
        </div>
    </div>
    <div class="more-cases pb-5">
        <div class="container custom-cn-width">
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="heading">
                        <div class="content1 d-flex align-items-center">
                            <img src={mini} class="img-fluid" alt="" />
                            <p class="fs-3 ">Other Case Studies</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row text-center py-4 g-4">
                <div class="col-lg-4 col-sm-12">
                    <Link to="/tracky">
                        <div class="case rounded-3  h-100 case1 p-4 bg-light">
                            <div class="case-title">
                                <h4 class="">Tracky</h4>
                            </div>
                            <p class=" pt-2"> A perfect tool to help you control your workspace </p>
                            <div class="case-img"><img class="img-fluid rounded py-3" src={case1} alt="" />
                            </div>

                        </div>
                    </Link>
                </div>
                <div class="col-lg-4 col-sm-12 ">
                    <Link to="/attesta_ai">
                        <div class="case rounded-3  h-100 case1 p-4 bg-light">
                            <div class="case-title">
                                <h4 class="">Attesta AI</h4>
                            </div>
                            <p class=" pt-2">Amazing AI-based document discovery to Reduce your review time by
                                70%</p>
                            <div class="case-img"><img class="img-fluid rounded py-3" src={case2} alt="" />
                            </div>
                        </div>
                    </Link>
                </div>
                <div class="col-lg-4 col-sm-12">
                    <Link to="/bussopi">
                        <div class="case rounded-3  h-100 case1 p-4 bg-light">
                            <div class="case-title">
                                <h4 class="">Bussopi</h4>
                            </div>
                            <p class=" pt-2">School app for learning</p>
                            <div class="case-img"><img class="img-fluid rounded py-3" src={case3} alt="" />
                            </div>

                        </div>
                    </Link>
                </div>
            </div>
        </div>
    </div>

    <FooterOne />
</div>
)
}

export default Genie;