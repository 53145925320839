import React,{ useEffect } from 'react';
import { Link } from "react-router-dom";

import HeaderLight from '../../../common/header/HeaderLight';
import FooterOne from '../../../common/footer/FooterOne';

import banner from '../../../../src/app/assets/images/casestudy/bussopi/banner.png'
import mini from '../../../../src/app/assets/images/casestudy/tech/mini-equ.png'
import cutout1 from '../../../../src/app/assets/images/casestudy/bussopi/cutout1.png'
import cutout2 from '../../../../src/app/assets/images/casestudy/bussopi/cutout2.png'
import cutout3 from '../../../../src/app/assets/images/casestudy/bussopi/cutout3.png'

import tech1 from '../../../../src/app/assets/images/casestudy/tech/figma.png'

import case1 from '../../../../src/app/assets/images/casestudy/othercase/attesta.png'
import case2 from '../../../../src/app/assets/images/casestudy/othercase/tracky.png'
import case3 from '../../../../src/app/assets/images/casestudy/othercase/sunbelt.png'

const Bussipi = () => {

	useEffect(() => {
	  document.title = 'Bussopi | CAP Digisoft ';
	}, []);

return (
<div className="casestudy-practica-page">
    <HeaderLight />
    <div class="container-fluid hero-banner pt-0 px-0 margin-minus">
        <div class="masthead tracky pt-lg-5">
            <h2 class="text-white text-center pt-5">Bussopi</h2>
            <p class="text-white text-center pb-4 pt-3">Online learning platform with mini interactive games based on
                the topics
            </p>
            <div class="img text-center">
                <img alt="" class="img-fluid rounded-3 pb-5" src={banner} />
            </div>
        </div>
    </div>
    <div class="about py-5">
        <div class="container custom-cn-width">
            <div class="row">
                <div class="col-lg-3 col-md-4 col-sm-12 ">
                    <div class="heading">
                        <div class="content1 d-flex align-items-center">
                            <img src={mini} class="img-fluid" alt="" />
                            <p class="fs-3 ">What is Bussopi?</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-9 col-md-8 col-sm-12">
                    <p>
                        Bussopi is a high-level identity validator APP to identify and manage information of an
                        individual accurately with a high degree of reliability. The information contains complete legal
                        certified documents along with physical, health and personality behaviour of an individual. </p>
                </div>
            </div>
        </div>

    </div>
    <div class="picture">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-lg-12">
                    <div class="picture-img">
                        <img class="w-100 tracky-mockup img-fluid" src={cutout1} alt="" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="position-relative py-5">
        <div class="container custom-cn-width">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="heading">
                        <div class="content1 d-flex align-items-center">
                            <img src={mini} class="img-fluid" alt="" />
                            <p class="fs-3 ">Scenario</p>
                        </div>
                    </div>
                    <p class="p-4">
                        There are several tools and apps available that use retina and biometric recognition for
                        verification purposes. One example is a biometric authentication system that uses a combination
                        of retina and fingerprint scanning to verify a person's identity. But none of them found to be
                        interactive and has a study about the individual’s health and personality behaviour.</p>
                    <div class="heading">
                        <div class="content1 d-flex align-items-center">
                            <img src={mini} class="img-fluid" alt="" />
                            <p class="fs-3 ">CDS Solution Approach</p>
                        </div>
                    </div>
                    <p class="p-4">
                        We came up with Bussopi, simple yet powerful way to view and identify information that can be
                        easily integrated with other systems, such as access control systems or time and screen systems,
                        to provide a seamless verification experience.The screening process and validation metrics is
                        highly secured and innovative, the personal insights and behaviour can be viewed according to the
                        set parameters.
                    </p>
                </div>
                <div class="col-lg-6">
                    <div class="tracky-middle  d-flex justify-content-center">
                        <img class="rounded-3 py-0 img-fluid" src={cutout2} alt="" />
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div class="picture1">
        <div class="container custom-cn-width">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="picture1-img text-center">
                        <img class="rounded-3 py-0 img-fluid" src={cutout3} alt="" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="impact py-5">
        <div class="container custom-cn-width">
            <div class="row">
                <div class="col-lg-3 col-sm-12 ">
                    <div class="heading">
                        <div class="content1 d-flex align-items-center">
                            <img src={mini} class="img-fluid" alt="" />
                            <p class="fs-3 ">Impact</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-9 col-sm-12  ">
                    <p className='mb-3'>Perfect Platform to connect and validate individual person by</p>
                    <ul class="mt-top list-styled ps-4">
                        <li>Creating Profiles (Individual or Company)</li>
                        <li>Sign up and add Data To fields.</li>
                        <li>Face Recognition and Character Detailing</li>
                        <li>Fetch Legal Document.</li>
                        <li>Understand persona. </li>
                        <li>Detailed Report of Geographic and Sentimental analysis. </li>
                        <li>Generate Report on Health and disease diagnosis. </li>

                    </ul>
                </div>
                <div class="col-lg-3 col-sm-12 pb-3 pt-5">
                    <div class="heading">
                        <div class="content1 d-flex align-items-start ">
                            <img src={mini} class="img-fluid" alt="" />
                            <p class="fs-3 ">Technology Stack</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-9 col-sm-12 pt-5">
                    <div class="impact-img4 d-flex align-items-center">
                        <div class="img2 p-2 pb-0 case"><img src={tech1} class="img-fluid" alt="" />
                        </div>

                    </div>

                </div>
                <div class="col-lg-3 col-sm-12  pt-5">
                    <div class="heading">
                        <div class="content1 d-flex align-items-center">
                            <img src={mini} class="img-fluid" alt="" />
                            <p class="fs-3 ">Conclusion</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-9 col-sm-12 pt-lg-5 pt-2">
                    <p class="pt-2">This system is created with multiple user profile case study and has the integrity
                        to suit high security settings, such as government agencies and military bases, as it provides a
                        high level of accuracy and security.</p>
                </div>

            </div>
        </div>
    </div>
    <div class="more-cases pb-5">
        <div class="container custom-cn-width">
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="heading">
                        <div class="content1 d-flex align-items-center">
                            <img src={mini} class="img-fluid" alt="" />
                            <p class="fs-3 ">Other Case Studies</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row text-center py-4 g-4">
                <div class="col-lg-4 col-sm-12">
                    <Link to="/sunbelt">
                        <div class="case rounded-3  h-100 case1 p-4 bg-light">
                            <div class="case-title">
                                <h4 class="">Sunbelt</h4>
                            </div>
                            <p class=" pt-2"> Performance Tracker & Store Optimizer
                            </p>
                            <div class="case-img"><img class="img-fluid rounded py-3" src={case1} alt="" />
                            </div>

                        </div>
                    </Link>
                </div>
                <div class="col-lg-4 col-sm-12 ">
                    <Link to="/tracky">
                        <div class="case rounded-3  h-100 case1 p-4 bg-light">
                            <div class="case-title">
                                <h4 class="">Tracky</h4>
                            </div>
                            <p class=" pt-2">
                                <p class="pt-2">A perfect tool to help you control your workspace</p>
                            </p>
                            <div class="case-img"><img class="img-fluid rounded py-3" src={case2} alt="" />
                            </div>
                        </div>
                    </Link>
                </div>
                <div class="col-lg-4 col-sm-12">
                    <Link to="/attesta_ai">
                        <div class="case rounded-3  h-100 case1 p-4 bg-light">
                            <div class="case-title">
                                <h4 class="">Attesta AI</h4>
                            </div>
                            <p class=" pt-2"> Amazing AI-based document discovery to Reduce your review time
                                by
                                70%
                            </p>
                            <div class="case-img"><img class="img-fluid rounded py-3" src={case3} alt="" />
                            </div>

                        </div>
                    </Link>
                </div>
            </div>
        </div>
    </div>

    <FooterOne />
</div>
)
}

export default Bussipi;