import React, { Component } from "react";

import sd1 from "../../../app/assets/images/sd1.png";
import sd2 from "../../../app/assets/images/sd2.png";
import sd3 from "../../../app/assets/images/sd3.png";

class Counter extends Component {
  render() {
    return (
      <section className="counter-section4 section-padding pt-0">
        <div className="container custom-cn-width">
          <div className="row justify-content-center">
            <div className="col-xl-12 counter-inner">
              <div class="row g-4 g-lg-5 justify-content-center justify-content-lg-start">
                <div class="col-lg-5 ">
                  <div class="d-flex justify-content-around flex-column h-100">
                    <h3
                      class="text-uppercase text-center fw-400 text-dark hero-text"
                    >
                      We adhere to the highest standards of Compliance
                    </h3>
                  </div>
                </div>
                <div class="col-lg-2 col-md-3 col-4">
                  <div class="d-flex justify-content-center flex-column h-100">
                    <div className="mx-auto">
                      <img src={sd1} alt="Logo" class="img-fluid mb-1" />
                    </div>
                    <div>
                      <h6 class="text-center text-dark mt-1 counter-mtext">
                        ISO 9001:2015 Certified Company
                      </h6>
                    </div>
                  </div>
                </div>
                <div class="col-lg-2 col-md-3 col-4">
                  <div class="d-flex justify-content-around flex-column h-100">
                    <div className="mx-auto">
                      <img src={sd2} alt="Logo" class="img-fluid mb-1" />
                    </div>
                    <div>
                      <h6 class="text-center text-dark mt-1 counter-mtext">
                        ISO 27001:2015 Certified Company
                      </h6>
                    </div>
                  </div>
                </div>
                <div class="col-lg-2 col-md-3 col-4">
                  <div class="d-flex justify-content-around flex-column h-100">
                    <div className="mx-auto">
                      <img src={sd3} alt="Logo" class="img-fluid mb-1" />
                    </div>
                    <div>
                      <h6 class="text-center text-dark mt-1 counter-mtext">
                        HIPAA Compliant
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Counter;
