import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import { CgFacebook } from "react-icons/cg";
import { BsTwitterX } from "react-icons/bs";
import { FaLinkedin, FaYoutube } from "react-icons/fa";
import { FaChevronUp } from "react-icons/fa6";

const FooterOne = () => {
  const { pathname } = useLocation();
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const handleScroll = () => {
    if (window.pageYOffset > 300) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <footer class="bg-dark main-cds-footer">
        <div class="container custom-cn-width">
          <div class="row align-items-center g-4 footer-sec1">
            <div class="col-lg-4 sec-title col-md-12">
              <h2 class="text-white">Need help?</h2>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="d-flex align-items-center">
                <div class="info">
                  <div class="icon text-center">
                    <svg viewBox="0 0 400 400" width="40" height="40">
                      <path
                        d={
                          "M128.61,3.48c11,4.17,15.36,13.4,17.75,23.83,4.76,20.78,9.25,41.62,13.72,62.46a49.79,49.79,0,0,1,.91,9.69c.15,10-4.52,16.74-14,20.13-6.57,2.36-13.19,4.62-19.88,6.64-6.48,2-10.07,6.44-11.6,12.69a43.38,43.38,0,0,0,1,24.91c16,47,43.81,85.91,81.24,118.07,7.61,6.54,16.23,12,24.88,17.19,5.51,3.27,12.12,4,18.63,3.3a14.69,14.69,0,0,0,11.17-6.52c3.67-5.37,7.49-10.65,11.19-16,7.5-10.84,16.24-13.67,28.5-8.45,6.62,2.83,12.9,6.5,19.24,10q17.93,9.75,35.75,19.71,4.57,2.55,8.95,5.45c8,5.3,14.4,11.73,15.24,22-.38,2.63-.77,5.26-1.15,7.88-2.63,6-4.8,12.29-8,18-14,25.41-35.3,40.86-64.25,45-5.77.83-11.71.58-17.56.83l-13.63-2c-5.39-1.68-10.86-3.15-16.17-5.07-26.65-9.61-49.17-25.81-70.13-44.41-25.1-22.28-46.87-47.61-67.06-74.32C88.74,241.78,66.33,207.64,50.1,169.77c-9-21-15.83-42.72-17.56-65.71-.5-6.69-.35-13.43-.5-20.15q1-6.81,2-13.62c.38-1,.85-2,1.13-3,9-33.22,30.78-53.37,63.56-62,7.11-1.87,14.67-2,22-3Z"
                        }
                      ></path>
                    </svg>
                  </div>
                </div>
                <div class="text ms-3">
                  <p class="font-size-16 mb-0 opacity-75">Let’s talk</p>{" "}
                  <a href="tel:2149889484" class="h3 fw-light text-white">
                    (214) 988-9484
                  </a>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="d-flex align-items-center">
                <div class="info text-center">
                  <div class="icon">
                    <i class="">
                      <svg viewBox="0 0 400 400" width="40" height="40">
                        <path d="M.85,79l188.78,139.7a18.15,18.15,0,0,0,20.68,0L399.15,79.51Z"></path>
                        <path d="M134.93,194.22,3.56,316.79a23.51,23.51,0,0,1-3.56-12V94.64Z"></path>
                        <path d="M252.76,203,385,326.54a27.75,27.75,0,0,1-12.37,2.79H27.34A27.85,27.85,0,0,1,16,327l132.1-123.43L200,242.06Z"></path>
                        <path d="M400,94.62V304.76a23.84,23.84,0,0,1-3.27,11.13L266.11,193.61Z"></path>
                      </svg>
                    </i>
                  </div>
                </div>
                <div class="text ms-3">
                  <p class="font-size-16 mb-0 opacity-75">Free consultation</p>
                  <a
                    href="mailto:info@capdigisoft.com"
                    class="h3 fw-light text-white"
                  >
                    info@capdigisoft.com
                  </a>
                </div>
              </div>
            </div>
          </div>
          <hr class="my-xl-5 my-4 bg-light footer-hr"></hr>
          <div class="row footer-bottom g-lg-0 g-4 footer-sec2">
            <div class="col-12 col-lg-2 col-md-6">
              <div>
                <h5 class="mb-lg-3 mb-2">About Us</h5>
                <ul class="list-unstyled mb-0">
                  <li>
                    <Link to="/products">Products</Link>
                  </li>
                  <li>
                    <Link to="/ourcompany">Our Company</Link>
                  </li>
                  <li>
                    <Link to="/pressrelease">Press Release</Link>
                  </li>
                  <li>
                    <Link to="/management-team">Management Team</Link>
                  </li>
                  <li>
                    <Link to="/careers">Careers</Link>{" "}
                    <span class="px-2 ms-2 text-white rounded-pill small bg-warning text-dark text-decoration-none d-inline-block d-lg-inline">
                      Hiring!
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-12 col-lg-2 col-md-6">
              <div>
                <h5 class="mb-lg-3 mb-2">Brands</h5>
                <ul class="list-unstyled mb-0">
                  <li>
                    <a target="_blank" href="https://flobile.com">
                      Flobile
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://medicallegalspider.com">
                      Medical Legal Spider
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-12 col-lg-4 col-md-6">
              <div>
                <h5 class="mb-lg-3 mb-2">Solutions</h5>
                <ul class="list-unstyled mb-0">
                  <li>
                    <Link
                      to="https://ideatoproduct.capdigisoft.com/"
                      target="_blank"
                    >
                      App Idea to Product
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://callcenter.capdigisoft.com/"
                      target="_blank"
                    >
                      Call Center Service
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://ideabooster.capdigisoft.com/"
                      target="_blank"
                    >
                      App Idea Booster
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://softwareappdevelopment.capdigisoft.com/"
                      target="_blank"
                    >
                      Mobile App &amp; Software Development
                    </Link>
                  </li>
                  <li>
                    <Link to="/generative-ai">Generative AI</Link>
                  </li>
                  {/* <li><Link  to="/marketing-and-consulting">Marketing and Consulting Services</Link></li> */}
                  <li>
                    <Link to="/it_support">IT Support</Link>
                  </li>
                  <li>
                    <Link to="/client-success">Client Success Service</Link>
                  </li>
                  <li>
                    <Link to="/staffing-support-service">
                      Staffing Solutions
                    </Link>
                  </li>
                  <li>
                    <Link to="/comprehensive-website-design">
                      Comprehensive Website Design
                    </Link>
                  </li>
                  <li>
                    <Link to="/knowledge-process-outsourcing">
                      Knowledge Process Outsourcing
                    </Link>
                  </li>
                  {/* <li><Link  to="/nearshore-call-center-outsourcing">Nearshore Call Center Outsourcing</Link>
                            </li> */}
                  <li>
                    <Link to="/b2b-lead-generation-expert">
                      B2B Lead Generation
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-2 col-md-6">
              <div>
                <h5 class="mb-lg-3 mb-2">Resources</h5>
                <ul class="list-unstyled mb-0">
                  <li>
                    <Link to="/blog">Blog</Link>
                  </li>
                  <li>
                    <Link to="/casestudy">Case Studies</Link>
                  </li>
                  <li>
                    <Link to="/freedownload">Free Downloads</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-12 col-lg-2 col-md-6">
              <div>
                <h5 class="mb-lg-3 mb-2">Help</h5>
                <ul class="list-unstyled mb-0">
                  <li>
                    <Link to="/contactus">Contact Us</Link>
                  </li>
                  <li>
                    <Link to="/terms-of-use">Terms of Use</Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="row mt-xl-5 mt-4 align-items-center">
            <div class="col-md-7">
              <p class="copyright f-15 opacity-75">
                © 2004 -
                <script>document.write(new Date().getFullYear())</script> 2024
                CAP Digisoft Solutions, Inc. All Rights Reserved.
              </p>
            </div>
            <div class="col-md-5 text-lg-end">
              <ul class="list-unstyled list-inline mb-0 footer-social ">
                <li class="list-inline-item f-15">Stay in the Loop?</li>
                <li class="list-inline-item fb opacity-75">
                  <a
                    title="Facebook"
                    href="https://www.facebook.com/capdigisoftsolutions/"
                    target="_blank"
                    aria-label="facebook"
                  >
                    <CgFacebook />
                  </a>
                </li>
                <li class="list-inline-item ln opacity-75">
                  <a
                    title="Linked In"
                    href="https://in.linkedin.com/company/cap-digisoft-solutions-inc."
                    target="_blank"
                    aria-label="linkedin"
                  >
                    <FaLinkedin />
                  </a>
                </li>
                <li class="list-inline-item tw opacity-75">
                  <a
                    title="twitter"
                    href="https://twitter.com/CAPDigisoft"
                    target="_blank"
                    aria-label="twitter"
                  >
                    <BsTwitterX />
                  </a>
                </li>
                <li class="list-inline-item tw opacity-75">
                  <a
                    target="_blank"
                    title="Youtube"
                    href="https://www.youtube.com/channel/UCGgLVDZx1q4I4F99IEx9Apw"
                    aria-label="youtube"
                  >
                    <FaYoutube />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
      {showButton && (
        <div className="scroll-up">
          <Link
            to="javascript:void(0)"
            onClick={scrollToTop}
            className="js-scroll-trigger scroll-to-top"
          >
            <FaChevronUp/>
          </Link>
        </div>
      )}
    </>
  );
};

export default FooterOne;
