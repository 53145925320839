/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { Link } from 'react-router-dom';

const products = () => {
  return (
    <>
      <div className="blog-suggestions mt-5">
        <h1 className="text-center mb-2 fw-500">You may be interested in</h1>

        <div className="row">
          <div className="col-xxl-4">
            <div className="item-box-interest">
              <h4
                className=""
                style={{
                  FontSize: "18px",
                  fontWeight: "500",
                  lineHeight: "1.2",
                  marginBottom:"1Rem",
                }}
              >
                <Link to="/marketing-strategies-for-the-age-of-everything-online">
                  Marketing Strategies for the Age of Everything Online
                </Link>
              </h4>


              <p>
                Strategic Digital Marketing Strategies for the Age of
                Everything Online The digital age has transformed the marketing
                landscape. Gone are the days of mass media campaigns and
                one-size-fits-all approaches…
              </p>

              <div class="btn-wraper mt-3">
                <Link
                  to="/marketing-strategies-for-the-age-of-everything-online"
                  class="elementskit-btn  whitespace--normal"
                >
                  Learn more{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-arrow-right"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                    />
                  </svg>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-xxl-4">
            <div className="item-box-interest">
              <h4
                className=""
                style={{
                  FontSize: "18px",
                  fontWeight: "500",
                  lineHeight: "1.2",
                  marginBottom:"1Rem",
                }}
              >
                <Link to="/the-impact-of-knowledge-processing-on-business-intelligence">
                  The Impact of Knowledge Processing on Business Intelligence
                </Link>
              </h4>


              <p>
                Transforming Data into Business Intelligence The Impact of
                Knowledge Processing on Business Intelligence The business world
                is drowning in data. Every click, purchase, and interaction
                generates a wealth of information…
              </p>

              <div class="btn-wraper mt-3">
                <Link
                  to="/the-impact-of-knowledge-processing-on-business-intelligence"
                  class="elementskit-btn  whitespace--normal"
                >
                  Learn more{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-arrow-right"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                    />
                  </svg>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-xxl-4">
            <div className="item-box-interest">
              <h4
                className=""
                style={{
                  FontSize: "18px",
                  fontWeight: "500",
                  lineHeight: "1.2",
                  marginBottom:"1Rem",
                }}
              >
                <Link to="/the-importance-of-ui-ux-design-in-todays-digital-landscape">
                  The Importance of UI/UX Design in Today’s Digital Landscape
                </Link>
              </h4>


              <p>
                Mastering UI/UX Design The Importance of UI/UX Design in Today's
                Digital Landscape Imagine walking into a store where everything
                is cluttered, confusing, and impossible to find. That's what a
                website…
              </p>

              <div class="btn-wraper mt-3">
                <Link
                  to="/the-importance-of-ui-ux-design-in-todays-digital-landscape"
                  class="elementskit-btn  whitespace--normal"
                >
                  Learn more{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-arrow-right"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                    />
                  </svg>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default products;
