import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import HeaderLight from "../../../common/header/HeaderLight";
import FooterOne from "../../../common/footer/FooterOne";

import banner from "../../../../src/app/assets/images/casestudy/rcm/rcm1.png";
import mini from "../../../../src/app/assets/images/casestudy/tech/mini-equ.png";
import cutout1 from "../../../../src/app/assets/images/casestudy/rcm/rcm2.png";
import cutout2 from "../../../../src/app/assets/images/casestudy/rcm/rcm3.png";
import cutout3 from "../../../../src/app/assets/images/casestudy/rcm/rcm4.png";

import tech1 from "../../../../src/app/assets/images/casestudy/tech/xampp.png";
import tech2 from "../../../../src/app/assets/images/casestudy/tech/html.png";
import tech3 from "../../../../src/app/assets/images/casestudy/tech/css.png";
import tech4 from "../../../../src/app/assets/images/casestudy/tech/php.png";
import tech5 from "../../../../src/app/assets/images/casestudy/tech/my-sql.png";
import tech6 from "../../../../src/app/assets/images/casestudy/tech/json.png";
import tech7 from "../../../../src/app/assets/images/casestudy/tech/bootstrap.png";

import case1 from "../../../../src/app/assets/images/casestudy/othercase/carfidante1.png";
import case2 from "../../../../src/app/assets/images/casestudy/othercase/finax1.png";
import case3 from "../../../../src/app/assets/images/casestudy/othercase/fura.png";

const RCM = () => {
  useEffect(() => {
    document.title = "RCM | CAP Digisoft ";
  }, []);

  return (
    <div className="casestudy-practica-page">
      <HeaderLight />
      <div class="container-fluid hero-banner pt-0 px-0 margin-minus">
        <div class="masthead tracky pt-lg-5">
          <h2 class="text-white text-center pt-5">RCM</h2>
          <p class="text-white text-center pb-4 pt-3">
            Gen AI-Powered Solution Revolutionizing Revenue Cycle Management
          </p>
          <div class="img text-center">
            <img alt="" class="img-fluid rounded-3 pb-5" src={banner} />
          </div>
        </div>
      </div>
      <div class="about py-5">
        <div class="container custom-cn-width">
          <div class="row">
            <div class="col-lg-3 col-md-4 col-sm-12 ">
              <div class="heading">
                <div class="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">What is RCM?</p>
                </div>
              </div>
            </div>
            <div class="col-lg-9 col-md-8 col-sm-12">
              <p>
                RCM, a Gen AI-powered solution, represents a transformative
                intervention designed to optimize operational efficiency in
                Revenue Cycle Management. Leveraging advanced artificial
                intelligence, RCM aims to streamline financial processes, reduce
                errors, and enhance overall revenue generation for healthcare
                organizations.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="picture">
        <div class="container-fluid">
          <div class="row justify-content-center">
            <div class="col-lg-12">
              <div class="picture-img">
                <img
                  class="w-100 tracky-mockup img-fluid"
                  src={cutout1}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="position-relative py-5">
        <div class="container custom-cn-width">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="heading">
                <div class="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Scenario</p>
                </div>
              </div>
              <p class="p-4">
                In the healthcare industry, traditional Revenue Cycle Management
                often faces challenges related to manual data entry, billing
                errors, lengthy reimbursement cycles, and difficulty in adapting
                to evolving regulatory requirements. These issues result in
                financial inefficiencies, increased operational costs, and
                delays in revenue realization.
              </p>
              <div class="heading">
                <div class="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">CDS Solution Approach</p>
                </div>
              </div>
              <p class="p-4">
                RCM integrates with regulatory databases and updates in
                real-time to ensure compliance with the latest industry
                standards. This feature reduces the risk of non-compliance
                penalties and streamlines the adaptation to evolving healthcare
                regulations. The solution analyzes and optimizes the entire
                revenue cycle workflow, identifying areas for improvement and
                implementing automation where possible. This leads to reduced
                operational costs, faster reimbursement cycles, and improved
                overall efficiency.
              </p>
            </div>
            <div class="col-lg-6">
              <div class="tracky-middle  d-flex justify-content-center">
                <img class="rounded-3 py-0 img-fluid" src={cutout2} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="picture1">
        <div class="container custom-cn-width">
          <div class="row">
            <div class="col-lg-12 col-md-12">
              <div class="picture1-img text-center">
                <img class="rounded-3 py-0 img-fluid" src={cutout3} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="impact py-5">
        <div class="container custom-cn-width">
          <div class="row">
            <div class="col-lg-3 col-sm-12 ">
              <div class="heading">
                <div class="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Impact</p>
                </div>
              </div>
            </div>
            <div class="col-lg-9 col-sm-12  ">
              <ul class="impact pt-2 list-styled">
                <li> Improved Accuracy and Compliance</li>
                <li>Faster Reimbursement Cycles</li>
                <li>Cost Savings and Operational Efficiency</li>
                <li>Strategic Decision-Making</li>
              </ul>
            </div>
            <div class="col-lg-3 col-sm-12 pb-3 pt-5">
              <div class="heading">
                <div class="content1 d-flex align-items-start ">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Technology Stack</p>
                </div>
              </div>
            </div>
            <div class="col-lg-9 col-sm-12 pt-5">
              <div class="impact-img4  d-flex align-items-center">
                <div class="img2 p-2 pb-0 case">
                  <img src={tech1} class="img-fluid" alt="" />
                </div>
                <div class="img2 p-2 pb-0 case">
                  <img src={tech2} class="img-fluid" alt="" />
                </div>
                <div class="img2 p-2 pb-0  case">
                  <img src={tech3} class="img-fluid" alt="" />
                </div>
                <div class="img2 p-2 pb-0 case">
                  <img src={tech4} class="img-fluid" alt="" />
                </div>
                <div class="img2 p-2 pb-0 case">
                  <img src={tech5} class="img-fluid" alt="" />
                </div>
              </div>
              <div class="impact-img4  d-flex align-items-center">
                <div class="img2 p-2 pb-0 case">
                  <img src={tech7} class="img-fluid" alt="" />
                </div>
                <div class="img2 p-2 pb-0 case">
                  <img src={tech6} class="img-fluid" alt="" />
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-sm-12  pt-5">
              <div class="heading">
                <div class="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Conclusion</p>
                </div>
              </div>
            </div>
            <div class="col-lg-9 col-sm-12 pt-lg-5 pt-2">
              <p class="pt-2">
                RCM emerges as a transformative force in the realm of Revenue
                Cycle Management, showcasing the potential of Gen AI to
                revolutionize financial processes in the healthcare industry. By
                addressing critical challenges and leveraging advanced
                technologies, RCM not only enhances operational efficiency but
                also empowers healthcare organizations to navigate the complex
                landscape of regulatory compliance and financial sustainability.
                As the healthcare sector continues to evolve, RCM stands as a
                testament to the impact of intelligent solutions in shaping the
                future of revenue management in healthcare.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="more-cases pb-5">
        <div class="container custom-cn-width">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12">
              <div class="heading">
                <div class="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Other Case Studies</p>
                </div>
              </div>
            </div>
          </div>
          <div class="row text-center py-4 g-4">
            <div class="col-lg-4 col-sm-12">
              <Link to="/carfidante">
                <div class="case rounded-3  h-100 case1 p-4 bg-light">
                  <div class="case-title">
                    <h4 class="">Carfidante</h4>
                  </div>
                  <p class="pt-2">
                    A application to redefine the experience of buying pre-owned
                    cars
                  </p>
                  <div class="case-img">
                    <img class="img-fluid rounded py-3" src={case1} alt="" />
                  </div>
                </div>
              </Link>
            </div>
            <div class="col-lg-4 col-sm-12 ">
              <Link to="/finax">
                <div class="case rounded-3  h-100 case1 p-4 bg-light">
                  <div class="case-title">
                    <h4 class="">Finax</h4>
                  </div>
                  <p class="pt-2">
                    An intelligent, generative AI -driven platform provides
                    dynamic, actionable insights to help you make informed
                    financial decisions with confidence
                  </p>
                  <div class="case-img">
                    <img class="img-fluid rounded py-3" src={case2} alt="" />
                  </div>
                </div>
              </Link>
            </div>
            <div class="col-lg-4 col-sm-12">
              <Link to="/fura">
                <div class="case rounded-3 h-100 case1 p-4 bg-light">
                  <div class="case-title">
                    <h4 class="">Fura</h4>
                  </div>
                  <p class="pt-2">
                    AI powered speciality pharmacy prescription fulfillment
                    booster
                  </p>
                  <div class="case-img">
                    <img class="img-fluid rounded py-3" src={case3} alt="" />
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <FooterOne />
    </div>
  );
};

export default RCM;
