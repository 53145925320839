import React, { useEffect } from 'react';
import { Link } from "react-router-dom";

import HeaderLight from '../../../common/header/HeaderLight';
import FooterOne from '../../../common/footer/FooterOne';

import banner from '../../../../src/app/assets/images/casestudy/meeting/hero -1.png'
import mini from '../../../../src/app/assets/images/casestudy/tech/mini-equ.png'
import cutout1 from '../../../../src/app/assets/images/casestudy/meeting/sec-2.png'
import cutout2 from '../../../../src/app/assets/images/casestudy/meeting/sec-3.png'
import cutout3 from '../../../../src/app/assets/images/casestudy/meeting/sec-4.png'

import tech1 from '../../../../src/app/assets/images/casestudy/tech/figma.png'

import case1 from '../../../../src/app/assets/images/casestudy/othercase/hireharmony.png'
import case2 from '../../../../src/app/assets/images/casestudy/othercase/J&p.png'
import case3 from '../../../../src/app/assets/images/casestudy/othercase/cluedin.png'

const HiireHormony = () => {
    
	useEffect(() => {
        document.title = 'Hire Harmony | CAP Digisoft ';
      }, []);

return (
<div className="casestudy-practica-page">
    <HeaderLight />
    <div class="container-fluid hero-banner pt-0 px-0 margin-minus">
        <div class="masthead tracky pt-lg-5">
            <h2 class="text-white text-center pt-5">Meeting Minder</h2>
            <p class="text-white text-center pb-4 pt-3">
                Mobile application is used for scheduling and managing the meetings
            </p>
            <div class="img text-center">
                <img alt="" class="img-fluid rounded-3 pb-5" src={banner} />
            </div>
        </div>
    </div>
    <div class="about py-5">
        <div class="container custom-cn-width">
            <div class="row">
                <div class="col-lg-3 col-md-4 col-sm-12 ">
                    <div class="heading">
                        <div class="content1 d-flex align-items-center">
                            <img src={mini} class="img-fluid" alt="" />
                            <p class="fs-3 ">What is Meeting Minder?</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-9 col-md-8 col-sm-12">
                    <p>
                        Meeting Minder is a versatile and indispensable tool designed for streamlined meeting management
                        across a range of professional settings. In corporate offices, it facilitates the efficient
                        scheduling and organization of team meetings, strategic planning sessions, and departmental
                        discussions. Small businesses benefit from its features for coordinating team meetings, client
                        discussions, and project updates. Educational institutions find value in "Meeting Minder" for
                        scheduling faculty meetings, parent-teacher conferences, and committee discussions.
                    </p>
                </div>
            </div>
        </div>

    </div>
    <div class="picture">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-lg-12">
                    <div class="picture-img">
                        <img class="w-100 tracky-mockup img-fluid" src={cutout1} alt="" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="position-relative py-5">
        <div class="container custom-cn-width">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="heading">
                        <div class="content1 d-flex align-items-center">
                            <img src={mini} class="img-fluid" alt="" />
                            <p class="fs-3 ">Scenario</p>
                        </div>
                    </div>
                    <p class="p-4">
                        In the fast-paced business environment, professionals often face challenges in
                        coordinating and managing meetings efficiently. The reliance on traditional methods such as emails,
                        calendars, and manual reminders can result in scheduling conflicts, missed appointments, and a lack
                        of overall organization.
                    </p>
                    <div class="heading">
                        <div class="content1 d-flex align-items-center">
                            <img src={mini} class="img-fluid" alt="" />
                            <p class="fs-3 ">CDS Solution Approach</p>
                        </div>
                    </div>
                    <p class="p-4">
                        Introducing Meeting Minder, your go-to solution for efficient meeting management. This intuitive
                        mobile app is designed to streamline the entire meeting process, from scheduling to follow-up. With
                        Meeting Minder, users can effortlessly schedule meetings, set agendas, and allocate resources,
                        ensuring a smooth and organized collaboration experience.
                    </p>
                </div>
                <div class="col-lg-6">
                    <div class="tracky-middle  d-flex justify-content-center">
                        <img class="rounded-3 py-0 img-fluid" src={cutout2} alt="" />
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div class="picture1">
        <div class="container custom-cn-width">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="picture1-img text-center">
                        <img class="rounded-3 py-0 img-fluid" src={cutout3} alt="" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="impact py-5">
        <div class="container custom-cn-width">
            <div class="row">
                <div class="col-lg-3 col-sm-12 ">
                    <div class="heading">
                        <div class="content1 d-flex align-items-center">
                            <img src={mini} class="img-fluid" alt="" />
                            <p class="fs-3 ">Impact</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-9 col-sm-12  ">
                    <ul class="mb-0 pt-2 ps-4 ps-lg-0 list-styled">
                        <li>Intuitive Scheduling</li>
                        <li>Agenda Setting</li>
                        <li>Resource Allocation</li>
                        <li>Real-time Collaboration</li>
                        <li>Communication Channels</li>
                        <li>Document Sharing</li>
                        <li>User-Friendly Interface</li>
                        <li>Accessibility for All</li>
                        <li>Productive Meeting Culture</li>
                        <li>Efficient Workflow Integration</li>
                    </ul>
                </div>
                <div class="col-lg-3 col-sm-12 pb-3 pt-5">
                    <div class="heading">
                        <div class="content1 d-flex align-items-start ">
                            <img src={mini} class="img-fluid" alt="" />
                            <p class="fs-3 ">Technology Stack</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-9 col-sm-12 pt-5">
                    <div class="impact-img4 d-flex align-items-center">
                        <div class="img2 p-2 pb-0 case"><img src={tech1} class="img-fluid" alt="" />
                        </div>
                    </div>

                </div>
                <div class="col-lg-3 col-sm-12  pt-5">
                    <div class="heading">
                        <div class="content1 d-flex align-items-center">
                            <img src={mini} class="img-fluid" alt="" />
                            <p class="fs-3 ">Conclusion</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-9 col-sm-12 pt-lg-5 pt-2">
                    <p class="pt-2">
                        This mobile application addresses the diverse needs of organizations by offering an
                        intuitive, collaborative, and efficient solution for scheduling, managing, and optimizing meetings for all
                        users.
                    </p>
                </div>

            </div>
        </div>
    </div>
    <div class="more-cases pb-5">
        <div class="container custom-cn-width">
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="heading">
                        <div class="content1 d-flex align-items-center">
                            <img src={mini} class="img-fluid" alt="" />
                            <p class="fs-3 ">Other Case Studies</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row text-center py-4 g-4">
                <div class="col-lg-4 col-sm-12">
                    <Link to="/hireharmony">
                        <div class="case rounded-3  h-100 case1 p-4 bg-light">
                            <div class="case-title">
                                <h4 class="">Hire Harmony</h4>
                            </div>
                            <p class=" pt-2"> 
                                Hire Harmony is a web application based on hiring process of an
                                organization.
                            </p>
                            <div class="case-img"><img class="img-fluid rounded py-3" src={case1} alt="" />
                            </div>

                        </div>
                    </Link>
                </div>
                <div class="col-lg-4 col-sm-12 ">
                    <Link to="/j&p_services+">
                        <div class="case rounded-3  h-100 case1 p-4 bg-light">
                            <div class="case-title">
                                <h4 class="">J&P services+</h4>
                            </div>
                            <p class=" pt-2">
                                Allows guests in J&P-partnered hotels to check in, request services.
                            </p>
                            <div class="case-img"><img class="img-fluid rounded py-3" src={case2} alt="" />
                            </div>
                        </div>
                    </Link>
                </div>
                <div class="col-lg-4 col-sm-12">
                    <Link to="/clued-in-closet">
                        <div class="case rounded-3 h-100 case1 p-4 bg-light">
                            <div class="case-title">
                                <h4 class="">Clued.in Closet</h4>
                            </div>
                            <p class=" pt-2">Efficiently organize your closet within the app.</p>
                            <div class="case-img"><img class="img-fluid rounded py-3" src={case3} alt="" />
                            </div>

                        </div>
                    </Link>
                </div>
            </div>
        </div>
    </div>

    <FooterOne />
</div>
)
}

export default HiireHormony;