import React from 'react';
import { Link } from "react-router-dom";
import HeaderDark from '../../../common/header/HeaderDark';
import FooterOne from '../../../common/footer/FooterOne';

import press1 from '../../../../src/app/assets/images/prelease/1.png'
import press2 from '../../../../src/app/assets/images/prelease/2.png'

import Meta from "../../Meta/Press-release";

const PressRelease = () => {
return (
<div className="pressrelease-page">
	<HeaderDark />
	<Meta/>
	<section class="pt-5 section-padding-btm">
		<div class="container custom-cn-width ">
			<div class="row g-5 justify-content-center text-center">
				<div class="col-lg-12 mb-4 mb-lg-5">
					<h1 class="fw-light">Press Release</h1>
				</div>
			</div>

			{/*
			<!-- Press Release Sec 1 --> */}
			<div class="row g-4 g-xl-5 text-center justify-content-center text-xl-start">
				<div class="col-lg-10 col-md-12">
					<fieldset class="transition">
						<legend class="text-xl-start text-dark"> SAN SALVADOR, El Salvador | January 10, 2023 </legend>
						<div class="row align-items-center">
							<div class="col-md-5">
								<a class="d-inline-block" href="cds-opens-new-call-center-office-in-el-salvador">
									<img src={press1} alt="team" class="border-0 rounded mb-md-0 mb-3 img-fluid" /> </a>

							</div>
							<div class="col-md-7">
								<h4><a class="text-dark font-size-24" href="cds-opens-new-call-center-office-in-el-salvador">CAP
										Digisoft Solutions, Inc. Opens New Call Center Office in El Salvador to Boost
										Market Expansion</a></h4>
								<p class="my-3">CAP Digisoft Solutions, Inc. has announced the opening of a new call
									center office in El Salvador. The state-of-the-art facility, located in the heart of
									San Salvador, will serve as a growth solution for companies seeking to expand their
									market share and improve their customer service operations.</p>
								<Link class="d-inline-block underline-border"
									to="/cds-opens-new-call-center-office-in-el-salvador"><span
										class="border-line d-inline-block me-1">Read more</span><i
										class="fa fa-arrow-right fa-sm position-relative"></i></Link>
							</div>
						</div>
					</fieldset>
				</div>
			</div>
			<div class="row g-4 g-xl-5 text-center justify-content-center text-xl-start mt-2">
				<div class="col-lg-10 col-md-12">
					<fieldset class="transition">
						<legend class="text-xl-start text-dark"> FRISCO, TEXAS | MAY 27, 2022 </legend>
						<div class="row align-items-center">
							<div class="col-md-5">
								<a class="d-inline-block" href="joiint-inc-introduces-a-new-application">
									<img src={press2} alt="team" class="border-0 rounded mb-md-0 mb-3 img-fluid" /> </a>
							</div>
							<div class="col-md-7">
								<h4><a class="text-dark font-size-24" href="joiint-inc-introduces-a-new-application">Joiint Inc.
										Introduces a New Application that Easily Juggles your Personal and Professional
										Lives. #JuglApp</a></h4>
								<p class="my-3">Jugl was first conceptualized in 2019 when the founders of Joiint, Inc.,
									Arun Kumar Ponnusamy, Ramesh Periya Samy, and Arun Prasath observed that there was a
									significant need for a new kind of collaboration application.</p>
								<Link class="d-inline-block underline-border"
									to="/joiint-inc-introduces-a-new-application"><span
										class="border-line d-inline-block me-1">Read more</span><i
										class="fa fa-arrow-right fa-sm position-relative"></i></Link>
							</div>
						</div>
					</fieldset>
				</div>
			</div>
		</div>
	</section>

	<FooterOne />
</div>
)
}

export default PressRelease;