import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import HeaderLight from "../../../common/header/HeaderLight";
import FooterOne from "../../../common/footer/FooterOne";

import banner from "../../../../src/app/assets/images/casestudy/liti/banner.jpg";

import icon1 from "../../../../src/app/assets/images//casestudy/liti/icon1.png";
import icon2 from "../../../../src/app/assets/images//casestudy/liti/icon2.png";


import PDF from "../../../app/assets/casestudypdf/liti-code-system-case-study.pdf";


const LCS = () => {
    
	useEffect(() => {
        document.title = 'Liti code system | CAP Digisoft ';
      }, []);

return (
<div className="casestudy-logistics-page">
    <HeaderLight />
    <section
        class="d-flex align-items-center justify-content-center banner-sec bg-dark case-study-sec inner margin-minus">
        <div class="container custom-cn-width position-relative">
            <div class="row position-relative g-3 mt-lg-4 align-items-center mb-md-2">
                <div class="col-lg-9">
                    <h5 class="text-white">Case Study</h5>
                    <h1 class="text-white fw-semibold">
                        Liti-code System
                    </h1>
                </div>
                <div class="col-lg-3 text-lg-end">
                <a
                href={PDF}
                class="default-btn orange-btn"
                target="_blank"
              >
                        <i class="fa fa-file-pdf me-1"></i>
                        Download PDF
                    </a>
                </div>
            </div>
        </div>
    </section>
    <section class="text-center text-lg-start section-padding">
        <div class="container custom-cn-width">
            <div class="row justify-content-center g-lg-5 g-4 align-items-center">
                <div class="col-lg-7">
                    <h1 class="fw-light mb-lg-4 mb-3">Scenario</h1>
                    <p class="">A client who was an attorney that tries medical-legal cases approached us to discuss the
                        possibility of automating their Medical Case workflow and organize their cluttered medical
                        files.</p>

                </div>
                <div class="col-lg-5 col-md-9">
                    <img src={banner} class="img-fluid b-r-6" alt="" />
                </div>
            </div>
        </div>
    </section>
    <section class="pb-0 text-center text-lg-start section-padding pt-0">
        <div class="container custom-cn-width">
            <div class="row g-xl-5 g-4">
                <div class="col-lg-7 mb-4 mb-lg-5">
                    <h1 class="fw-light">CDS Solution Approach</h1>
                </div>
            </div>
            <div class="row g-xl-5 g-4">
                <div class="col-md-6 col-lg-6">
                    <div class="features-item h-100 justify-content-start">
                        <div
                            class="icons sec-primary-ic mb-4 mx-auto mx-lg-0  d-flex align-items-center justify-content-center rounded-pill">
                            <img src={icon1} class="img-fluid" alt="Prospect Database Cleansing" />
                        </div>
                        <h4>Approach</h4>
                        <p class="mt-3">
                            Our approach was to correctly unify and code documents to help create accurate databases,
                            saving the time and effort of the end-user to a great extent. We developed a custom
                            application to automate the process of a Medical Case workflow system that has a Customer,
                            Admin, and Production team. The system facilitates the production workflow of the Medical
                            Case where the unorganized medical files are processed and organized to extract the key
                            factors from them.
                        </p>
                    </div>
                </div>
                <div class="col-md-6 col-lg-6">
                    <div class="features-item h-100 justify-content-start">
                        <div
                            class="icons mb-4 mx-auto mx-lg-0 sec-primary-ic d-flex align-items-center justify-content-center rounded-pill">
                            <img src={icon2} class="img-fluid" alt="Prospect Database Governance" />
                        </div>
                        <h4>Strategy</h4>
                        <p class="mt-3">
                            We wanted to have a system in place where the clients could easily upload the case and their
                            files. The uploaded files go through various processes and are downloaded and transferred by
                            the admin to the production team. The production team passes the information through various
                            phases such as assignment, extraction, coding, sorting, Q.C check, and delivery. The final
                            file that is prepared for delivery is transferred to the Admin and the admin transfers the
                            files to the customer.
                        </p>
                    </div>
                </div>

            </div>
        </div>
    </section>
    <section class=" text-center text-lg-start section-padding">
        <div class="container custom-cn-width">
            <div class="row g-lg-0 g-4 justify-content-center">
                <div class="col-lg-6 col-md-9 bg-dark b-r-t-b">
                    <div class="p-lg-5 p-4">
                        <h1 class="fw-light text-white">Results</h1>
                        <p class="mt-3 mb-3 text-white">The client had various analytical options to search, view, and
                            download files of different cases. They also benefited from the advanced email functionality
                            of the system which sends emails to all the entities throughout the process of production
                        </p>

                    </div>
                </div>
                <div class="col-lg-6 col-md-9 bg-warning b-r-r-b">
                    <div class="p-lg-5 p-4">
                        <h1 class="fw-light  mb-3">Conclusion</h1>

                        <p class="mt-3 ">The client was able to organize all their documents and files in one
                            place and had various options to view and download reports as per their preference.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="pb-0">
        <div class="container custom-cn-width">
            <div class="row g-5 align-items-center justify-content-center text-center">
                <div class="col-lg-7">
                    <h1 class="fw-light mb-4">Interested in working with us?</h1>
                    <p class="mb-3">
                        CAP Digisoft would be more than happy to support and help you
                        derive information and knowledge with our AI engine. Our experts
                        combine experience with technology to make it easy for you to
                        convert your raw documents into informational records.
                    </p>
                    <p>
                        Send us an email at{" "}
                        <a className="text-primary" href="mailto:info@capdigisoft.com">
                            info@capdigisoft.com{" "}
                        </a>{" "}
                        and we’ll be happy to help you.
                    </p>
                    <a href="javascript:;" class="default-btn mt-lg-4 mt-3" data-bs-toggle="modal"
                        data-bs-target="#meeting">
                        Let's Talk
                    </a>
                </div>
            </div>
        </div>
    </section>
    <section class="pb-0 section-padding">
        <div class="container">
            <div class="row align-items-center justify-content-center">
                <div class="col-lg-12">
                    <hr class="my-0" style={{ opacity: ".1" }} />
                </div>
            </div>
        </div>
    </section>
    <section class="other-sec section-padding">
        <div class="container custom-cn-width">
            <div class="row g-lg-5 g-4 text-center">
                <div class="col-lg-12 mb-lg-5 mb-4">
                    <h1 class="fw-light">Other Case Studies</h1>
                </div>
            </div>
            <div class="">
                <div class="row g-4 g-xl-5 text-center text-xl-start">
                    <div class="col-lg-4 col-md-6">
                        <fieldset class="transition">
                            <legend class="text-xl-start">Case Studies</legend>
                            <div class="d-flex flex-column justify-content-between h-100">
                                <h4 class="transition mb-2 mb-lg-3">Panera Bread</h4>
                                <Link class="d-inline-block underline-border" to="/panera-bread"><span
                                        class="border-line d-inline-block me-1">Learn more</span><i
                                        class="fa fa-arrow-right fa-sm position-relative"></i></Link>
                            </div>
                        </fieldset>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <fieldset class="transition">
                            <legend class="text-xl-start">Case Studies</legend>
                            <div class="d-flex flex-column justify-content-between h-100">
                                <h4 class="transition mb-2 mb-lg-3">Medico AI – Chrono Sort App (Attesta)</h4>
                                <Link class="d-inline-block underline-border"
                                    to="/medico-ai-chrono-sort-app-attesta-system"><span
                                        class="border-line d-inline-block me-1">Learn more</span><i
                                        class="fa fa-arrow-right fa-sm position-relative"></i></Link>
                            </div>
                        </fieldset>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <fieldset class="transition">
                            <legend class="text-xl-start">Case Studies</legend>
                            <div class="d-flex flex-column justify-content-between h-100">
                                <h4 class="transition mb-2 mb-lg-3">Help Finding My Agent</h4>
                                <Link class="d-inline-block underline-border" to="/helpfindingmyagent"><span
                                        class="border-line d-inline-block me-1">Learn more</span><i
                                        class="fa fa-arrow-right fa-sm position-relative"></i></Link>
                            </div>
                        </fieldset>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <FooterOne />
</div>
);
};

export default LCS;