import React, { useEffect, useState } from 'react';
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

import HeaderOne from "../../../common/header/HeaderOne";
import FooterOne from "../../../common/footer/FooterOne";

// brand logo imports

import c1 from "../../../app/assets/images/brand-logo/c1.jpg";
import c2 from "../../../app/assets/images/brand-logo/c2.jpg";
import c3 from "../../../app/assets/images/brand-logo/c3.jpg";
import c4 from "../../../app/assets/images/brand-logo/c4.jpg";
import c5 from "../../../app/assets/images/brand-logo/c5.jpg";
import c6 from "../../../app/assets/images/brand-logo/c6.jpg";
import c7 from "../../../app/assets/images/brand-logo/c7.jpg";
import c8 from "../../../app/assets/images/brand-logo/c8.jpg";
import c9 from "../../../app/assets/images/brand-logo/c9.jpg";
import c10 from "../../../app/assets/images/brand-logo/c10.jpg";
import c11 from "../../../app/assets/images/brand-logo/c11.jpg";
import c12 from "../../../app/assets/images/brand-logo/c12.jpg";
import c13 from "../../../app/assets/images/brand-logo/c13.jpg";
import c14 from "../../../app/assets/images/brand-logo/c14.jpg";
import c15 from "../../../app/assets/images/brand-logo/c15.jpg";
import c16 from "../../../app/assets/images/brand-logo/c16.jpg";
import c17 from "../../../app/assets/images/brand-logo/c17.jpg";
import c19 from "../../../app/assets/images/brand-logo/c19.jpg";
import c20 from "../../../app/assets/images/brand-logo/c20.jpg";
import c21 from "../../../app/assets/images/brand-logo/c21.png";
import c22 from "../../../app/assets/images/brand-logo/c22.png";
import c23 from "../../../app/assets/images/brand-logo/c23.png";
import c24 from "../../../app/assets/images/brand-logo/c24.png";
import c25 from "../../../app/assets/images/brand-logo/c25.png";
import c26 from "../../../app/assets/images/brand-logo/c26.png";
import c27 from "../../../app/assets/images/brand-logo/c27.png";
import c28 from "../../../app/assets/images/brand-logo/c28.png";
import c29 from "../../../app/assets/images/brand-logo/c29.png";
import c30 from "../../../app/assets/images/brand-logo/c30.png";
import c31 from "../../../app/assets/images/brand-logo/c31.png";

// Cutout imports

import cutout1 from "../../../app/assets/images/staff-solutions/cutout1.png";
import cutout2 from "../../../app/assets/images/staff-solutions/cutout2.png";
import cutout3 from "../../../app/assets/images/staff-solutions/cutout3.jpg";
import cutout5 from "../../../app/assets/images/staff-solutions/cutout5.jpg";
import clipper from "../../../app/assets/images/staff-solutions/clipper.jpg";

// icons import

import icon1 from "../../../app/assets/images/staff-solutions/icon1.png";
import icon2 from "../../../app/assets/images/staff-solutions/icon2.png";
import icon3 from "../../../app/assets/images/staff-solutions/icon3.png";
import icon4 from "../../../app/assets/images/staff-solutions/icon4.png";
import icon5 from "../../../app/assets/images/staff-solutions/icon5.png";

import Meta from "../../Meta/Staffing-support-service";

const Staffing = () => {

/*Scrol slider starts */
const [scrollPercentage, setScrollPercentage] = useState(0);
const [activeTab, setActiveTab] = useState('intro');

useEffect(() => {
  document.title = 'Staffing Solutions | CAP Digisoft ';
}, []);
useEffect(() => {
function handleScroll() {
const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
const scrollHeight = document.documentElement.scrollHeight;
const windowHeight = window.innerHeight;
const newScrollPercentage = Math.floor((scrollTop / (scrollHeight - windowHeight)) * 100);
setScrollPercentage(newScrollPercentage);
}

document.addEventListener('scroll', handleScroll);

return () => {
document.removeEventListener('scroll', handleScroll);
};
}, []);

useEffect(() => {
const container = document.getElementById('container');

if (container) {
function handleTabChange() {
const sections = container.querySelectorAll('.page-section');
const sectionTops = Array.from(sections).map(section => ({
id: section.id,
top: section.getBoundingClientRect().top,
}));
const currentSection = sectionTops.find(section => section.top >= 0);
if (currentSection) {
setActiveTab(currentSection.id);
}
}

container.addEventListener('scroll', handleTabChange);

return () => {
container.removeEventListener('scroll', handleTabChange);
};
}
}, [activeTab]);

const handleTabClick = (id) => {
const section = document.getElementById(id);
section.scrollIntoView({ behavior: 'smooth' });
};

/*Scrol slider ends */

const properties = {
prevArrow: (
<button className="cs-btn btn-left">
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left"
    viewBox="0 0 16 16">
    <path fill-rule="evenodd"
      d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8" />
  </svg>
</button>
),
nextArrow: (
<button className="cs-btn btn-right">
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right"
    viewBox="0 0 16 16">
    <path fill-rule="evenodd"
      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8" />
  </svg>
</button>
),
};
return (

<div className="staffsolution-page">
  <HeaderOne />
  <Meta/>
  <section class="d-flex align-items-center justify-content-center banner-sec sss-sec bg-img margin-minus">
    <div class="container custom-cn-width position-relative z-index-1">
      <div class="row g-4">
        <div class="col-xxl-6 col-xl-6 col-lg-6 p-0 m-0 ms-md-4 ms-xl-0 ps-md-5 px-xl-0 col-md-7">
          <h5 class="pb-2 text-white">Elevate Your Recruitment Game with our </h5>
          <h2 class="pb-2 text-uppercase text-white">Streamlined Staffing Solution</h2>
          <p class="text-white">We specialize in Recruitment Process Outsourcing (RPO) and aim to make the recruitment
            process simpler and more efficient for businesses of all sizes, from startups to Fortune 500 companies.</p>
        </div>
      </div>
    </div>
  </section>
  <section class="text-center text-lg-start section-padding">
    <div class="container custom-cn-width">
      <div class="row g-4 justify-content-center align-items-center">
        <div class="col-lg-6 col-md-8 order-lg-2">
          <div class="design-block ms-0 sec-title b-r-6 text-start">
            <h2 class="mb-3">Why choose us?</h2>
            <p class="pe-xl-5">We specialize in Staffing Support Services and aim to make the recruitment process
              simpler and more efficient for businesses of all sizes, from startups to Fortune 500 companies, and in
              industries such as:</p>
            <ul class="mt-3 list-styled ps-4">
              <li className='font-size-17'>Medical and Physician Recruitment</li>
              <li className='font-size-17'>Logistics and Commercial Driver Recruitment</li>
              <li className='font-size-17'>Oil &amp; Gas Recruitment</li>
              <li className='font-size-17'>Legal Staffing and Recruitment</li>

            </ul>

          </div>
        </div>
        <div class="col-lg-6 col-md-8 order-lg-1">
          <img src={cutout1} class="img-fluid b-r-6" alt="A full-suite of KPO services" />
        </div>
      </div>
    </div>
  </section>
  <section class="bg-light text-start section-padding">
    <div class="container custom-cn-width">
      <div class="row justify-content-center align-items-center mb-lg-4 g-4">

        <div class="col-lg-6 col-md-12">
          <h1 class="fw-light">Our Unique Value Proposition</h1>
          <p class="">Why settle for traditional recruitment methods when you can harness the power of modern,
            omni-channel solutions?</p>

          <div class="mt-3">
            <div class="mb-3 mt-4 mt-xl-0">
              <p><span class="fw-bold">Expert Recruitment Agents: </span> Our team of experienced recruiters offers a
                cost-effective and highly efficient service.</p>
            </div>
            <div class="mb-3 mt-4 mt-xl-0">
              <p><span class="fw-bold">Omni-Channel Outreach: </span> We utilize a range of marketing channels - from
                LinkedIn Outreach to email campaigns - to engage potential candidates wherever they are.</p>
            </div>
            <div class="mb-3 mt-4 mt-xl-0">
              <p><span class="fw-bold">Tailored Recruitment Solutions: </span> Every business is unique, and so are your
                recruitment needs. That's why we offer tailored solutions that allow you to assign as many or as few
                recruitment processes as you need. Whether you require full-cycle recruitment or just need help with
                specific stages, we adapt our services to fit your objectives.</p>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-8 text-center">
          <img src={cutout2} class="img-fluid b-r-6" alt="A full-suite of KPO services" />

        </div>
      </div>
    </div>
  </section>
  <section className="section-padding">
    <div class="container custom-cn-width">
      <div class="row g-lg-5 g-4 justify-content-center text-center">
        <div class="col-xxl-7 col-xl-8 col-lg-9 mb-xl-5">
          <h1 class="fw-light">Experience the distinct advantages of our recruitment process outsourcing services.</h1>
        </div>
      </div>
      <div class="row g-lg-5 g-4 justify-content-center text-center text-xl-start mw-500">
        <div class="col-xl-4 col-lg-9 order-xl-2">
          <div class="row g-lg-5 g-4 justify-content-center">
            <div class="col-12 d-xl-flex align-items-start">
              <div class="me-xl-4">
                <div
                  class="icons sec-primary-ic mb-4 d-flex align-items-center justify-content-center mx-auto rounded-pill">
                  <img src={icon1} class="img-fluid" alt="Pre-Development Research" /></div>
              </div>
              <div>
                <h4>Fast Hiring</h4>
                <p class="mt-3">Accelerate your time-to-hire by leveraging our streamlined recruitment processes.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-lg-9 d-xl-flex align-items-start order-xl-3">
          <div class="me-xl-4">
            <div
              class="icons mb-4 d-flex align-items-center justify-content-center mx-auto sec-primary-ic rounded-pill">
              <img src={icon2} class="img-fluid" alt="Pre-Development Research" /></div>
          </div>
          <div>
            <h4>Specialized Hiring</h4>
            <p class="mt-3">Our recruiters are trained in specialized hiring practices, ensuring that you get the most
              qualified candidates for niche roles.</p>
          </div>
        </div>
        <div class="col-xl-4 col-lg-9 order-xl-4">
          <div class="row g-lg-5 g-4 justify-content-center">
            <div class="col-12 d-xl-flex align-items-start">
              <div class="me-xl-4">
                <div
                  class="icons sec-primary-ic mb-4 d-flex align-items-center justify-content-center mx-auto rounded-pill">
                  <img src={icon3} class="img-fluid" alt="Pre-Development Research" />
                </div>
              </div>
              <div>
                <h4>Reduced Team Workload</h4>
                <p class="mt-3">By outsourcing your recruitment to us, your in-house team can focus on their core
                  functions, increasing productivity and reducing burnout.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-lg-9 d-xl-flex align-items-start order-xl-5">
          <div class="me-xl-4">
            <div
              class="icons mb-4 d-flex align-items-center justify-content-center mx-auto sec-primary-ic rounded-pill">
              <img src={icon4} class="img-fluid" alt="Pre-Development Research" /></div>
          </div>
          <div>
            <h4>Flexibility</h4>
            <p class="mt-3">Our tailored solutions give you the flexibility to assign as many or as few recruitment
              processes as you need.</p>
          </div>
        </div>
        <div class="col-xl-4 col-lg-9 d-xl-flex align-items-start order-xl-6">
          <div class="me-xl-4">
            <div
              class="icons mb-4 d-flex align-items-center justify-content-center mx-auto sec-primary-ic rounded-pill">
              <img src={icon5} class="img-fluid" alt="Pre-Development Research" /></div>
          </div>
          <div>
            <h4>Reduced Risk</h4>
            <p class="mt-3">Our experience and expertise minimize the risks associated with hiring, such as compliance
              issues and candidate dropouts.</p>
          </div>
        </div>
        <div class="col-xl-4 col-lg-9 order-xl-1 text-center">
          <img src={clipper} alt="hiring" class="img-fluid b-r-6" />
        </div>
      </div>
    </div>
  </section>
  <section class="acc-light section-padding pt-sm-0">
    <div class="container">
      <div class="row g-xl-5 g-4 justify-content-center  align-items-center">
        <div class="col-lg-6 col-xl-6 col-md-11">
          <div class="mb-3 pe-lg-5 text-center text-lg-start">
            <h1 class="fw-light">Comprehensive RPO Services</h1>
            <h5 class="mt-3 fw-400">Our Recruitment Process Outsourcing services are all-encompassing. We manage every
              step of the recruitment process for you, freeing up your time to focus on core business activities.</h5>
          </div>
          <h5 class="mb-3">Package Inclusions:</h5>
          <div class="accordion position-relative pt-0" id="design-acc">
            <div class="accordion-item">
              <h2 class="accordion-header" id="h1">
                <button class="accordion-button pointer-none" type="button" data-bs-toggle="collapse"
                  data-bs-target="#d1" aria-expanded="true" aria-controls="d1"><span class="num">1</span><span>Job
                    profiling and description creation</span></button>
              </h2>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="h2">
                <button class="accordion-button collapsed pointer-none" type="button" data-bs-toggle="collapse"
                  data-bs-target="#d2" aria-expanded="false" aria-controls="d2"><span class="num">2</span><span>Targeted
                    advertising campaigns</span></button>
              </h2>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="h3">
                <button class="accordion-button collapsed pointer-none" type="button" data-bs-toggle="collapse"
                  data-bs-target="#d3" aria-expanded="false" aria-controls="d3"><span class="num">3</span><span>CV
                    screening and shortlisting</span></button>
              </h2>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="h4">
                <button class="accordion-button collapsed pointer-none" type="button" data-bs-toggle="collapse"
                  data-bs-target="#d4" aria-expanded="false" aria-controls="d4"><span class="num">4</span><span>Initial
                    interviews and assessments</span></button>
              </h2>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="h5">
                <button class="accordion-button collapsed pointer-none" type="button" data-bs-toggle="collapse"
                  data-bs-target="#d5" aria-expanded="false" aria-controls="d5"><span
                    class="num">5</span><span>Background checks</span></button>
              </h2>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="h6">
                <button class="accordion-button collapsed pointer-none" type="button" data-bs-toggle="collapse"
                  data-bs-target="#d6" aria-expanded="false" aria-controls="d6"><span
                    class="num">6</span><span>Onboarding support</span></button>
              </h2>
            </div>
          </div>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-9 text-center">
          <div class="about-border right zoom">
            <div class="overflow-hidden border-20">
              <img src={cutout3} alt="" class="border-0" />
            </div>
            <div class="about-bg"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="acc-light section-padding pt-sm-0">
    <div class="container">
      <div class="row g-xl-5 g-4 justify-content-center align-items-center">
        <div class="col-xl-6 col-lg-6 col-md-9 text-center  order-2 order-lg-1">
          <div class="about-border right zoom">
            <div class="overflow-hidden border-20">
              <img src={cutout5} alt="" class="border-0" />
            </div>
            <div class="about-bg"></div>
          </div>
        </div>
        <div class="col-lg-6 col-xl-6 col-md-11 order-1 order-lg-2">
          <div class="mb-3 pe-lg-5 text-center text-lg-start">
            <h1 class="fw-light">Omni-Channel Recruitment Marketing</h1>
            <h5 class="mt-3 fw-400">We use a multi-channel approach to reach your ideal candidates.</h5>
          </div>
          <h5 class="mb-3">Our techniques include:</h5>
          <div class="accordion position-relative pt-0" id="design-acc">
            <div class="accordion-item">
              <h2 class="accordion-header" id="h1">
                <button class="accordion-button pointer-none" type="button" data-bs-toggle="collapse"
                  data-bs-target="#d1" aria-expanded="true" aria-controls="d1"><span
                    class="num">1</span><span>SEO-optimized job listings</span></button>
              </h2>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="h2">
                <button class="accordion-button collapsed pointer-none" type="button" data-bs-toggle="collapse"
                  data-bs-target="#d2" aria-expanded="false" aria-controls="d2"><span class="num">2</span><span>LinkedIn
                    Outreach</span></button>
              </h2>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="h3">
                <button class="accordion-button collapsed pointer-none" type="button" data-bs-toggle="collapse"
                  data-bs-target="#d3" aria-expanded="false" aria-controls="d3"><span class="num">3</span><span>Email
                    newsletters</span></button>
              </h2>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="h4">
                <button class="accordion-button collapsed pointer-none" type="button" data-bs-toggle="collapse"
                  data-bs-target="#d4" aria-expanded="false" aria-controls="d4"><span
                    class="num">4</span><span>Pay-per-click (PPC) advertisements</span></button>
              </h2>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="h5">
                <button class="accordion-button collapsed pointer-none" type="button" data-bs-toggle="collapse"
                  data-bs-target="#d5" aria-expanded="false" aria-controls="d5"><span class="num">5</span><span>Webinars
                    and virtual job fairs</span></button>
              </h2>
            </div>
          </div>
        </div>

      </div>
    </div>
  </section>
  <section class="pb-sm-0 section-padding pt-sm-0">
    <div class="container custom-cn-width">
      <div class="row g-xl-5 g-4 align-items-center justify-content-center text-center">
        <div class="col-xxl-7 col-xl-8 col-lg-9">
          <h1 class="fw-light mb-lg-4 mb-3">Discover the CAP Digisoft Difference</h1>
          <p class="px-xl-4">Our accomplished and cost-effective client success specialists provide premium solutions,
            empowering you to optimize value and elevate your competitive edge.</p>
        </div>
      </div>
    </div>
  </section>
  <div class="section-padding text-center text-lg-start">
    <div class="container custom-cn-width">
      <div class="cta-block b-r-6">
        <div class="row g-xl-5 g-4 align-items-center justify-content-center">
          <div class="col-lg-9">
            <h2 class="fw-light text-white">Reach out to us and see how our Client Success as a Service can elevate your customer
              relationships and help build your brand loyalty. </h2>
          </div>
          <div class="col-lg-3 text-lg-end">
            <a href="javascript:;" class="default-btn solution_class_popu" data-bs-toggle="modal"
              data-bs-target="#meeting" form_title="Knowledge Process Outsourcing">Let's Talk</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <section class="text-center client-block section-padding pb-80">
    <div class="container">
      <div class="row g-lg-5 g-4 justify-content-center">
        <div class="col-lg-12 mb-4 mb-lg-5">
          <h1 class="px-lg-4 ">
            Trusted by more than{" "}
            <span class="text-theme fw-bold text-grad">3000+</span> clients
            globally
          </h1>
        </div>
      </div>
      <Slide className="brand-slider" {...properties} slidesToScroll={1} slidesToShow={4} indicators={false}
        autoplay={true}>
        <div class="each-slide-effect mx-3">
          <img src={c1} alt="Logo" class="img-fluid mb-1" />
        </div>
        <div class="each-slide-effect mx-3">
          <img src={c2} alt="Logo" class="img-fluid mb-1" />
        </div>
        <div class="each-slide-effect mx-3">
          <img src={c3} alt="Logo" class="img-fluid mb-1" />
        </div>
        <div class="each-slide-effect mx-3">
          <img src={c4} alt="Logo" class="img-fluid mb-1" />
        </div>
        <div class="each-slide-effect mx-3">
          <img src={c5} alt="Logo" class="img-fluid mb-1" />
        </div>
        <div class="each-slide-effect mx-3">
          <img src={c6} alt="Logo" class="img-fluid mb-1" />
        </div>
        <div class="each-slide-effect mx-3">
          <img src={c7} alt="Logo" class="img-fluid mb-1" />
        </div>
        <div class="each-slide-effect mx-3">
          <img src={c8} alt="Logo" class="img-fluid mb-1" />
        </div>
        <div class="each-slide-effect mx-3">
          <img src={c9} alt="Logo" class="img-fluid mb-1" />
        </div>
        <div class="each-slide-effect mx-3">
          <img src={c10} alt="Logo" class="img-fluid mb-1" />
        </div>
        <div class="each-slide-effect mx-3">
          <img src={c11} alt="Logo" class="img-fluid mb-1" />
        </div>
        <div class="each-slide-effect mx-3">
          <img src={c12} alt="Logo" class="img-fluid mb-1" />
        </div>
        <div class="each-slide-effect mx-3">
          <img src={c13} alt="Logo" class="img-fluid mb-1" />
        </div>
        <div class="each-slide-effect mx-3">
          <img src={c14} alt="Logo" class="img-fluid mb-1" />
        </div>
        <div class="each-slide-effect mx-3">
          <img src={c15} alt="Logo" class="img-fluid mb-1" />
        </div>
        <div class="each-slide-effect mx-3">
          <img src={c16} alt="Logo" class="img-fluid mb-1" />
        </div>
        <div class="each-slide-effect mx-3">
          <img src={c17} alt="Logo" class="img-fluid mb-1" />
        </div>

        <div class="each-slide-effect mx-3">
          <img src={c19} alt="Logo" class="img-fluid mb-1" />
        </div>
        <div class="each-slide-effect mx-3">
          <img src={c20} alt="Logo" class="img-fluid mb-1" />
        </div>
        <div class="each-slide-effect mx-3">
          <img src={c21} alt="Logo" class="img-fluid mb-1" />
        </div>
        <div class="each-slide-effect mx-3">
          <img src={c22} alt="Logo" class="img-fluid mb-1" />
        </div>
        <div class="each-slide-effect mx-3">
          <img src={c23} alt="Logo" class="img-fluid mb-1" />
        </div>
        <div class="each-slide-effect mx-3">
          <img src={c24} alt="Logo" class="img-fluid mb-1" />
        </div>
        <div class="each-slide-effect mx-3">
          <img src={c25} alt="Logo" class="img-fluid mb-1" />
        </div>
        <div class="each-slide-effect mx-3">
          <img src={c26} alt="Logo" class="img-fluid mb-1" />
        </div>
        <div class="each-slide-effect mx-3">
          <img src={c27} alt="Logo" class="img-fluid mb-1" />
        </div>
        <div class="each-slide-effect mx-3">
          <img src={c28} alt="Logo" class="img-fluid mb-1" />
        </div>
        <div class="each-slide-effect mx-3">
          <img src={c29} alt="Logo" class="img-fluid mb-1" />
        </div>
        <div class="each-slide-effect mx-3">
          <img src={c30} alt="Logo" class="img-fluid mb-1" />
        </div>
        <div class="each-slide-effect mx-3">
          <img src={c31} alt="Logo" class="img-fluid mb-1" />
        </div>
      </Slide>
    </div>
  </section>
  <section class="other-sec pt-0 section-padding">
    <div class="container custom-cn-width">
          <h1 class="fw-light mb-2">Other Solutions</h1>
      <div class="">
        <div class="row g-4 g-xl-5 text-center text-xl-start">
          <div class="col-lg-4 col-md-6">
            <fieldset class="transition">
              <legend class="text-xl-start">Software</legend>
              <div class="d-flex flex-column justify-content-between h-100">
                <h4 class="transition mb-2 mb-lg-3">Mobile &amp; Software Development</h4>
                <a class="d-inline-block underline-border" target='_blank' href="https://softwareappdevelopment.capdigisoft.com/"><span
                    class="border-line d-inline-block me-1">Learn more</span><i
                    class="fa fa-arrow-right fa-sm position-relative"></i></a>
              </div>
            </fieldset>
          </div>
          <div class="col-lg-4 col-md-6">
            <fieldset class="transition">
              <legend class="text-xl-start">call center</legend>
              <div class="d-flex flex-column justify-content-between h-100">
                <h4 class="transition mb-2 mb-lg-3">Nearshore Call Center Outsourcing</h4>
                <a class="d-inline-block underline-border" target='_blank' href="https://callcenter.capdigisoft.com/"><span
                    class="border-line d-inline-block me-1">Learn more</span><i
                    class="fa fa-arrow-right fa-sm position-relative"></i></a>
              </div>
            </fieldset>
          </div>
          <div class="col-lg-4 col-md-6">
            <fieldset class="transition">
              <legend class="text-xl-start">Product</legend>
              <div class="d-flex flex-column justify-content-between h-100">
                <h4 class="transition mb-2 mb-lg-3">Idea to Product</h4>
                <a class="d-inline-block underline-border" href="https://ideatoproduct.capdigisoft.com/" target='_blank'><span
                    class="border-line d-inline-block me-1">Learn more</span><i
                    class="fa fa-arrow-right fa-sm position-relative"></i></a>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
    </div>
  </section>

  <FooterOne />
</div>
);
};

export default Staffing;