import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import HeaderLight from "../../../common/header/HeaderLight";
import FooterOne from "../../../common/footer/FooterOne";

import banner from "../../../../src/app/assets/images/casestudy/medico-ai/banner.jpg";

import icon1 from "../../../../src/app/assets/images/casestudy/medico-ai/icon1.png";
import icon2 from "../../../../src/app/assets/images/casestudy/medico-ai/icon2.png";

import PDF from "../../../app/assets/casestudypdf/medico-ai-chrono-sort-app-attesta.pdf";

const MedicoAI = () => {
    
	useEffect(() => {
        document.title = 'Medico AI chrono sort app attesta system | CAP Digisoft ';
      }, []);

return (
<div className="casestudy-logistics-page">
    <HeaderLight />
    <section
        class="d-flex align-items-center justify-content-center banner-sec bg-dark case-study-sec inner margin-minus">
        <div class="container custom-cn-width position-relative">
            <div class="row position-relative g-3 mt-lg-4 align-items-center mb-md-2">
                <div class="col-lg-9">
                    <h5 class="text-white">Case Study</h5>
                    <h1 class="text-white fw-semibold lh-sm">
                        Medico AI – Chrono Sort App (Attesta)
                    </h1>
                </div>
                <div class="col-lg-3 text-lg-end"><a
                        href={PDF}
                        class="default-btn orange-btn" target="_blank"><i class="fa fa-file-pdf me-1"></i>Download
                        PDF</a></div>

            </div>
        </div>
    </section>
    <section class="text-center text-lg-start section-padding">
        <div class="container custom-cn-width">
            <div class="row justify-content-center g-lg-5 g-4 align-items-center">
                <div class="col-lg-6">
                    <h1 class="fw-light mb-lg-4 mb-3">Scenario</h1>
                    <p>A client who runs a manufacturing business approached us to help them automate and convert their
                        raw documents into informational records. They wanted a solution to provide the route for
                        auditors to assess the overall quality of operations.</p>
                </div>
                <div class="col-lg-6">
                    <img src={banner} class="img-fluid b-r-6" alt="" />
                </div>
            </div>
        </div>
    </section>
    <section class="pb-0 text-center text-lg-start section-padding-btm">
        <div class="container custom-cn-width">
            <div class="row g-xl-5 g-4">
                <div class="col-lg-7 mb-4 mb-lg-5">
                    <h1 class="fw-light">CDS Solution Approach</h1>
                </div>
            </div>
            <div class="row g-xl-5 g-4">
                <div class="col-lg-6 ">
                    <div class="features-item h-100 justify-content-start">
                        <div
                            class="icons sec-primary-ic mb-4 mx-auto mx-lg-0 d-flex align-items-center justify-content-center rounded-pill">
                            <img src={icon1} class="img-fluid" alt="Prospect Database Cleansing" />
                        </div>
                        <h4>Approach</h4>
                        <p class="mt-3">
                            Our approach was to make it easy for the client to extract data from their medical files, in
                            the order they wanted, and generate a useful report. We took the client's documents and fed
                            them to our custom-developed AI application built specifically to deal with the decision
                            support in Medical Litigation cases. The system has been developed in such a way that the
                            data entered into the system, based on several criteria and conditions, generates reports
                            for the attorneys to help them fight their case. </p>
                    </div>
                </div>
                <div class="col-lg-6 ">
                    <div class="features-item h-100 justify-content-start">
                        <div
                            class="icons mb-4 mx-auto mx-lg-0 sec-primary-ic d-flex align-items-center justify-content-center rounded-pill">
                            <img src={icon2} class="img-fluid" alt="New Prospect Identification" />
                        </div>
                        <h4>Strategy</h4>
                        <p class="mt-3">
                            The data is entered by the user in several forms, called the “case entry forms”, that
                            include several other forms like BP, Chronic forms, etc. These data are entered over the
                            patient's data and the date of occurrence of the event (incident). Based on that, the system
                            automatically generates a report with multi-color marking to make the report easy to
                            understand and digest. The report generated is available to download in both Excel format
                            and PDF.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="text-center text-lg-start section-padding ">
        <div class="container custom-cn-width">
            <div class="row g-lg-0 g-4 justify-content-center">
                <div class="col-lg-6 col-md-9 bg-dark b-r-t-b">
                    <div class="p-lg-5 p-4">
                        <h1 class="fw-light text-white mb-3">Results</h1>
                        <p class="text-white">The benefits for the client included:
                        </p>
                        <ul class="mb-3 text-white text-start ps-lg-4 ps-2 list-styled">
                            <li className="text-white">
                                The user can customize the flow of details to be generated in the same order.
                            </li>
                            <li className="text-white">
                                The user can request customization with the hosts, after which, any number of records is
                                automated and saved to cater to industry needs.
                            </li>
                            <li className="text-white">
                                A timeline is generated based on the dates and events available in that particular
                                document
                            </li>
                            <li className="text-white" x>
                                These reports automatically generate a glossary and create tags for the user to view
                                similar
                                keywords on different pages.
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-6 col-md-9 bg-warning b-r-r-b">
                    <div class="p-lg-5 p-4">
                        <h1 class="fw-light  mb-3">Conclusion</h1>

                        <p className="">
                            In the end, the client saw the real benefits of our AI system that understands the scanned
                            report by reading through the lines and identifying its relevance irrespective of direct
                            specification. This AI system can take any document format to create a relation and generate
                            output.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="pb-0">
        <div class="container custom-cn-width">
            <div class="row g-5 align-items-center justify-content-center text-center">
                <div class="col-lg-7">
                    <h1 class="fw-light mb-4">Interested in working with us?</h1>
                    <p class="mb-3">
                        CAP Digisoft would be more than happy to support and help you
                        derive information and knowledge with our AI engine. Our experts
                        combine experience with technology to make it easy for you to
                        convert your raw documents into informational records.
                    </p>
                    <p>
                        Send us an email at{" "}
                        <a className="text-primary" href="mailto:info@capdigisoft.com">
                            info@capdigisoft.com{" "}
                        </a>{" "}
                        and we’ll be happy to help you.
                    </p>
                    <a href="javascript:;" class="default-btn mt-lg-4 mt-3" data-bs-toggle="modal"
                        data-bs-target="#meeting">
                        Let's Talk
                    </a>
                </div>
            </div>
        </div>
    </section>
    <section class="pb-0 section-padding">
        <div class="container custom-cn-width">
            <div class="row align-items-center justify-content-center">
                <div class="col-lg-12">
                    <hr class="my-0" style={{ opacity: ".1" }} />
                </div>
            </div>
        </div>
    </section>
    <section class="other-sec section-padding">
        <div class="container custom-cn-width">
            <div class="row g-5 text-center">
                <div class="col-lg-12 mb-lg-5 mb-4">
                    <h1 class="fw-light">Other Case Studies</h1>
                </div>
            </div>
            <div class="">
                <div class="row g-4 g-xl-5 text-center text-xl-start">
                    <div class="col-lg-4 col-md-6">
                        <fieldset class="transition">
                            <legend class="text-xl-start">Case Studies</legend>
                            <div class="d-flex flex-column justify-content-between h-100">
                                <h4 class="transition mb-2 mb-lg-3">Medico- View Desk </h4>
                                <Link class="d-inline-block underline-border" to="/medico-view-desk-system"><span
                                        class="border-line d-inline-block me-1">Learn more</span><i
                                        class="fa fa-arrow-right fa-sm position-relative"></i></Link>
                            </div>
                        </fieldset>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <fieldset class="transition">
                            <legend class="text-xl-start">Case Studies</legend>
                            <div class="d-flex flex-column justify-content-between h-100">
                                <h4 class="transition mb-2 mb-lg-3">Medico – Risk Chart App</h4>
                                <Link class="d-inline-block underline-border" to="/medico-risk-chart-app-system"><span
                                        class="border-line d-inline-block me-1">Learn more</span><i
                                        class="fa fa-arrow-right fa-sm position-relative"></i></Link>
                            </div>
                        </fieldset>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <fieldset class="transition">
                            <legend class="text-xl-start">Case Studies</legend>
                            <div class="d-flex flex-column justify-content-between h-100">
                                <h4 class="transition mb-2 mb-lg-3">Liti-code System</h4>
                                <Link class="d-inline-block underline-border" to="/liti-code-system"><span
                                        class="border-line d-inline-block me-1">Learn more</span><i
                                        class="fa fa-arrow-right fa-sm position-relative"></i></Link>
                            </div>
                        </fieldset>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <FooterOne />
</div>
);
};

export default MedicoAI;