import React, { useEffect } from 'react';
import { Link } from "react-router-dom";

import HeaderLight from '../../../common/header/HeaderLight';
import FooterOne from '../../../common/footer/FooterOne';

import banner from '../../../../src/app/assets/images/casestudy/j&p/banner.png'
import mini from '../../../../src/app/assets/images/casestudy/tech/mini-equ.png'
import cutout1 from '../../../../src/app/assets/images/casestudy/j&p/cutout1.png'
import cutout2 from '../../../../src/app/assets/images/casestudy/j&p/cutout2.png'
import cutout3 from '../../../../src/app/assets/images/casestudy/j&p/cutout3.png'

import tech1 from '../../../../src/app/assets/images/casestudy/tech/figma.png'


import case1 from '../../../../src/app/assets/images/casestudy/othercase/fura.png'
import case2 from '../../../../src/app/assets/images/casestudy/othercase/hireharmony.png'
import case3 from '../../../../src/app/assets/images/casestudy/othercase/intellicue.png'

const JP = () => {
    
	useEffect(() => {
        document.title = 'J&P Services+ | CAP Digisoft ';
      }, []);

return (
<div className="casestudy-iledger-page">
    <HeaderLight />
    <div class="container-fluid hero-banner pt-0 px-0 margin-minus">
        <div class="masthead tracky pt-lg-5">
            <h2 class="text-white text-center pt-5">J&P services+</h2>
            <p class="text-white text-center pb-4 pt-3">Allows guests in J&P-partnered hotels to check in, request
                services.
            </p>
            <div class="img text-center">
                <img alt="" class="img-fluid rounded-3 pb-5" src={banner} />
            </div>
        </div>
    </div>
    <div class="about py-5">
        <div class="container custom-cn-width">
            <div class="row">
                <div class="col-lg-3 col-md-4 col-sm-12 ">
                    <div class="heading">
                        <div class="content1 d-flex align-items-center">
                            <img src={mini} class="img-fluid" alt="" />
                            <p class="fs-3 ">What is J&P services+?</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-9 col-md-8 col-sm-12">
                    <p>
                    Through the app, guests have the convenience of checking in, requesting various hotel services, and even managing their accommodation details. The functionality extends to room customization, allowing guests to change their rooms based on preferences. </p>
                </div>
            </div>
        </div>

    </div>
    <div class="picture">
        <div class="container custom-cn-width">
            <div class="row justify-content-center">
                <div class="col-lg-12">
                    <div class="picture-img">
                        <img class="tracky-mockup img-fluid" src={cutout1} alt="" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="position-relative py-5">
        <div class="container custom-cn-width">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="heading">
                        <div class="content1 d-flex align-items-center">
                            <img src={mini} class="img-fluid" alt="" />
                            <p class="fs-3 ">Scenario</p>
                        </div>
                    </div>
                    <p class="p-4">
                    In the hospitality industry, traditional check-in processes and service requests often lead to inefficiencies, long queues, and potential delays in meeting guest expectations. The need for a modern, guest-centric solution has become imperative to provide a more convenient and personalized hotel experience..</p>
                    <div class="heading">
                        <div class="content1 d-flex align-items-center">
                            <img src={mini} class="img-fluid" alt="" />
                            <p class="fs-3 ">CDS Solution Approach</p>
                        </div>
                    </div>
                    <p class="p-4">
                    We developed this application, for individuals with dynamic travel plans, tech-savvy preferences, and a desire for a contactless experience. The partnership with J&P adds an extra layer of convenience, allowing guests to access additional services seamlessly.</p>
                </div>
                <div class="col-lg-6">
                    <div class="tracky-middle  d-flex justify-content-center">
                        <img class="rounded-3 py-0 img-fluid" src={cutout2} alt="" />
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div class="picture1">
        <div class="container custom-cn-width">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="picture1-img text-center">
                        <img class="rounded-3 py-0 img-fluid" src={cutout3} alt="" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="impact py-5">
        <div class="container custom-cn-width">
            <div class="row">
                <div class="col-lg-3 col-sm-12 ">
                    <div class="heading">
                        <div class="content1 d-flex align-items-center">
                            <img src={mini} class="img-fluid" alt="" />
                            <p class="fs-3 ">Impact</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-9 col-sm-12  ">
                    <ul class="mb-0 pt-2 ps-4 ps-lg-0 list-styled">
                   
					<li>User Authentication and Profile Management</li>
					<li>Booking and Reservation</li>
					<li>Service Requests</li>
					<li>Room Management</li>
					<li>Restaurant Information</li>
					<li>Integration with J&amp;P Services</li>
					<li>Digital Key and Check-In/Check-Out</li>
					<li>Notifications and Communication</li>
					<li>Localization and Maps</li>
					<li>Security and Privacy</li>

                    </ul>
                </div>
                <div class="col-lg-3 col-sm-12 pb-3 pt-5">
                    <div class="heading">
                        <div class="content1 d-flex align-items-start ">
                            <img src={mini} class="img-fluid" alt="" />
                            <p class="fs-3 ">Technology Stack</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-9 col-sm-12 pt-5">
                    <div class="impact-img4 d-flex align-items-center">
                        <div class="img2 p-2 pb-0 case"><img src={tech1} class="img-fluid" alt="" />
                        </div>
                        
                    </div>

                </div>
                <div class="col-lg-3 col-sm-12  pt-5">
                    <div class="heading">
                        <div class="content1 d-flex align-items-center">
                            <img src={mini} class="img-fluid" alt="" />
                            <p class="fs-3 ">Conclusion</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-9 col-sm-12 pt-lg-5 pt-2">
                    <p class="pt-2">iLedger proved to be a game-changer for Transportation Company, transforming their
                    This application transforms the hotel experience, meeting the diverse needs of modern travelers while optimizing hotel operations for a more efficient and guest-centric approach.

</p>
                </div>

            </div>
        </div>
    </div>
    <div class="more-cases pb-5">
        <div class="container custom-cn-width">
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="heading">
                        <div class="content1 d-flex align-items-center">
                            <img src={mini} class="img-fluid" alt="" />
                            <p class="fs-3 ">Other Case Studies</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row text-center py-4 g-4">
                <div class="col-lg-4 col-sm-12">
                    <Link to="/fura">
                        <div class="case rounded-3  h-100 case1 p-4 bg-light">
                            <div class="case-title">
                                <h4 class="">Fura</h4>
                            </div>
                            <p class=" pt-2">  AI powered speciality pharmacy prescription fulfillment booster. </p>
                            <div class="case-img"><img class="img-fluid rounded py-3" src={case1} alt="" />
                            </div>

                        </div>
                    </Link>
                </div>
                <div class="col-lg-4 col-sm-12 ">
                    <Link to="/hireharmony">
                        <div class="case rounded-3  h-100 case1 p-4 bg-light">
                            <div class="case-title">
                                <h4 class="">Hire Harmony</h4>
                            </div>
                            <p class=" pt-2">Hire Harmony is a web application based on hiring process of an
                                organization.</p>
                            <div class="case-img"><img class="img-fluid rounded py-3" src={case2} alt="" />
                            </div>
                        </div>
                    </Link>
                </div>
                <div class="col-lg-4 col-sm-12">
                    <Link to="/intellicue">
                        <div class="case rounded-3  h-100 case1 p-4 bg-light">
                            <div class="case-title">
                                <h4 class="">Intellicue</h4>
                            </div>
                            <p class=" pt-2">Online learning platform with mini interactive games based on the
                                topics</p>
                            <div class="case-img"><img class="img-fluid rounded py-3" src={case3} alt="" />
                            </div>

                        </div>
                    </Link>
                </div>
            </div>
        </div>
    </div>

    <FooterOne />
</div>
)
}

export default JP;