// Group 1 case studies
import case1 from "../../../app/assets/images/casestudy/1.png";
import case2 from "../../../app/assets/images/casestudy/2.png";
import case3 from "../../../app/assets/images/casestudy/3.png";
import case4 from "../../../app/assets/images/casestudy/4.png";
import case5 from "../../../app/assets/images/casestudy/5.png";
import case6 from "../../../app/assets/images/casestudy/6.png";
import case29 from "../../../app/assets/images/casestudy/carfidantea.png";
import case30 from "../../../app/assets/images/casestudy/aiya.png";

// Group 2 case studies
import case7 from "../../../app/assets/images/casestudy/7.png";
import case8 from "../../../app/assets/images/casestudy/8.png";
import case9 from "../../../app/assets/images/casestudy/9.png";
import case10 from "../../../app/assets/images/casestudy/10.png";
import case11 from "../../../app/assets/images/casestudy/11.png";
import case12 from "../../../app/assets/images/casestudy/12.png";
import case13 from "../../../app/assets/images/casestudy/13.png";
import case14 from "../../../app/assets/images/casestudy/14.png";
import case15 from "../../../app/assets/images/casestudy/15.png";
import case16 from "../../../app/assets/images/casestudy/16.png";
import case17 from "../../../app/assets/images/casestudy/17.png";
import case18 from "../../../app/assets/images/casestudy/18.png";
import case19 from "../../../app/assets/images/casestudy/19.png";
import case20 from "../../../app/assets/images/casestudy/20.png";
import case21 from "../../../app/assets/images/casestudy/21.png";
import case22 from "../../../app/assets/images/casestudy/22.png";
import case23 from "../../../app/assets/images/casestudy/23.png";
import case24 from "../../../app/assets/images/casestudy/24.png";
import case25 from "../../../app/assets/images/casestudy/25.png";
import case26 from "../../../app/assets/images/casestudy/26.png";
import case27 from "../../../app/assets/images/casestudy/27.png";
import case28 from "../../../app/assets/images/casestudy/28.png";
import case31 from "../../../app/assets/images/casestudy/rcm.png";
import case32 from "../../../app/assets/images/casestudy/steppa.png";
import case33 from "../../../app/assets/images/casestudy/smart-swatcher.png";

// Group 1 case studies array
const row1Details = [
  { 
    image: case1, 
    title: "Fura", 
    path: "/fura",
    subtext: "AI-powered specialty pharmacy tool", 
},
  { 
    image: case2, 
    title: "iLedgers", 
    path: "/iledgers" ,
    subtext: "Truck fault inspections ensure fleet safety", 
},
  { 
    image: case3, 
    title: "Genie", 
    path: "/genie",
    subtext: "Efficient teacher management tool", 
 },
  { 
    image: case4, 
    title: "Practica", 
    path: "/practica",
    subtext: "All-in-one school management app",  
},
  {
    image: case5,
    title: "Merger and Acquisition Firm",
    path: "/mergers-and-acquisition-firm",
    subtext: "Identifying and filling strategy gaps for success.", 
  },
  { 
    image: case6, 
    title: "Logistics Company", 
    path: "/logistics-company" ,
    subtext: "Logistics sales strategy gaps addressed effectively.", 
},
  { 
    image: case29, 
    title: "Carfidante", 
    path: "/carfidante" ,
    subtext: "Revolutionizing pre-owned car buying", 
},
];

// Group 2 case studies array
const row2Details = [
  { 
    image: case7, 
    title: "Attesta AI", 
    path: "/attesta_ai",
    subtext: "Automated summaries and reports",  
},
  { 
    image: case8, 
    title: "Hire Harmony", 
    path: "/hireharmony",
    subtext: "Streamlining and optimizing hiring processes", 
 },
  {
    image: case9,
    title: "Workflow Management System",
    path: "/workflow-management-system",
    subtext: "Client seeks efficient order and tracking tool", 
  },
  {
    image: case10,
    title: "Medico - View Desk",
    path: "/medico-view-desk-system",
    subtext: "Client seeks solution for online lab records storage", 
  },
  {
    image: case11,
    title: "Narrative Medical Record Summaries",
    path: "/narrative-medical-record-summaries",
    subtext: "Law firm struggles with medical records management.", 
  },
  { 
    image: case30, 
    title: "Aiya", 
    path: "/aiya" ,
    subtext: "Advanced AI knowledge assistant", 
},
];
// Group 3 case studies
const row3Details = [
  {
    image: case12,
    path: "/frontera",
    title: "Frontera",
    subtext: "Frontera's sales transformed by SDR services", 
  },
  {
    image: case13,
    path: "/j&p_services+",
    title: "J&P",
    subtext: "App streamlines guest hotel experience", 
  },
  {
    image: case14,
    path: "/tracky",
    title: "Tracky",
    subtext: "Productive time sheet management", 
  },
  {
    image: case15,
    path: "/skoolers",
    title: "Skoolers",
    subtext: "Streamlined school management application", 
  },
  {
    image: case16,
    path: "/panera-bread",
    title: "Panera Bread",
    subtext: "Uncovering sales blind spots, boosting success", 
  },
  {
    image: case17,
    path: "/liti-code-system",
    title: "Liti-code System",
    subtext: "Automate medical case workflow for attorneys", 
  },
  {
    image: case31,
    path: "/rcm",
    title: "RCM",
    subtext: "Gen AI optimizing revenue management", 
  },
];

// Group 4 case studies
const row4Details = [
  {
    image: case18,
    path: "/ai-driven-optimization-of-prescription-fulfillment",
    title: "AI-Driven Optimization of Prescription Fulfillment",
    subtext: "AI-powered specialty pharmacy tool", 
  },
  {
    image: case19,
    path: "/intellicue",
    title: "Intellicue",
    subtext: "Mental health learning platform", 
  },
  {
    image: case20,
    path: "/medico-risk-chart-app-system",
    title: "Medico – Risk Chart App",
    subtext: "Enhance attorney decision support for medical-legal cases", 
  },
  {
    image: case21,
    path: "/medico-ai-chrono-sort-app-attesta-system",
    title: "Medico AI – Chrono Sort App (Attesta)",
    subtext: "Automate manufacturing document conversion for auditing", 
  },
  {
    image: case22,
    path: "/e-sort-desk-system",
    title: "E-Sort Desk",
    subtext: "Streamline medical record management efficiently", 
  },
  {
    image: case32,
    path: "/steppa",
    title: "Steppa",
    subtext: "AI platform for real-time insights", 
  },
];

// Group 5 case studies
const row5Details = [
  {
    image: case23,
    path: "/clued-in-closet",
    title: "Clued.in Closet",
    subtext: "Closet organizers and outfit planner", 
  },
  {
    image: case24,
    path: "/bussopi",
    title: "Bussopi",
    subtext: "Advanced identity validation app", 
  },
  {
    image: case25,
    path: "/sunbelt",
    title: "Sunbelt",
    subtext: "Market share tracking software", 

  },
  {
    image: case26,
    path: "/reeler",
    title: "Reeler",
    subtext: "Next-gen hook-up app", 
  },
  {
    image: case27,
    path: "/helpfindingmyagent",
    title: "Help Finding My Agent",
    subtext: "Partnering for startup success", 
  },
  {
    image: case28,
    path: "/jugl",
    title: "Jugl",
    subtext: "Integrated workforce communication app", 
  },
  {
    image: case33,
    path: "/smart-swatchers",
    title: "Smart Swatchesrs",
    subtext: "Hair color learning app", 
  },
];

export { row1Details, row2Details, row3Details, row4Details, row5Details };
