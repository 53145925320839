import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import HeaderLight from "../../../common/header/HeaderLight";
import FooterOne from "../../../common/footer/FooterOne";

import banner from "../../../../src/app/assets/images/casestudy/sunbelt/banner.png";
import mini from "../../../../src/app/assets/images/casestudy/tech/mini-equ.png";
import cutout1 from "../../../../src/app/assets/images/casestudy/sunbelt/cutout1.png";
import cutout2 from "../../../../src/app/assets/images/casestudy/sunbelt/cutout2.png";
import cutout3 from "../../../../src/app/assets/images/casestudy/sunbelt/cutout3.png";

import tech1 from "../../../../src/app/assets/images/casestudy/tech/html.png";
import tech2 from "../../../../src/app/assets/images/casestudy/tech/css.png";
import tech3 from "../../../../src/app/assets/images/casestudy/tech/bootstrap.png";
import tech4 from "../../../../src/app/assets/images/casestudy/tech/php.png";
import tech5 from "../../../../src/app/assets/images/casestudy/tech/json.png";
import tech6 from "../../../../src/app/assets/images/casestudy/tech/my-sql.png";
import tech7 from "../../../../src/app/assets/images/casestudy/tech/js.png";
import tech8 from "../../../../src/app/assets/images/casestudy/tech/ajax.png";
import tech9 from "../../../../src/app/assets/images/casestudy/tech/apache.png";
import tech10 from "../../../../src/app/assets/images/casestudy/tech/jquery.png";

import case1 from "../../../../src/app/assets/images/casestudy/othercase/tracky.png";
import case2 from "../../../../src/app/assets/images/casestudy/othercase/reeler.png";
import case3 from "../../../../src/app/assets/images/casestudy/othercase/practica.png";

const Sunbelt = () => {
  useEffect(() => {
    document.title = "Sunbelt | CAP Digisoft ";
  }, []);

  return (
    <div className="casestudy-sunbelt-page">
      <HeaderLight />
      <div class="container-fluid pt-0 px-0 margin-minus">
        <div class="masthead tracky pt-lg-5">
          <h2 class="text-white text-center pt-5">Sunbelt</h2>
          <p class="text-white text-center pb-4 pt-3">
            Performance Tracker & Store Optimizer
          </p>
          <div class="row justify-content-center">
            <div class="img text-center">
              <img alt="" class="img-fluid rounded-3 pb-5" src={banner} />
            </div>
          </div>
        </div>
      </div>
      <div class="about py-5">
        <div class="container custom-cn-width">
          <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-12 ">
              <div class="heading">
                <div class="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">What is Sunbelt?</p>
                </div>
              </div>
            </div>
            <div class="col-lg-8 col-md-8 col-sm-12">
              <p>
                Sunbelt is market share tracker tool is a software that allows
                businesses to monitor, rate and analyze their market share in a
                specific industry or market. Sunbelt is designed to gather data
                from integrated sources, such as market research firms, industry
                reports, and company financial statements, to provide a
                comprehensive view of a business's market position with
                insights.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="picture">
        <div class="container custom-cn-width">
          <div class="row justify-content-center">
            <div class="col-lg-12">
              <div class="picture-img">
                <img
                  class="w-100 tracky-mockup img-fluid"
                  src={cutout1}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="position-relative py-5">
        <div class="container custom-cn-width">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="heading">
                <div class="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Scenario</p>
                </div>
              </div>
              <p class="p-4">
                The risks of manually integrating solutions for multiple
                supplier products can be a challenging process that requires
                careful planning and attention to detail as synchronized entries
                can lead to errors, such as missing or incorrect information.
                Also, tracking the performance of the products by integrating
                multiple systems manually can be labor-intensive and require
                dedicated resources, which can be costly and inefficient.
              </p>
              <div class="heading">
                <div class="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">CDS Solution Approach</p>
                </div>
              </div>
              <p class="p-4">
                We came up with Sunbelt Systems, simple yet powerful management
                information system that can help businesses understand their
                competitive landscape, identify trends and changes in the
                market, and measure the success of their marketing and sales
                efforts. They can also provide insights into customer behavior
                and preferences, which can inform product development and
                pricing strategies.
              </p>
            </div>
            <div class="col-lg-6">
              <div class="tracky-middle  d-flex justify-content-center">
                <img class="rounded-3 py-0 img-fluid" src={cutout2} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="picture1">
        <div class="container custom-cn-width">
          <div class="row">
            <div class="col-lg-12 col-md-12">
              <div class="picture1-img text-center">
                <img class="rounded-3 py-0 img-fluid" src={cutout3} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="impact py-5">
        <div class="container custom-cn-width">
          <div class="row">
            <div class="col-lg-3 col-sm-12 ">
              <div class="heading">
                <div class="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Impact</p>
                </div>
              </div>
            </div>
            <div class="col-lg-9 col-sm-12 py-3">
              <p class="mb-lg-2 mb-4">
                Best tool for Companies to integrate vendor products that can
                Track, Rebate &amp; optimize store management.Businesses can
                Analyse, Track and Execute successful business strategies.
              </p>
              <ul class="mt-top list-styled ps-4">
                <li>User Friendly Dashboard Controls</li>
                <li>
                  Integrate Multiple Supplier products (Product Name,
                  Manufacturer Name &amp; Category)
                </li>
                <li>Set Performance Metrics with Code and Access Number</li>
                <li>Track Unit based Performance </li>
                <li>Multi-Level Product staging from Store </li>
                <li>Views the Performance Report of the Store </li>
                <li>Enter the Rebate calculation values </li>
                <li>Export to the Quick Books </li>
                <li>Analyse Market share </li>
                <li>Make decisions based on insights</li>
              </ul>
            </div>
            <div class="col-lg-3 col-sm-12 pb-3 pt-5">
              <div class="heading">
                <div class="content1 d-flex align-items-start ">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Technology Stack</p>
                </div>
              </div>
            </div>

            <div class="col-lg-9 col-sm-12 pt-5">
              <div class="impact-img4  d-flex align-items-center">
                <div class="img2 p-2 pb-0 case">
                  <img src={tech1} class="img-fluid" alt="" />
                </div>
                <div class="img2 p-2 pb-0 case">
                  <img src={tech2} class="img-fluid" alt="" />
                </div>
                <div class="img2 p-2 pb-0  case">
                  <img src={tech3} class="img-fluid" alt="" />
                </div>
                <div class="img2 p-2 pb-0 case">
                  <img src={tech4} class="img-fluid" alt="" />
                </div>
                <div class="img2 p-2 pb-0 case">
                  <img src={tech5} class="img-fluid" alt="" />
                </div>
              </div>
              <div class="row">
                <div class="impact-img4  d-flex align-items-center">
                  <div class="img2 p-2 pb-0 case">
                    <img src={tech6} class="img-fluid" alt="" />
                  </div>
                  <div class="img2 p-2 pb-0 case">
                    <img src={tech7} class="img-fluid" alt="" />
                  </div>
                  <div class="img2 p-2 pb-0 case">
                    <img src={tech8} class="img-fluid" alt="" />
                  </div>
                  <div class="img2 p-2 pb-0  case">
                    <img src={tech9} class="img-fluid" alt="" />
                  </div>
                  <div class="img2 p-2 pb-0 case">
                    <img src={tech10} class="img-fluid" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-12  pt-5">
              <div class="heading">
                <div class="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Conclusion</p>
                </div>
              </div>
            </div>
            <div class="col-lg-9 col-sm-12 pt-lg-5 pt-2">
              <p class="pt-2">
                Sunbelt Systems is made for businesses of all sizes and
                industries, as they provide a way to track and understand market
                dynamics and make informed business decisions.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="more-cases pb-5">
        <div class="container custom-cn-width">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12">
              <div class="heading">
                <div class="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Other Case Studies</p>
                </div>
              </div>
            </div>
          </div>
          <div class="row text-center py-4 g-4">
            <div class="col-lg-4 col-sm-12">
              <Link to="/tracky">
                <div class="case rounded-3  h-100 case1 p-4 bg-light">
                  <div class="case-title">
                    <h4 class="">Tracky</h4>
                  </div>
                  <p class=" pt-2">
                    {" "}
                    A perfect tool to help you control your workspace{" "}
                  </p>
                  <div class="case-img">
                    <img class="img-fluid rounded py-3" src={case1} alt="" />
                  </div>
                </div>
              </Link>
            </div>
            <div class="col-lg-4 col-sm-12 ">
              <Link to="/reeler">
                <div class="case rounded-3  h-100 case1 p-4 bg-light">
                  <div class="case-title">
                    <h4 class="">Reeler</h4>
                  </div>
                  <p class=" pt-2">The best place to meet-up</p>
                  <div class="case-img">
                    <img class="img-fluid rounded py-3" src={case2} alt="" />
                  </div>
                </div>
              </Link>
            </div>
            <div class="col-lg-4 col-sm-12">
              <Link to="/practica">
                <div class="case rounded-3 h-100 case1 p-4 bg-light">
                  <div class="case-title">
                    <h4 class="">Practica</h4>
                  </div>
                  <p class=" pt-2">School app for learning</p>
                  <div class="case-img">
                    <img class="img-fluid rounded py-3" src={case3} alt="" />
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <FooterOne />
    </div>
  );
};

export default Sunbelt;
