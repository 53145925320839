import React, { useEffect } from 'react';
import { Helmet } from "react-helmet";

const ProductsPage = () => {
  useEffect(() => {
    const handleVisibilityChange = () => {
      document.title = 'Capdigisoft | Innovative Solutions: A Case Study in Problem-Solving'; // Reset title if necessary
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);
  return (
    <>
      <Helmet>
        <title>
          Capdigisoft | Innovative Solutions: A Case Study in Problem-Solving
        </title>
        <meta
          name="description"
          content="Discover how our innovative approach tackled complex challenges, delivering impactful solutions that transformed outcomes for our clients. Dive deep into our case study to see our strategic process in action, driving results through creativity and expertise."
        />
        <meta name="author" content="https://capdigisoft.com" />
        <meta name="robots" content="index, follow" />

        <link
          rel="alternate"
          hreflang="x-default"
          href="https://capdigisoft.com/casestudy"
        />

        <link rel="canonical" href="https://capdigisoft.com" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Capdigisoft|Innovative Solutions: A Case Study in Problem-Solving"
        />
        <meta
          property="og:description"
          content="Discover how our innovative approach tackled complex challenges, delivering impactful solutions that transformed outcomes for our clients. Dive deep into our case study to see our strategic process in action, driving results through creativity and expertise."
        />
        <meta property="og:url" content="https://capdigisoft.com/" />
        <meta property="og:site_name" content="CAP Digisoft" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/capdigisoftsolutions/"
        />
        <meta
          property="article:modified_time"
          content="2021-08-23T14:13:42+00:00"
        />
        <meta
          property="og:image"
          content="https://capdigisoft.com/static/media/logo-light.7ce76383d4689c9800e7.png"
        />

        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Capdigisoft|Innovative Solutions: A Case Study in Problem-Solving"
        />
        <meta
          name="twitter:description"
          content="Discover how our innovative approach tackled complex challenges,delivering impactful solutions that transformed outcomes for our clients. Dive deep into our case study to see our strategic process in action, driving results through creativity and expertise."
        />
        <meta
          name="twitter:image"
          content="https://capdigisoft.com/static/media/logo-light.7ce76383d4689c9800e7.png"
        />
        <meta name="twitter:site" content="@CAPDigisoft" />

        <script type="application/ld+json">
          {`{
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "capdigisoft",
            "url": "https://capdigisoft.com/",
            "logo": "https://capdigisoft.com/static/media/logo-light.7ce76383d4689c9800e7.png",
            "sameAs": [
                "https://www.facebook.com/capdigisoftsolutions/",
                "https://x.com/CAPDigisoft",
                "@capdigisoftsolutions",
                "https://www.youtube.com/channel/UCGgLVDZx1q4I4F99IEx9Apw",
                "https://www.linkedin.com/company/cap-digisoft-solutions-inc"
            ]
            }`}
        </script>

        <script type="application/ld+json">
          {`{
                "@context": "http://schema.org/",
                "@type": "BreadcrumbList",
                "itemListElement": [
                    {
                        "@type": "ListItem",
                        "position": 1,
                        "name": "Capdigisoft",
                        "item": "https://capdigisoft.com"
                    },
                    {
                        "@type": "ListItem",
                        "position": 2,
                        "name": "casestudy",
                        "item": "https://capdigisoft.com/casestudy"
                    }
                ]
            }`}
        </script>
      </Helmet>
    </>
  );
};

export default ProductsPage;
