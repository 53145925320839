/* eslint-disable jsx-a11y/alt-text */
import React, {useEffect} from "react";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

import HeaderDark from "../../../common/header/HeaderDark";
import FooterOne from "../../../common/footer/FooterOne";

import TrustiesSlide from "../../../common/BrandLogo-slider";

// Cutout imports

import cutout1 from "../../../app/assets/images/webdesign-media/cutout1.jpg";
import cutout2 from "../../../app/assets/images/webdesign-media/cutout2.jpg";
import cutout3 from "../../../app/assets/images/webdesign-media/cutout3.jpg";

// icons import

import icon1 from "../../../app/assets/images/webdesign-media/icon1.png";
import icon2 from "../../../app/assets/images/webdesign-media/icon2.png";
import icon3 from "../../../app/assets/images/webdesign-media/icon3.png";

// mockups import

import moc1 from "../../../app/assets/images/webdesign-media/moc1.png";
import moc2 from "../../../app/assets/images/webdesign-media/moc2.png";
import moc4 from "../../../app/assets/images/webdesign-media/moc4.png";
import moc5 from "../../../app/assets/images/webdesign-media/moc5.png";
import moc6 from "../../../app/assets/images/webdesign-media/moc6.png";

// model import

import model1 from "../../../app/assets/images/webdesign-media/model1.png";
import model4 from "../../../app/assets/images/webdesign-media/model4.png";
import model5 from "../../../app/assets/images/webdesign-media/model5.png";
import model6 from "../../../app/assets/images/webdesign-media/model6.png";
import model7 from "../../../app/assets/images/webdesign-media/model7.png";
import model9 from "../../../app/assets/images/webdesign-media/model9.png";
import model10 from "../../../app/assets/images/webdesign-media/model10.png";
import model11 from "../../../app/assets/images/webdesign-media/model11.png";

// technology import

import tech1 from "../../../app/assets/images/webdesign-media/tech1.png";
import tech2 from "../../../app/assets/images/webdesign-media/tech2.png";
import tech3 from "../../../app/assets/images/webdesign-media/tech3.png";
import tech4 from "../../../app/assets/images/webdesign-media/tech4.png";
import tech5 from "../../../app/assets/images/webdesign-media/tech5.png";
import tech6 from "../../../app/assets/images/webdesign-media/tech6.png";
import tech7 from "../../../app/assets/images/webdesign-media/tech7.png";
import tech8 from "../../../app/assets/images/webdesign-media/tech8.png";

import Meta from "../../Meta/Comprehensive-webdesign";

const WebSiteDesign = () => {
	useEffect(() => {
	  document.title = 'Comprehensive Website Design | CAP Digisoft ';
	}, []);
const properties = {
prevArrow: (
<button className="cs-btn btn-left">
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left"
    viewBox="0 0 16 16">
    <path fill-rule="evenodd"
      d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8" />
  </svg>
</button>
),
nextArrow: (
<button className="cs-btn btn-right">
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right"
    viewBox="0 0 16 16">
    <path fill-rule="evenodd"
      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8" />
  </svg>
</button>
),
};

const mymodel = {
prevArrow: (
<button className="cs-btn btn-left custom-outline">
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left"
    viewBox="0 0 16 16">
    <path fill-rule="evenodd"
      d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8" />
  </svg>
</button>
),
nextArrow: (
<button className="cs-btn btn-right custom-outline">
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right"
    viewBox="0 0 16 16">
    <path fill-rule="evenodd"
      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8" />
  </svg>
</button>
),
};
return (
<div className="webdesign-page">
  <HeaderDark />
  <Meta/>
  <section class="vh-100 p-0 banner-sec web-sec overflow-hidden margin-minus cp-mrg">
    <div class="container custom-cn-width">
      <div class="row no-gutters">
        <div class="col-12 col-lg-6 bg-blue d-flex justify-content-center align-items-center">
          <div class="container h-75">
            <div class="image-container wb-design-longe pt-lg-4 mt-lg-5">
              <Slide className="brand-slider" {...properties} slidesToScroll={1} slidesToShow={1} autoplay={true}
                arrows={false} indicators={true} scale={1.4}>
                <div class="each-slide-effect">
                  <img src={moc1} alt="Terry Johnson" class="img-fluid" />
                </div>
                <div class="each-slide-effect">
                  <img src={moc2} alt="Terry Johnson" class="img-fluid" />
                </div>
                <div class="each-slide-effect">
                  <img src={moc4} alt="Terry Johnson" class="img-fluid" />
                </div>
                <div class="each-slide-effect">
                  <img src={moc5} alt="Terry Johnson" class="img-fluid" />
                </div>
                <div class="each-slide-effect">
                  <img src={moc6} alt="Terry Johnson" class="img-fluid" />
                </div>
              </Slide>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="row no-gutters text-lg-start text-md-center">
            <div class="col-12 bg-yellow bg-img height-50 d-flex align-items-center  py-5 py-lg-0">
              <div class="container super-container p-0">
                <div class="text-lg-end mt-lg-5">
                  <h3 class="text-white mb-3">
                    Website Design <br />
                    for Today’s Audience{" "}
                  </h3>
                  <p class="text-white para w-450 ms-auto">
                    The role of a company website in the 21<sup>st</sup>{" "}
                    century is no longer about claiming a stake on the
                    internet highway or a mere branding exercise. Today’s
                    websites need to be lean, effective conversion machines
                    that make it easy for your customers and prospects to
                    find the information they’re looking for and make
                    decisive buying decisions.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-12 bg-dk-blue height-50 d-flex align-items-center position-relative py-5 py-lg-0">
              <div class="container">
                <div class="me-auto ms-lg-4">
                  <h3 class="text-white mb-3">
                    Buyer Enablement in Three Clicks{" "}
                  </h3>
                  <p class="text-white para w-450">
                    Our highly skilled, expert global resources have a
                    website design philosophy built around the idea of buyer
                    enablement. We craft a web presence for your business
                    that delivers the latest trends in UI and UX design with
                    tools to make it easy for your audience to find exactly
                    what they’re looking for, all within three short clicks.
                    This means a better experience for your users, and more
                    ROI for you.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="section-padding">
    <div class="container text-center custom-cn-width">
      <div class="row g-xl-5 g-4 justify-content-center text-center">
        <div class="col-lg-12 mb-4 mb-lg-5">
          <h5 class="mb-2 mb-lg-3 fw-400">An Approach to Design </h5>
          <h1 class="fw-light">That’s focused on enablement</h1>
        </div>
      </div>
      <div class="row g-xl-5 g-4 justify-content-center">
        <div class="col-lg-7">
          <div class="design-sec">
            <p class="">CAP Digisoft’s team of expert developers and strategic marketers take an approach to web design
              that not only places a priority on brand communication, but on the philosophy of buyer enablement – the
              idea that it should be as easy as possible for your customers and prospects to find exactly what they’re
              looking for in the shortest path possible.</p>
            <div class="row g-lg-0 g-4 pt-lg-5 pt-4">
              <div class="col-md-6 border-end pe-lg-4">
                <h1 class="fw-medium"><span class="counter">80</span><span>%</span></h1>
                <h6 class="mt-2 fw-400 f-18">of potential buyers say that the buying experience is just as important as
                  the product</h6>
              </div>
              <div class="col-md-6 px-4 border-start border-white">
                <h1 class="fw-medium"><span class="counter">67</span><span>%</span></h1>
                <h6 class="mt-2 fw-400 f-18">of buyers are willing to pay more for a great buying experience</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="bg-light text-center text-lg-start section-padding">
    <div class="container custom-cn-width">
      <div class="row g-xl-5 g-4 justify-content-center">
        <div class="col-lg-12 col-md-8">
          <div class="mb-lg-5 mb-4 text-center">
            <h1 class="fw-light">To enhance that experience on your website.</h1>
            <h5 class="mt-3 fw-400">We consider all aspects of your web presence including:</h5>
          </div>
        </div>
      </div>
      <div class="row g-lg-5 g-4 justify-content-center">
        <div class="col-xl-6 col-lg-7 col-md-9 pe-xl-5">
          <div class="row g-xl-5 g-4 justify-content-center mt-lg-2">
            <div class="col-12 d-lg-flex align-items-start">
              <div class="me-xl-4 me-lg-3">
                <div
                  class="icons sec-primary-ic mb-4 d-flex align-items-center justify-content-center mx-auto rounded-pill">
                  <img src={icon1} class="img-fluid" alt="Website and Branding Strategy" /></div>
              </div>
              <div>
                <h4>Website and Branding Strategy</h4>
                <p class="mt-3">Right from the get go we work with you to develop a complete website strategy, including
                  how to implement your branding. We determine how your site’s content will be mapped, as well as what
                  kind of buyer enablement tools can best help your customers and prospects.</p>
              </div>
            </div>
            <div class="col-12 d-lg-flex align-items-start">
              <div class="me-xl-4 me-lg-3">
                <div
                  class="icons mb-4 d-flex align-items-center justify-content-center mx-auto sec-primary-ic rounded-pill">
                  <img src={icon2} class="img-fluid" alt="Website Maintenance" /></div>
              </div>
              <div>
                <h4>Website Maintenance</h4>
                <p class="mt-3">We can take over the maintenance of your existing website to keep it running in the best
                  shape possible. This includes making sure all the back-end systems are updated to their latest and
                  most secure versions, and that all of your content is up to date. We make sure you’re putting your
                  company’s best foot forward.</p>
              </div>
            </div>
            <div class="col-12 d-lg-flex align-items-start">
              <div class="me-xl-4 me-lg-3">
                <div
                  class="icons mb-4 d-flex align-items-center justify-content-center mx-auto sec-primary-ic rounded-pill">
                  <img src={icon3} class="img-fluid" alt="Auditing your existing content" /></div>
              </div>
              <div>
                <h4>Auditing your existing content</h4>
                <p class="mt-3">We audit your existing content to make sure you’ve got all your buyer enablement bases
                  covered, and that your content is up-to-date and accurately reflects your company’s current offerings.
                  Based on our findings we make recommendations for new tools and content that can make it easier for
                  your prospects to make a buying decision.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-6 col-lg-7 col-md-9">
          <div class="about-border right zoom">
            <div class="overflow-hidden border-white border-20">
              <img src={cutout1} alt="To enhance that experience on your website" class="border-0" />
            </div>
            <div class="about-bg"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="modal-sec section-padding wb-design-longe section-padding">
    <div class="container position-relative z-index-1 custom-cn-width">
      <div class="bg-white">
        <div class="row align-items-center g-lg-5 g-4 justify-content-center text-center">
          <div class="col-lg-8 px-xl-5 col-xxl-7">
            <h1 class="mb-3 mb-lg-4 fw-light">A Highly-Efficient Development Model</h1>
            <p>CAP Digisoft has developed a highly-efficient website development model which means we can work based on
              specified deliverables with a phased development plan that gives us the ability to deliver even complex
              websites with an extremely short turnaround.</p>
          </div>
          <div class="col-lg-9 text-center">
            <Slide className="brand-slider" {...mymodel} slidesToScroll={1} slidesToShow={1} autoplay={false}
              arrows={true} indicators={true} scale={2}>
              <div class="each-slide-effect">
                <img src={model1} alt="Terry Johnson" class="img-fluid" />
              </div>
              <div class="each-slide-effect">
                <img src={model4} alt="Terry Johnson" class="img-fluid" />
              </div>
              <div class="each-slide-effect">
                <img src={model5} alt="Terry Johnson" class="img-fluid" />
              </div>
              <div class="each-slide-effect">
                <img src={model6} alt="Terry Johnson" class="img-fluid" />
              </div>
              <div class="each-slide-effect">
                <img src={model7} alt="Terry Johnson" class="img-fluid" />
              </div>
              <div class="each-slide-effect">
                <img src={model9} alt="Terry Johnson" class="img-fluid" />
              </div>
              <div class="each-slide-effect">
                <img src={model10} alt="Terry Johnson" class="img-fluid" />
              </div>
              <div class="each-slide-effect">
                <img src={model11} alt="Terry Johnson" class="img-fluid" />
              </div>
            </Slide>
            <a href="javascript:;" class="default-btn solution_class_popu" data-bs-toggle="modal"
              data-bs-target="#meeting" form_title="Marketing and Consulting Services">
              Let's Talk
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="inner-client-sec yellow-bg section-padding">
    <div class="container custom-cn-width">
      <div class="row align-items-center g-5 justify-content-center">
        <div class="col-lg-10">
          <h1 class="fw-400"><i class="fa fa-quote-left me-2"></i>I had a basic feel about what I wanted in a website.
            They helped me with the dynamic visual layout and content by which we were able to increase the
            functionality of our website while cutting our costs. Our current website is much easier to use with tons of
            more features, customized and easy to maintain. We could not be more happy with our new website! Thanks
            again!<i class="fa fa-quote-right ms-2"></i></h1>
          <h4 class="fw-400 mt-4 pt-lg-3">- President, E-Commerce</h4>
        </div>
      </div>
    </div>
  </section>
  <section class="clients text-center text-lg-start section-padding">
    <div class="container custom-cn-width">
      <div class="row g-xl-5 g-4 align-items-center justify-content-between">
        <div class="col-12 col-lg-6">
          <div class="">
            <h1 class="fw-light pe-lg-5">CAP Digisoft’s tools &amp; technology expertise</h1>
            <p class="mt-lg-4 mt-3">CAP Digisoft has experience in the latest web development tools and software, and
              we’ve created hundreds of websites for both corporate and government organizations. The testimonials from
              our satisfied clients speak for themselves.</p>
          </div>
        </div>
        <div class="col-12 col-lg-5">
          <div class="">
            <img className="img-fluid" src={cutout2} alt="Tools &amp; technology expertise" />
          </div>
        </div>
      </div>
      <div class="row g-xl-5 g-4 mt-0 align-items-center justify-content-center">
        <div class="col-lg-7 text-center order-2 order-lg-1">
          <img className="img-fluid" src={cutout3} />
        </div>
        <div class="col-lg-5 col-md-9 order-1 order-lg-2">
          <h3>Our web developers are experts in the latest web technologies.</h3>
        </div>
      </div>
    </div>
  </section>
  <section class="text-center bg-light section-padding">
    <div class="container custom-cn-width">
      <div class="row justify-content-center">
        <div class="col-lg-8 col-md-8 mb-3 mb-lg-5">
          <h1 class="fw-light">Our technology partners</h1>
        </div>
      </div>
      <div class="row gx-5 gy-lg-4 gy-1">
        <div class="col-lg-3 col-md-6">
          <div class="client-img"><img src={tech1} alt="" /></div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="client-img"><img src={tech2} alt="" /></div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="client-img"><img src={tech3} alt="" /></div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="client-img"><img src={tech4} alt="" /></div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="client-img"><img src={tech5} alt="" /></div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="client-img"><img src={tech6} alt="" /></div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="client-img"><img src={tech7} alt="" /></div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="client-img"><img src={tech8} alt="" /></div>
        </div>
      </div>
    </div>
  </section>

  <section class="section-padding">
    <div class="container custom-cn-width">
      <div class="row g-xl-5 g-4 align-items-center justify-content-center text-center">
        <div class="col-xxl-7 col-xl-8 col-lg-9">
          <h1 class="fw-light mb-lg-4 mb-3">
            Discover the CAP Digisoft Difference
          </h1>
          <p class="px-xl-4">
            Our highly-skilled, cost-effective, expert global resources bring the highest quality development standards
            at competitive pricing, with no compromises.
          </p>
        </div>
      </div>
    </div>
  </section>
  <section class="p-0 text-center text-lg-start section-padding">
    <div class="container custom-cn-width">
      <div class="cta-block cta-blue b-r-6">
        <div class="row g-lg-5 g-4 align-items-center justify-content-center">
          <div class="col-lg-9">
            <h2 class="fw-light text-white">
              Our custom website design services make it easy for your customers and prospects to engage and do business
              with you, and increase sales revenue.
            </h2>
          </div>
          <div class="col-lg-3 text-lg-end">
            <a href="javascript:;" class="default-btn solution_class_popu" data-bs-toggle="modal"
              data-bs-target="#meeting" form_title="Marketing and Consulting Services">
              Let's Talk
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="text-center client-block section-padding">
    <div class="container">
      <div class="row g-lg-5 g-4 justify-content-center">
        <div class="col-lg-12 mb-4 mb-lg-5">
          <h1 class="px-lg-4 ">
            Trusted by more than{" "}
            <span class="text-theme fw-bold text-grad">3000+</span> clients
            globally
          </h1>
        </div>
      </div>
      <TrustiesSlide/>
    </div>
  </section>
  <FooterOne />
</div>
);
};

export default WebSiteDesign;