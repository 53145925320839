import React, { useEffect } from 'react';
import { Link } from "react-router-dom";

import HeaderLight from '../../../common/header/HeaderLight';
import FooterOne from '../../../common/footer/FooterOne';

import banner from '../../../../src/app/assets/images/casestudy/intellicue/banner.png'
import mini from '../../../../src/app/assets/images/casestudy/tech/mini-equ.png'
import cutout1 from '../../../../src/app/assets/images/casestudy/intellicue/cutout1.png'
import cutout2 from '../../../../src/app/assets/images/casestudy/intellicue/cutout2.png'
import cutout3 from '../../../../src/app/assets/images/casestudy/intellicue/cutout3.png'

import tech1 from '../../../../src/app/assets/images/casestudy/tech/figma.png'

import case1 from '../../../../src/app/assets/images/casestudy/othercase/J&p.png'
import case2 from '../../../../src/app/assets/images/casestudy/othercase/meeting-minder.png'
import case3 from '../../../../src/app/assets/images/casestudy/othercase/iledgers.png'

const Intellicue = () => {
    
	useEffect(() => {
        document.title = 'Intellicue | CAP Digisoft ';
      }, []);

return (
<div className="casestudy-practica-page">
    <HeaderLight />
    <div class="container-fluid hero-banner pt-0 px-0 margin-minus">
        <div class="masthead tracky pt-lg-5">
            <h2 class="text-white text-center pt-5">Intellicue</h2>
            <p class="text-white text-center pb-4 pt-3">Online learning platform with mini interactive games based on
                the topics
            </p>
            <div class="img text-center">
                <img alt="" class="img-fluid rounded-3 pb-5" src={banner} />
            </div>
        </div>
    </div>
    <div class="about py-5">
        <div class="container custom-cn-width">
            <div class="row">
                <div class="col-lg-3 col-md-4 col-sm-12 ">
                    <div class="heading">
                        <div class="content1 d-flex align-items-center">
                            <img src={mini} class="img-fluid" alt="" />
                            <p class="fs-3 ">What is Intellicue?</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-9 col-md-8 col-sm-12">
                    <p>
                        Intellicue is an innovative online learning platform designed to support individuals in
                        enhancing their mental health through a variety of engaging tools. Administered by the system's
                        administrator, the app encompasses assessments, educational courses, and mini interactive games,
                        forming a comprehensive online learning platform. </p>
                </div>
            </div>
        </div>

    </div>
    <div class="picture">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-lg-12">
                    <div class="picture-img">
                        <img class="w-100 tracky-mockup img-fluid" src={cutout1} alt="" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="position-relative py-5">
        <div class="container custom-cn-width">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="heading">
                        <div class="content1 d-flex align-items-center">
                            <img src={mini} class="img-fluid" alt="" />
                            <p class="fs-3 ">Scenario</p>
                        </div>
                    </div>
                    <p class="p-4">
                        Traditional online learning often faces challenges related to learner engagement, passive
                        participation, and low retention rates. Students may struggle to maintain focus during remote
                        lessons, leading to decreased understanding of complex topics. A lack of interactive elements
                        can contribute to disinterest and hinder the overall learning experience.</p>
                    <div class="heading">
                        <div class="content1 d-flex align-items-center">
                            <img src={mini} class="img-fluid" alt="" />
                            <p class="fs-3 ">CDS Solution Approach</p>
                        </div>
                    </div>
                    <p class="p-4">
                        We developed this system, encompassing assessments, courses, and interactive games, is designed
                        as a pivotal tool for individuals dedicated to bolstering their mental health. Tailored for a
                        diverse spectrum of users, it caters to students grappling with academic stress, professionals
                        navigating workplace challenges, individuals seeking support for anxiety or depression, and
                        those on the journey to overcome addiction.</p>
                </div>
                <div class="col-lg-6">
                    <div class="tracky-middle  d-flex justify-content-center">
                        <img class="rounded-3 py-0 img-fluid" src={cutout2} alt="" />
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div class="picture1">
        <div class="container custom-cn-width">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="picture1-img text-center">
                        <img class="rounded-3 py-0 img-fluid" src={cutout3} alt="" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="impact py-5">
        <div class="container custom-cn-width">
            <div class="row">
                <div class="col-lg-3 col-sm-12 ">
                    <div class="heading">
                        <div class="content1 d-flex align-items-center">
                            <img src={mini} class="img-fluid" alt="" />
                            <p class="fs-3 ">Impact</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-9 col-sm-12  ">
                    <ul class="pt-2 list-styled">
                        <li>Assessment</li>
                        <li>Customized Learning Paths</li>
                        <li>Educational Courses</li>
                        <li>Interactive Games</li>
                        <li>Progress Tracking</li>
                        <li>Live Webinars</li>
                        <li>Mindfulness Exercises</li>
                        <li>Games and Interactive Activities</li>
                    </ul>
                </div>
                <div class="col-lg-3 col-sm-12 pb-3 pt-5">
                    <div class="heading">
                        <div class="content1 d-flex align-items-start ">
                            <img src={mini} class="img-fluid" alt="" />
                            <p class="fs-3 ">Technology Stack</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-9 col-sm-12 pt-5">
                    <div class="impact-img4 d-flex align-items-center">
                        <div class="img2 p-2 pb-0 case"><img src={tech1} class="img-fluid" alt="" />
                        </div>

                    </div>

                </div>
                <div class="col-lg-3 col-sm-12  pt-5">
                    <div class="heading">
                        <div class="content1 d-flex align-items-center">
                            <img src={mini} class="img-fluid" alt="" />
                            <p class="fs-3 ">Conclusion</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-9 col-sm-12 pt-lg-5 pt-2">
                    <p class="pt-2">This app aims to provide a comprehensive and supportive environment for individuals
                        overcoming drug addiction, integrating education, assessments, games, and community engagement
                        to promote lasting mental health improvements.</p>
                </div>

            </div>
        </div>
    </div>
    <div class="more-cases pb-5">
        <div class="container custom-cn-width">
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="heading">
                        <div class="content1 d-flex align-items-center">
                            <img src={mini} class="img-fluid" alt="" />
                            <p class="fs-3 ">Other Case Studies</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row text-center py-4 g-4">
                <div class="col-lg-4 col-sm-12">
                    <Link to="/j&p_services+">
                        <div class="case rounded-3 h-100 case1 p-4 bg-light">
                            <div class="case-title">
                                <h4 class="">J&P services+</h4>
                            </div>
                            <p class=" pt-2"> Allows guests in J&P-partnered hotels to check in, request
                                services.
                            </p>
                            <div class="case-img"><img class="img-fluid rounded py-3" src={case1} alt="" />
                            </div>

                        </div>
                    </Link>
                </div>
                <div class="col-lg-4 col-sm-12 ">
                    <Link to="/meeting-minder">
                        <div class="case rounded-3  h-100 case1 p-4 bg-light">
                            <div class="">
                                <h4 class="">Meeting Minder</h4>
                            </div>
                            <p class=" pt-2">Mobile application is used for scheduling and managing the
                                meetings
                            </p>
                            <div class="case-img"><img class="img-fluid rounded py-3" src={case2} alt="" />
                            </div>
                        </div>
                    </Link>
                </div>
                <div class="col-lg-4 col-sm-12">
                    <Link to="/iledgers">
                        <div class="case rounded-3  h-100 case1 p-4 bg-light">
                            <div class="case-title">
                                <h4 class="">iLedgers</h4>
                            </div>
                            <p class=" pt-2">Truck Fault Inspection</p>
                            <div class="case-img"><img class="img-fluid rounded py-3" src={case3} alt="" />
                            </div>

                        </div>
                    </Link>
                </div>
            </div>
        </div>
    </div>

    <FooterOne />
</div>
)
}

export default Intellicue;