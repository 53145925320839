import React, { useEffect } from 'react';
import { Link } from "react-router-dom";

import HeaderLight from '../../../common/header/HeaderLight';
import FooterOne from '../../../common/footer/FooterOne';

import banner from '../../../../src/app/assets/images/casestudy/jugl/banner.png'
import mini from '../../../../src/app/assets/images/casestudy/tech/mini-equ.png'
import cutout1 from '../../../../src/app/assets/images/casestudy/jugl/cutout1.png'
import cutout2 from '../../../../src/app/assets/images/casestudy/jugl/cutout2.png'
import cutout3 from '../../../../src/app/assets/images/casestudy/jugl/cutout3.png'

import tech1 from '../../../../src/app/assets/images/casestudy/tech/html.png'
import tech2 from '../../../../src/app/assets/images/casestudy/tech/css.png'
import tech3 from '../../../../src/app/assets/images/casestudy/tech/aws.png'
import tech4 from '../../../../src/app/assets/images/casestudy/tech/appium.png'
import tech5 from '../../../../src/app/assets/images/casestudy/tech/react.png'
import tech6 from '../../../../src/app/assets/images/casestudy/tech/elixir.png'
import tech7 from '../../../../src/app/assets/images/casestudy/tech/erlang.png'
import tech8 from '../../../../src/app/assets/images/casestudy/tech/rest.png'
import tech9 from '../../../../src/app/assets/images/casestudy/tech/docker.png'
import tech10 from '../../../../src/app/assets/images/casestudy/tech/p-sql.png'
import tech11 from '../../../../src/app/assets/images/casestudy/tech/mobx.png'
import tech12 from '../../../../src/app/assets/images/casestudy/tech/js.png'

import case1 from '../../../../src/app/assets/images/casestudy/othercase/attesta.png'
import case2 from '../../../../src/app/assets/images/casestudy/othercase/bussopi.png'
import case3 from '../../../../src/app/assets/images/casestudy/othercase/genie.png'

const Jugl = () => {
    
	useEffect(() => {
        document.title = 'Jugl | CAP Digisoft ';
      }, []);

return (
<div className="casestudy-attesta-page">
    <HeaderLight />
    <div class="container-fluid pt-0 px-0 margin-minus">
        <div class="masthead tracky pt-lg-5">
            <h2 class="text-white text-center pt-5">JUGL</h2>
            <p class="text-white text-center pb-4 pt-3">Workforce communication made simple
            </p>
            <div class="row justify-content-center">

                <div class="img text-center">
                    <img alt="" class="img-fluid rounded-3 pb-5" src={banner} />
                </div>

            </div>
        </div>
    </div>
    <div class="about py-5">
        <div class="container custom-cn-width">
            <div class="row">
                <div class="col-lg-3 col-md-4 col-sm-12 ">
                    <div class="heading">
                        <div class="content1 d-flex align-items-center">
                            <img src={mini} class="img-fluid" alt="" />
                            <p class="fs-3 ">What is JUGL?</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-9 col-md-8 col-sm-12">
                    <p>
                        JUGL is a Workforce Communication App designed for small and medium sized businesses to own and
                        manage all of the communications happening between their people and projects with a
                        highly-integrated task management, calendar events, chat, and cloud storage to help achieve new
                        heights. </p>
                </div>
            </div>
        </div>

    </div>
    <div class="picture">
        <div class="container custom-cn-width">
            <div class="row justify-content-center">
                <div class="col-lg-12">
                    <div class="picture-img">
                        <img class="w-100 tracky-mockup img-fluid" src={cutout1} alt="" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="position-relative py-5">
        <div class="container custom-cn-width">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="heading">
                        <div class="content1 d-flex align-items-center">
                            <img src={mini} class="img-fluid" alt="" />
                            <p class="fs-3 ">Scenario</p>
                        </div>
                    </div>
                    <p class="p-4">
                        Most small and medium size business use free chat Apps to manage their business communication
                        that can create confusion and chaos for the team members that lacks a robust set of
                        administrative controls features and no built-in way to store and organize files in the cloud
                        also failing to protect the data privacy. Free chat Apps finally end up to be a poor choice for
                        businesses due to the lack of productivity tools such as task management and event reminders.
                    </p>
                    <div class="heading">
                        <div class="content1 d-flex align-items-center">
                            <img src={mini} class="img-fluid" alt="" />
                            <p class="fs-3 ">CDS Solution Approach</p>
                        </div>
                    </div>
                    <p class="p-4">
                        We developed a fully functional workforce communication App “JUGL”. Now, businesses get to own
                        their data, have their own space to run their business, and communicate with their people. JUGL
                        allows businesses to chat, manage tasks, schedule meetings, get reminders for meetings and
                        tasks, track task progress, securely store and share files in the cloud drive, and have a
                        branded workspace with their company logo.</p>
                </div>
                <div class="col-lg-6">
                    <div class="tracky-middle  d-flex justify-content-center">
                        <img class="rounded-3 py-0 img-fluid" src={cutout2} alt="" />
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div class="picture1">
        <div class="container custom-cn-width">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="picture1-img text-center">
                        <img class="rounded-3 py-0 img-fluid" src={cutout3} alt="" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="impact py-5">
        <div class="container custom-cn-width">
            <div class="row">
                <div class="col-lg-3 col-sm-12 ">
                    <div class="heading">
                        <div class="content1 d-flex align-items-center">
                            <img src={mini} class="img-fluid" alt="" />
                            <p class="fs-3 ">Impact</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-9 col-sm-12  ">
                    <ul class="mt-top list-styled">
                        <li>Business owners can have and manage multiple businesses</li>
                        <li>Business owner gets complete control over company’s communications and data </li>
                        <li>Manage data storage in JUGL Drive and authenticate members to access it. </li>
                        <li>Enhanced security with the ability to wipe company data from personal devices.</li>
                        <li>Businesses can easily assign a task to a specific employee and monitor the progress of the
                            task
                        </li>
                        <li>Create a group to host every employee in your company to give out company announcements,
                            critical information and wishes.</li>
                        <li>Upload files, photos, videos, and more to share with your team members or clients. </li>
                        <li>Make Audio &amp; Video Calls within your teams with better quality and control. </li>
                        <li>Create events and schedule meeting reminders to ensure no one misses it.</li>
                        <li>Employers/Employees can see all of the people in the company/branch with a single click.
                        </li>
                    </ul>
                </div>
                <div class="col-lg-3 col-sm-12 pb-3 pt-5">
                    <div class="heading">
                        <div class="content1 d-flex align-items-start ">
                            <img src={mini} class="img-fluid" alt="" />
                            <p class="fs-3 ">Technology Stack</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-9 col-sm-12 pt-5">
                    <div class="impact-img4  d-flex align-items-center">
                        <div class="img2 p-2 pb-0 case"><img src={tech1} class="img-fluid" alt="" />
                        </div>
                        <div class="img2 p-2 pb-0 case"><img src={tech2} class="img-fluid" alt="" />
                        </div>
                        <div class="img2 p-2 pb-0  case"><img src={tech3} class="img-fluid" alt="" />
                        </div>
                        <div class="img2 p-2 pb-0 case"><img src={tech4} class="img-fluid" alt="" />
                        </div>
                        <div class="img2 p-2 pb-0 case"><img src={tech5} class="img-fluid" alt="" />
                        </div>
                        <div class="img2 p-2 pb-0 case"><img src={tech6} class="img-fluid" alt="" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="impact-img4  d-flex align-items-center">
                            <div class="img2 p-2 pb-0 case"><img src={tech7} class="img-fluid" alt="" />
                            </div>
                            <div class="img2 p-2 pb-0 case"><img src={tech8} class="img-fluid" alt="" />
                            </div>
                            <div class="img2 p-2 pb-0  case"><img src={tech9} class="img-fluid" alt="" />
                            </div>
                            <div class="img2 p-2 pb-0 case"><img src={tech10} class="img-fluid" alt="" />
                            </div>
                            <div class="img2 p-2 pb-0 case"><img src={tech11} class="img-fluid" alt="" />
                            </div>
                            <div class="img2 p-2 pb-0 case"><img src={tech12} class="img-fluid" alt="" />
                            </div>
                        </div>

                    </div>
                </div>
                <div class="col-lg-3 col-sm-12  pt-5">
                    <div class="heading">
                        <div class="content1 d-flex align-items-center">
                            <img src={mini} class="img-fluid" alt="" />
                            <p class="fs-3 ">Conclusion</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-9 col-sm-12 pt-lg-5 pt-2">
                    <p class="pt-2">Business can boost the workforce efficiency and productivity by up to 2X. To
                        understand how JUGL works? Download and use JUGL on any device with IOS, Android, and a Web
                        version. Share your Feedback!</p>
                </div>

            </div>
        </div>
    </div>
    <div class="more-cases pb-5">
        <div class="container custom-cn-width">
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="heading">
                        <div class="content1 d-flex align-items-center">
                            <img src={mini} class="img-fluid" alt="" />
                            <p class="fs-3 ">Other Case Studies</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row text-center py-4 g-4">
                <div class="col-lg-4 col-sm-12">
                    <Link to="/attesta_ai">
                        <div class="case rounded-3  h-100 case1 p-4 bg-light">
                            <div class="case-title">
                                <h4 class="">Attesta AI</h4>
                            </div>
                            <p class=" pt-2"> Amazing AI-based document discovery to Reduce your review time
                                by
                                70% </p>
                            <div class="case-img"><img class="img-fluid rounded py-3" src={case1} alt="" />
                            </div>

                        </div>
                    </Link>
                </div>
                <div class="col-lg-4 col-sm-12 ">
                    <Link to="/bussopi">
                        <div class="case rounded-3 h-100 case1 p-4 bg-light">
                            <div class="case-title">
                                <h4 class="t">Bussopi</h4>
                            </div>
                            <p class=" pt-2">School app for learning</p>
                            <div class="case-img"><img class="img-fluid rounded py-3" src={case2} alt="" />
                            </div>
                        </div>
                    </Link>
                </div>
                <div class="col-lg-4 col-sm-12">
                    <Link to="/genie">
                        <div class="case rounded-3 h-100 case1 p-4 bg-light">
                            <div class="case-title">
                                <h4 class="">Genie</h4>
                            </div>
                            <p class=" pt-2">The Genie app serves as a transformative launcher</p>
                            <div class="case-img"><img class="img-fluid rounded py-3" src={case3} alt="" />
                            </div>

                        </div>
                    </Link>
                </div>
            </div>
        </div>
    </div>

    <FooterOne />
</div>
)
}

export default Jugl;