/* eslint-disable jsx-a11y/alt-text */
import React, {useEffect} from "react";

import HeaderDark from "../../../common/header/HeaderDark";
import FooterOne from "../../../common/footer/FooterOne";

import banner from "../../../../src/app/assets/images/b2b-media/banner-bg.jpg";
import shape1 from "../../../../src/app/assets/images/b2b-media/shape-1.png";

import pipe from "../../../../src/app/assets/images/b2b-media/2x.png";

import cutout1 from "../../../../src/app/assets/images/b2b-media/cutout1.jpg";
import cutout2 from "../../../../src/app/assets/images/b2b-media/cutout2.jpg";
import cutout3 from "../../../../src/app/assets/images/b2b-media/cutout3.png";

import Slider from "../../layouts/slide";
import Meta from "../../Meta/B2B-lead-generation";

const B2B = () => {
 
	useEffect(() => {
	  document.title = 'B2B Lead Generation Expert | CAP Digisoft ';
	}, []);
  return (
    <div className="b2b-page">
      <HeaderDark />
      <Meta/>
      <section class="sdr-banner banner-sec pb-lg-0">
        <div class="container-fluid position-relative z-index-1">
          <div class="row align-items-center justify-content-center">
            <div class="col-lg-10 col-xl-6 pe-lg-0 text-center">
              <div class="hero-b2b-1">
                <img
                  src={banner}
                  class="img-fluid hero-image"
                  alt="Hero Banner shape"
                />
                <div class="position-relative d-inline-block text-end">
                  <img
                    src={shape1}
                    class="img-fluid shape-1"
                    alt="Hero Banner shape"
                  />
                  <h1 class="banner-text text-center">B2B</h1>
                </div>
                <h1 class="mb-4 mb-lg-5 banner-text text-center">
                  Lead Generation Experts.
                </h1>

                <div class="shape-2 d-xxl-block d-none">
                  <svg
                    width="143"
                    height="167"
                    viewBox="0 0 143 167"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      class="draw2 animatedes"
                      d="M117.598 165.891L140.651 151.002C141.276 150.598 141.233 149.721 140.571 149.368L116.69 136.625"
                      stroke="#141313"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      class="animatedes"
                      d="M34.49 1.45581C-23.5151 42.6406 2.07105 91.6836 51.8502 95.5715C105.783 99.7838 111.671 56.5383 91.2246 53.9823C22.8593 45.4358 57.0779 172.416 139.527 150.056"
                      stroke="#141313"
                      stroke-width="2"
                      stroke-linecap="round"
                    ></path>
                  </svg>
                </div>
              </div>

              <div class="hero-b2b-2">
                <div className="hero-b3b-3-img">
                  <img src={banner} class="img-fluid" alt="Hero Banner shape" />
                  <div className="hero-b3b-3-text">
                    <h1 class="mb-4 mb-lg-5 banner-text text-center">
                      B2B Lead Generation Experts.
                    </h1>
                  </div>
                </div>

                
              </div>

              <div class="top-sec text-xl-start text-center">
                <h5>B2B Lead Generation</h5>
                <p class="mt-3">
                  Our Orchestrated Outbound Services are designed to help you
                  grow your company by building a strong sales pipeline. Our
                  services include detailed cadence preparation, prospect data
                  generation, multi-channel prospecting, bilingual SDRs, fast
                  lead response times, and so much more.
                </p>
              </div>
              <div class="bottom-sec p-4 bg-dark text-xl-start text-center">
                <p class="text-white">
                  CAP Digisoft supports your company's growth with our creative,
                  best-practice omnichannel method for Orchestrated Outbound.
                </p>
              </div>
              <div class="mouse-scroll-animation mt-3">
                <div class="scroll-animation d-flex flex-column justify-content-center align-items-center">
                  <div class="mouse">
                    <div class="wheel"></div>
                  </div>
                  <h6 class="mt-2">Scroll Down</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="bg-dark process-sec z-index-1 section-padding">
        <div class="container custom-cn-width">
          <div class="row align-items-center justify-content-center text-lg-start text-center">
            <div class="col-lg-7 ">
              <p class="text-uppercase gradient-l-blue mb-3">Process</p>
              <h1 class="title text-white">
                Our Process to Support and Maintain Growth
              </h1>
            </div>
            <div class="col-lg-5">
              <p class="pb-3 mx-lg-4 fs-20 text-white">
                Our experience in sales generation and orchestrated outbound
                enables us to create systematic approaches for targeted
                omnichannel marketing campaigns. We've developed best practices
                specialized in assisting our clients to funnel their qualified
                prospects for their sales teams.
              </p>
            </div>
          </div>

          <div class="row justify-content-center g-4 g-lg-3 mt-4">
            <div class="col-lg-3">
              <div class="sb-block border p-4">
                <h5 class="fw-bold mt-3 text-white">01</h5>
                <h5 class="fw-bold mt-1 text-white">
                  Readiness for the Omnichannel approach
                </h5>
                <p class="para1 mt-3 text-white">
                  We like to start with a consultation to review if your company
                  is ready for orchestrated outbound. If you are not ready,
                  that’s okay, most companies are not. We will help you set up
                  all the necessary processes including creating an outbound
                  script, cadence, flow, and an omnichannel approach that’s
                  right for you.
                </p>
                <p></p>
              </div>
            </div>
            <div class="col-lg-3">
              <div class="sb-block border p-4">
                <h5 class="fw-bold mt-3 text-white">02</h5>
                <h5 class="fw-bold mt-1 text-white">Data</h5>
                <p class="para1 mt-3 text-white">
                  Now we get started on the continuous follow-up sequence. If
                  you do not have prospecting data for us, our data team can
                  source some for you from the industry. You may also source the
                  data yourself from the industry and provide it to us, from
                  there we will verify it.
                </p>
                <p></p>
              </div>
            </div>
            <div class="col-lg-3">
              <div class="sb-block border p-4">
                <h5 class="fw-bold mt-3 text-white">03</h5>
                <h5 class="fw-bold mt-1 text-white">
                  Selecting the best SDRs for your account
                </h5>
                <p class="para1 mt-3 text-white">
                  We have consistently trained SDR team members and we will
                  select the best representatives for your account and prepare
                  them for the calls and orchestrated outbound activities they
                  will do for you. We will identify the most qualified SDR team
                  members and get them ready for the omnichannel setup to most
                  efficiently boost your sales pipeline growth.
                </p>
                <p></p>
              </div>
            </div>
            <div class="col-lg-3">
              <div class="sb-block border p-4">
                <h5 class="fw-bold mt-3 text-white">04</h5>
                <h5 class="fw-bold mt-1 text-white">Constant Review</h5>
                <p class="para1 mt-3 text-white">
                  Every week, we analyze calls our SDRs make, the reachability,
                  and their conversions. We examine audio recordings as well as
                  email and phone data to assess performance and prospect
                  responses. We believe there is always room for improvement! We
                  look for every possible opportunity to improve, and we remain
                  agile in our efforts to identify and convert more leads to our
                  clients.
                </p>
                <p></p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="boost-sec section-padding">
        <div class="container custom-cn-width">
          <div class="row justify-content-between align-items-center">
            <div class="col-lg-6">
              <div class="circle-animation d-lg-block d-none">
                <div class="svg-container position-relative">
                  <div class="progress">
                    <span className="outer-circle"></span>
                    <div
                      class="title timer"
                      data-from="0"
                      data-to="95"
                      data-speed="800"
                    >
                      <h1>2X</h1>
                      <p>Boost your pipeline</p>
                    </div>
                    <div class="overlay"></div>
                    <div class="left"></div>
                    <div class="right"></div>
                  </div>

                </div>
              </div>
              <div class="d-lg-none d-block text-center">
                <img src={pipe} alt="" class="img-fluid" />
              </div>
            </div>
            <div class="col-lg-6">
              <h1 class="sec-title mt-lg-0 mt-4 mb-4">
                We <span class="f-em text-bk-dark fw-semibold">empower</span>{" "}
                your sales team to focus on conversions!
              </h1>
              <div class="pe-lg-4">
                <p class="mb-3">
                  CAP Digisoft makes high-level sales targets attainable with
                  our qualified prospecting system! Our approach has brought in
                  2x productivity for the majority of our clients and we’d like
                  to do the same for you.
                </p>
                <p class="para1">
                  We'll work on your pipeline using our Orchestrated Outbound
                  platform, data-driven research, phone, email, social media,
                  and online prospecting. Let CAP Digisoft fill your pipeline so
                  your sales team can concentrate on closing sales.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="boost-sec sdr-sec section-padding">
        <div class="container custom-cn-width">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <h1 class="sec-title mt-lg-0 mt-4 mb-4">
                Let us take care of SDR{" "}
                <span class="text-bk-dark f-em">retention</span>
              </h1>
              <div class="pe-lg-4">
                <p class="mb-3">
                  Sales Development is never easy, and finding a quality,
                  in-house SDR that will stay with your company for longer than
                  a few years is next to impossible. In fact, 44% of SDRs plan
                  on leaving their job within 2 years, and the cost of replacing
                  an SDR can be upwards of 150% - 200% of their salary.
                </p>
              </div>
            </div>
            <div class="col-lg-6 d-flex justify-content-center">
              <div class="row align-items-center">
                <div class="col-6 col-lg-6">
                  <img src={cutout1} alt=" " class="img-fluid" />
                </div>
                <div class="col-6 col-lg-6">
                  <div class="stat-sec text-center">
                    <h1 class="sec-title">44%</h1>
                    <p class="mb-4">of SDRs plan on leaving their job</p>
                    <img src={cutout2} alt=" " class="img-fluid" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <hr class="my-4 px-5 mx-lg-5" />

      <section class="boost-sec sdr-sec section-padding">
        <div class="container custom-cn-width">
          <div class="row justify-content-between">
            <div class="col-lg-5 text-lg-end text-center">
              <img src={cutout3} alt=" " class="img-fluid" />
            </div>

            <div class="col-lg-7">
              <h1 class="sec-title mb-4">
                No matter the prospect we have the{" "}
                <span class="text-bk-dark f-em">perfect SDR</span>
              </h1>
              <div class="ps-lg-5 ms-lg-5 mt-lg-5 mt-3">
                <p class="mb-3">
                  Bypass all of the stress and money required to constantly hire
                  and train new SDRs by outsourcing the aches and pains. At CAP
                  Digisoft we take care of all the hiring, training, and
                  maintaining of sales development teams to ensure your pipeline
                  stays full. This means that you not only get trained SDRs but
                  you also get their management. We also take a unique approach
                  in encouraging our SDRs to use the most creative means
                  necessary to get in touch with prospects. No matter the
                  prospect we have the perfect SDR, to deliver the right
                  message, at the right time.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="cta-sec sec-1 section-cn-width section-padding">
        <div class="container custom-cn-width">
          <div class="row justify-content-center text-center">
            <div class="col-lg-10">
              <h1 class="sec-title mb-lg-5 mb-3 text-white">
                Schedule a Meeting Today!
              </h1>

              <div class="pe-lg-4">
                <div class="text-end my-b">
                  <svg
                    class="d-lg-inline-block d-none animated wow fadeInUp animated"
                    width="559"
                    height="14"
                    viewBox="0 0 559 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      class="animatedes"
                      d="M205.048 5.21268C255.722 4.37743 306.772 4.81009 357.515 4.81009C413.246 4.81009 468.44 4.25168 523.857 2.48404C527.779 2.35895 531.701 2.23304 535.611 2.08145C538.159 1.98264 544.165 2.23385 543.12 1.5894C541.457 0.564499 508.417 1.18749 507.207 1.18682C406.406 1.13113 305.604 1.18682 204.803 1.18682C137.35 1.18682 69.7485 0.831402 2.3031 1.18682C-1.68174 1.20782 10.2532 1.34846 14.2197 1.45521C39.6423 2.13941 64.8908 3.18855 90.3715 3.73653C207.183 6.24863 325.655 4.81009 442.727 4.81009C479.048 4.81009 588.011 4.81009 551.69 4.81009C449.583 4.81009 347.476 4.81009 245.369 4.81009C210.408 4.81009 175.447 4.81009 140.486 4.81009C121.034 4.81009 179.392 4.81009 198.845 4.81009C310.393 4.81009 645.036 4.81009 533.489 4.81009C477.336 4.81009 421.639 7.26395 365.759 8.52283C319.817 9.5578 273.937 10.106 227.902 10.6252C212.668 10.797 199.162 11.1626 184.48 12.0566"
                      stroke="#ffbf00"
                      stroke-width="2"
                      stroke-linecap="round"
                    ></path>
                  </svg>
                </div>

                <p class="mb-4 text-white">
                  The 30 Minutes you invest in us could be the solution you have
                  been looking for.
                </p>
                <a
                  href="javascript:void(0)"
                  class="btn btn-primary btn-pill"
                  data-bs-toggle="modal"
                  data-bs-target="#meeting"
                >
                  Get a Free Analysis{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="boost-sec leader-sec section-padding">
        <div class="container custom-cn-padding">
          <div class="row justify-content-between">
            <div class="col-lg-11 text-lg-start ">
              <h1 class="sec-title mb-4">
                Be a leader in your field with CAP Digisoft’s Orchestrated
                Outbound
              </h1>
              <p class="mb-4 mb-lg-5 pb-lg-4">
                Partner with us and experience the abundance of our resources
                from the United States, India, and El Salvador.
              </p>
            </div>
          </div>
          <div class="row justify-content-between g-4 g-lg-5">
            <div class="col-lg-6">
              <h1 class="mb-2">01</h1>
              <h2 class="mb-4">
                No matter the size of the client, we can handle it
              </h2>
              <div class="pe-lg-4">
                <p class="mb-3">
                  We serve clients of all sizes at CAP Digisoft, but in order to
                  achieve the best results it’s important that businesses have
                  an existing process for converting the leads we bring to your
                  pipeline via your own in-house BDRs or sales professionals. We
                  have also found that businesses that have a solid product or
                  service with a good profit margin will achieve the best
                  results.
                </p>
              </div>
            </div>
            <div class="col-lg-6">
              <h1 class="mb-2">02</h1>
              <h2 class="mb-4">Our Orchestration Technology</h2>
              <div class="pe-lg-4">
                <p class="mb-3">
                  Our one-of-a-kind Orchestrated Outbound System allows sales
                  representatives to operate more efficiently and optimize sales
                  development activities. Both our system and resources are
                  trained in omnichannel outreach and CRMs. We make sure the
                  right people are on the right processes at the perfect time in
                  order to accelerate top-of-funnel action and proactively
                  schedule qualified appointments, thus consistently filling
                  your pipeline.
                </p>
              </div>
            </div>
            <div class="col-lg-6">
              <h1 class="mb-2">03</h1>
              <h2 class="mb-4">Qualified &amp; Trained SDRs</h2>
              <div class="pe-lg-4">
                <p class="mb-3">
                  We hire well-educated, personable SDRs who are bilingual in
                  English and Spanish and are up to speed on all of the various
                  challenges your company may face concerning email outreach,
                  prospect psychology, and other service-related strategies. We
                  value SDRs with exceptional conversational skills and hire the
                  most personable candidates that really open prospects up and
                  make them feel comfortable. We support cross-training across
                  departments, skilled performance across all digital channels,
                  and a continuous stream of knowledge regarding our industry.
                  As a result we train our SDRs consistently to ensure they
                  understand the current industry best practices and maintain
                  expertise in CRM. Also all of our SDR agents are nearshore,
                  located in Central America, so there are no time zone
                  discrepancies.
                </p>
              </div>
            </div>
            <div class="col-lg-6">
              <h1 class="mb-2">04</h1>
              <h2 class="mb-4">The Perfect Prospect</h2>
              <div class="pe-lg-4">
                <p class="mb-3">
                  We display your top-of-funnel sales successes in real-time by
                  incorporating the latest analytics, insights, and trends to
                  verify your ICP, and analyze prospect replies. With this data,
                  we fine-tune and optimize SDR activities.This step is
                  optional, but if you do not have the necessary prospecting
                  data, we can generate it for you. We can obtain data from the
                  industry, or you can get it from the industry yourself and we
                  can verify it. If you already have verified data, then we
                  don't need to access the data services, and we can simply
                  source prospects from outside. Then we will provide the data
                  to our SDR team, who are experts in CRMs, to use in our
                  omnichannel approach.
                </p>
              </div>
            </div>
            <div class="col-lg-12">
              <h1 class="mb-2">05</h1>
              <h2 class="mb-4">Fully Managed SDRs</h2>
              <div class="pe-lg-4">
                <p class="mb-3">
                  Our SDR Teams are held accountable at Department and Customer
                  Success levels and are rigorously monitored in the progress of
                  SDR activities, tasks, and assignments performed for you in
                  order to ensure you receive the best service. This essentially
                  means that when you outsource our SDRs you also get their
                  management. As a premier provider of Orchestrated Outbound
                  Omni-channel Solutions, our team of expert SDRs are
                  well-versed in the latest tools and techniques necessary to
                  consistently provide qualified leads for your sales team. Our
                  SDRs are consistently trained and empowered in order to keep
                  up with the industry best practices of social media
                  prospecting, LinkedIn lead generation, and email &amp; phone
                  outreach. We originally built this solution for ourselves and
                  over time we have perfected our processes. Realizing what a
                  great solution we created, we decided we want to help other
                  companies craft the perfect omnichannel strategy to reach a
                  wider audience and stay in front of prospects in a proactive
                  manner.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="cta-sec sec-2 section-padding">
        <div class="container custom-cn-width">
          <div class="row justify-content-center text-lg-start text-center align-items-center">
            <div class="col-lg-9 position-relative">
              <h1 class="sec-title mb-5 text-white">
                Schedule a <br/><span class="f-em gradient-l-blue">Meeting </span>
                Today!
              </h1>

              <div class="animated wow fadeInUp arrow-right">
                <span alt="Cta arrow" class="d-lg-block d-none">
                  <svg
                    width="533"
                    height="178"
                    viewBox="0 0 533 178"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      class="animatedes"
                      d="M1.5 78C49.6667 97.1667 162 139.8 226 157C306 178.5 320.5 181 315 169C309.5 157 232 118.5 208.5 104.5C185 90.5 164 76.5 221 80C278 83.5 450.5 98.5 529.5 3"
                      stroke="#ffbf00"
                      stroke-width="3"
                      stroke-linecap="round"
                    ></path>
                    <path
                      class="animatedes"
                      d="M517 3.5L530 2L531.5 14"
                      stroke="#ffbf00"
                      stroke-width="3"
                      stroke-linecap="round"
                    ></path>
                  </svg>
                </span>
              </div>
            </div>
            <div class="col-lg-3">
              <div class=" ">
                <a
                  href="javascript:void(0)"
                  class="btn btn-primary btn-pill"
                  data-bs-toggle="modal"
                  data-bs-target="#meeting"
                >
                  Get a Free Analysis{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-padding">
        <div className="container custom-cn-width">
          <div class="row justify-content-center text-center align-items-center">
            <div class="col-lg-9 position-relative">
              <h1 class="cs-title mb-3">Customer Success Stories</h1>
              <p class="pb-3 mx-lg-4 fs-20 mb-4">
                CAP Digisoft’s People-as-a-Service solution has a unique mixture
                of managed services and software to allow fast-growing sales
                organizations to scale at a rapid rate. Learn what our clients
                think of our company's skill in developing teams that generate
                pipelines full of qualified meetings.
              </p>
            </div>
          </div>
          <Slider/>
        </div>
      </section>

      {/* <section class="blogs-sec pt-0 section-padding">
        <div class="container custom-cn-width">
          <div class="row justify-content-center text-center align-items-center">
            <div class="col-lg-12 position-relative">
              <hr class="mb-5 px-5 mx-lg-5 bg-secondary" />
              <h1 class="cs-title mb-5">From our blogs</h1>
            </div>
          </div>

          <div class="row g-4">
            <div class="col-lg-4 col-md-12">
              <div class="blog-block bg-shadow b-r-6 p-4">
                <h4 class="mb-2 font-size-25">
                  <a
                    class="text-dark font-size-25"
                    href="https://capdigisoft.com/blog/why-hybrid-b2b-sales-are-the-future/"
                  >
                    Why Hybrid B2B Sales are the Future
                  </a>
                </h4>
                <p class="mb-3 f-15 fw-400">Barath Kumar</p>
                <hr />
                <p class="mb-2 hidden-4">
                  In this new day and age, it’s pretty obvious what B2B buyers
                  want from their suppliers, more channels, convenience, and
                  highly personalized interactions. There is a desire for a
                  balanced mix of in-person interaction, remote contact, and
                  e-commerce self-service...
                </p>
                <a
                  class="d-inline-block underline-border"
                  href="https://capdigisoft.com/blog/why-hybrid-b2b-sales-are-the-future/"
                >
                  <span class="border-line d-inline-block me-1">
                    Learn more
                  </span>
                  <i class="fa fa-arrow-right fa-sm position-relative"></i>
                </a>
              </div>
            </div>
            <div class="col-lg-4 col-md-12">
              <div class="blog-block bg-shadow b-r-6 p-4">
                <h4 class="mb-2 font-size-25">
                  <a
                    class="text-dark font-size-25"
                    href="https://capdigisoft.com/blog/why-is-sdr-outsourcing-vital-to-leverage-growth/"
                  >
                    Why is SDR Outsourcing Vital to Leverage Growth?
                  </a>
                </h4>
                <p class="mb-3 f-15 fw-400">Gerald Valentine</p>
                <hr />
                <p class="mb-2 hidden-4">
                  The more sales you wish to close, the more sales development
                  reps you will need. You have a greater chance of maximizing
                  the impact and closing more sales by outsourcing your SDR than
                  by going solo with your in-house sales reps. Every qualifying
                  conversation is different, and you need experts who are well
                  trained to handle your prospects...
                </p>
                <a
                  class="d-inline-block underline-border"
                  href="https://capdigisoft.com/blog/why-is-sdr-outsourcing-vital-to-leverage-growth/"
                >
                  <span class="border-line d-inline-block me-1">
                    Learn more
                  </span>
                  <i class="fa fa-arrow-right fa-sm position-relative"></i>
                </a>
              </div>
            </div>
            <div class="col-lg-4 col-md-12">
              <div class="blog-block p-4 bg-shadow b-r-6">
                <h4 class="mb-2 font-size-25">
                  <a
                    class="text-dark font-size-25"
                    href="https://capdigisoft.com/blog/strategies-and-trends-for-successful-lead-generation/"
                  >
                    Strategies and trends for successful lead generation
                  </a>
                </h4>
                <p class="mb-3 f-15 fw-400">Gerald Valentine</p>
                <hr />
                <p class="mb-2 hidden-4">
                  The core task of any business is to acquire new customers;
                  without them, there is no scope for growth and revenue.
                  Generating leads is not only about turning customers into
                  sales leads, but also about building a long-term relationship
                  with them and expanding business opportunities...
                </p>
                <a
                  class="d-inline-block underline-border "
                  href="https://capdigisoft.com/blog/strategies-and-trends-for-successful-lead-generation/"
                >
                  <span class="border-line d-inline-block me-1">
                    Learn more
                  </span>
                  <i class="fa fa-arrow-right fa-sm position-relative"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <FooterOne />
    </div>
  );
};

export default B2B;
